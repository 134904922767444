import { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { LANGUAGE, KEYWORDS } from "../../constants/url";
import { Tooltip } from "react-tooltip";
import { IoSearch } from "react-icons/io5";
import {
  Modal,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  ALERT_BUTTON_NO,
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  ALERT_ICON_QUESTON,
  ALERT_ICON_SUCC,
  ALERT_ICON_WARNING,
  DELETE_YES_BUTTON,
  DISABLE,
  DO_YOU_WANT_TO,
  DO_YOU_WANT_TO_DELETE,
  ENABLE,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  SUCCESSFULLY,
  SUCC_ALERT_TITLE,
  SUCC_DELETE,
  THE,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const Keywords = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const [noData, setNoData] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [activeLanguages, setActiveLanguages] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [isEdit, setIsEdit] = useState(false); // To track if the modal is for editing

  useEffect(() => {
    fetchLanguage();
    fetchKeywords();
  }, []);
  const handleModalClose = () => {
    setShowModal(false);
    setKeyword("");
    setSelectedLanguage("");
    setIsEdit(false);
    setId("");
  };

  const handleModalOpen = (editMode = false, item = null) => {
    setIsEdit(editMode);
    if (editMode && item) {
      setKeyword(item.keywords);
      setSelectedLanguage(item.language);
      setId(item._id);
    } else {
      setKeyword("");
      setSelectedLanguage("");
      setId("");
    }
    setShowModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const endpoint = isEdit
        ? `${KEYWORDS}/updateKeyword?keywordId=${id}`
        : `${KEYWORDS}/addKeyword`;
      const method = isEdit ? "put" : "post";
      const data = isEdit
        ? { keyword, selectedLanguage }
        : { keyword, languageCode: selectedLanguage, status: "active" };

      const response = await privateApiCall(endpoint, method, data);

      if (response.status === 200) {
        Swal.fire({
          icon: `${ ALERT_ICON_SUCC }`,
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.message,
        });
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.message,
          icon: `${ ALERT_ICON_SUCC }`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText:`${ ALERT_BUTTON_OK }`,
        });
        fetchKeywords();
        handleModalClose();
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: response.data.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: error.response.data.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText:` ${ ALERT_BUTTON_OK }`,
        });
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText:` ${ ALERT_BUTTON_OK }`,
        });
      }
    }
  };

  const fetchLanguage = async () => {
    try {
      const requestUrl = `${LANGUAGE}/getactivelan`;

      const response = await privateApiCall(requestUrl, "GET");
      const responseData = response.data;
      setActiveLanguages(responseData);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ ALERT_ICON_ERR }`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText:` ${ ALERT_BUTTON_OK }`,
      });
    }
  };

  const fetchKeywords = async () => {
    try {
      const requestUrl = `${KEYWORDS}/getKeywords`;
      const response = await privateApiCall(requestUrl, "GET");

      setKeywords(response.data.keywords);
      setFilteredKeywords(response.data.keywords);
      setNoData(response.data.keywords.length === 0);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ ALERT_ICON_ERR }`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ ALERT_BUTTON_OK }`,
      });
    }
  };

  const handleSearchLang = async (e) => {
    try {
      const searchValue = e.target.value;
      if (searchValue === "") {
        setFilteredKeywords(keywords);
        setKeywords(keywords);
        fetchKeywords();
        return;
      }
      const requestUrl = `/api/keyword/search/${searchValue}`;
      const response = await privateApiCall(requestUrl, "GET");

      setFilteredKeywords(response.data.keywords);
      setKeywords(response.data.keywords);
      setNoData(response.data.keywords.length === 0);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setNoData(true);
      } else {
        setNoData(false);
      }
       
    }
  };

  const actionFormatter = (item) => {
    const handleDisable = async () => {
      try {
        const keywordId = item._id;
        const result = await Swal.fire({
          title: `${DO_YOU_WANT_TO} ${
            item.status === "active" ? `${ DISABLE }` : `${ ENABLE }`
          } ${THE} ${item.keywords}?`,
          icon: `${ ALERT_ICON_QUESTON }`,
          showCancelButton: true,
          confirmButtonText: `${ ALERT_BUTTON_YES }`,
          cancelButtonText:` ${ ALERT_BUTTON_NO }`,
        });

        if (result.isConfirmed) {
          const requestUrl = `${KEYWORDS}/deletKeyword?keywordId=${keywordId}`;

          const response = await privateApiCall(requestUrl, "PUT", {});
          
          if (response.data.status) {
            Swal.fire({
              title:` ${ SUCC_ALERT_TITLE }`,
              text: `${item.keywords} ${
                item.status === "active" ?  `${ DISABLE }` : `${ ENABLE }`
              } ${SUCCESSFULLY}`,
              icon: `${ ALERT_ICON_SUCC }`,
              confirmButtonText: `${ ALERT_BUTTON_OK }`,
            });
            fetchKeywords();
          } else {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              text: response.data.message,
              icon: `${ ALERT_ICON_ERR }`,
              confirmButtonText: `${ ALERT_BUTTON_OK }`,
            });
          }
        }
      } catch (error) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      }
    };

    const handleDelete = async () => {
      try {
        const result = await Swal.fire({
          title: `${DO_YOU_WANT_TO_DELETE} ${item.keywords}?`,
          icon: `${ ALERT_ICON_WARNING }`,
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: " #3085d6",
          confirmButtonText: `${ DELETE_YES_BUTTON }`,
          cancelButtonText: `${ ALERT_BUTTON_NO }`,
        });

        if (result.isConfirmed) {
          const requestUrl = `${KEYWORDS}/${item._id}`;

          const response = await privateApiCall(requestUrl, "DELETE", {});

          if (response.data.status) {
            Swal.fire({
              title: `${ SUCC_ALERT_TITLE }`,
              text: `${item.keywords} ${SUCC_DELETE}`,
              icon: `${ ALERT_ICON_SUCC }`,
              confirmButtonText: `${ ALERT_BUTTON_OK }`,
            });
            fetchKeywords();
          } else {
            Swal.fire({
              title: { ERR_ALERT_TITLE },
              text: response.data.message,
              icon: { ALERT_ICON_ERR },
              confirmButtonText: { ALERT_BUTTON_OK },
            });
          }
        }
      } catch (error) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText:` ${ ALERT_BUTTON_OK }`,
        });
      }
    };

    return (
      <span>
        {permissions.some(
          (item) => item.keyword === "Keyword" && item.isEdit === true
        ) && (
          <>
            <FiEdit2
              size="18px"
              type="button"
              data-tooltip-id="1"
              data-tooltip-content="Edit"
              color="#0978F2"
              onClick={() => handleModalOpen(true, item)}
            ></FiEdit2>
            <Tooltip id="1" />
          </>
        )}

        <i onClick={handleDisable}>
          {item.status === "active"
            ? permissions.some(
                (item) => item.keyword === "Keyword" && item.isBlock === true
              ) && (
                <FaRegThumbsDown
                  size="18px"
                  color="red"
                  data-tooltip-id="2"
                  data-tooltip-content="Disable"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
              )
            : permissions.some(
                (item) => item.keyword === "Keyword" && item.isUnblock === true
              ) && (
                <FaRegThumbsUp
                  size="18px"
                  color="green"
                  data-tooltip-id="2"
                  data-tooltip-content="
              Enable"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
              )}
          <Tooltip id="2" style={{ fontStyle: "normal" }} />
        </i>
        {permissions.some(
          (item) => item.keyword === "Keyword" && item.isDelete === true
        ) && (
          <i onClick={handleDelete}>
            <FiTrash2
              size="18px"
              color="red"
              data-tooltip-id="3"
              data-tooltip-content="Delete"
              style={{ marginLeft: "10px", cursor: "pointer" }}
            />
            <Tooltip id="3" style={{ fontStyle: "normal" }} />
          </i>
        )}
      </span>
    );
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.KEYWORDS}</li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.KEYWORDS}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  {permissions.some(
                    (item) =>
                      item.keyword === "Keyword" && item.isCreate === true
                  ) && (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => handleModalOpen()}
                      sx={{
                        backgroundColor: "#769A0F",
                        color: "#fff",
                        marginBottom: "20px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#769A0F",
                        },
                      }}
                    >
                      {STRING_CONSTANTS.ADD_KEYWORDS}
                    </Button>
                  )}
                  <div
                    style={{
                      position: "relative",
                      float: "right",
                      width: "200px",
                    }}
                  >
                      {permissions.some(
          (item) => item.keyword === "Keyword" && item.isSearch === true
        ) && (
          <>
            <IoSearch
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                    <input
                      type="text"
                      placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                      className="form-control"
                      style={{
                        paddingLeft: "30px",
                      }}
                      onChange={handleSearchLang}
                    />
          
          </>
        )}
                  
                  </div>
                  <Modal
                    open={showModal}
                    onClose={handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <div
                      style={{
                        padding: "20px",
                        background: "white",
                        width: "60%",
                        margin: "100px auto",
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <h2>{isEdit ? `${STRING_CONSTANTS.EDIT}` :`${STRING_CONSTANTS.ADD}`} {STRING_CONSTANTS.KEYWORD}</h2>
                      <form onSubmit={handleSubmit}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <TextField
                            label="Keyword"
                            type="text"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            required
                            style={{ marginTop: "20px" }}
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <InputLabel id="language-label">
                            {STRING_CONSTANTS.SELECT_LANGUAGE}
                          </InputLabel>
                          <Select
                            labelId="language-label"
                            value={selectedLanguage}
                            onChange={(e) =>
                              setSelectedLanguage(e.target.value)
                            }
                            required
                          >
                            <MenuItem value="">
                              <em> {STRING_CONSTANTS.SELECT_LANGUAGE}</em>
                            </MenuItem>
                            {activeLanguages.map((lang) => (
                              <MenuItem key={lang._id} value={lang.language}>
                                {lang.language}-{lang.code}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Grid
                          container
                          justifyContent="flex-end"
                          sx={{ mt: 3 }}
                        >
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={handleModalClose}
                            sx={{ mt: 2, mr: 1 }}
                          >
                           {STRING_CONSTANTS.CLOSE}
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{ mt: 2 }}
                            style={{ backgroundColor: "#769A0F" }}
                          >
                            {isEdit ? `${STRING_CONSTANTS.UPDATE}` : `${STRING_CONSTANTS.SUBMIT}` }
                          </Button>
                        </Grid>
                      </form>
                    </div>
                  </Modal>
                  {noData ? (
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      {STRING_CONSTANTS.NO_KEYWORDS}
                    </div>
                  ) : (
                    <div style={{ width: "98%" }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="80px" align="center">
                                {STRING_CONSTANTS.SN_NO}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {" "}
                                {STRING_CONSTANTS.KEYWORD}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {STRING_CONSTANTS.LANGUAGE}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {STRING_CONSTANTS.STATUS}
                              </TableCell>
                              <TableCell width="80px" align="left">
                                {STRING_CONSTANTS.ACTIONS}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredKeywords.map((keyword, index) => (
                              <TableRow key={index}>
                                <TableCell width="80px" align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell width="180px" align="left">
                                  {keyword.keywords}
                                </TableCell>
                                <TableCell width="180px" align="left">
                                  {keyword.language}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  {keyword.status === "active" ? (
                                    <span className="btn-sm btn-success ">
                                      {STRING_CONSTANTS.ACTIVE}
                                    </span>
                                  ) : (
                                    <span className="btn-sm btn-danger">
                                      {STRING_CONSTANTS.INACTIVE}
                                    </span>
                                  )}{" "}
                                </TableCell>
                                <TableCell width="80px" align="left">
                                  {actionFormatter(keyword)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Keywords;
