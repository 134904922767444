import React, { useState, useEffect, useRef } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FiEye } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
import { InputAdornment } from "@mui/material";
import {
  Container,
  TextField,
  MenuItem,
  IconButton,
  InputLabel,
  Grid,
  Select,
  Button,
  Modal,
  Box,
  Typography,
  TableCell,
  TableRow,
  Table,
  Autocomplete as MUIAutocomplete,
} from "@mui/material";
import {
  ALERT_BUTTON_OK,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ALERT_ICON_ERR,
  NO_DATA,
  NO_LOCATION,
  VALID_LOCATION,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import Cookies from "universal-cookie";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { ADD_EMPLOYEE, VIEW_DET } from "../../constants/url";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import axios from "axios";
import { toast, Bounce } from "react-toastify";
import { GOOGLE_MAP_API_KEY } from "../../constants/apiKey";
const cookies = new Cookies();
const LIBRARIES = ["places", "marker"];

const HarvestPlanner = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const [hoveredCard, setHoveredCard] = useState(null);
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedHarvest, setSelectedHarvest] = useState(null);
  const [harvestUser, setHarvestUser] = useState([]);
  const [distanceFrom, setDistanceFrom] = useState("");
  const [variety, setVariety] = useState([]);
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [advancedMarkerElement, setAdvancedMarkerElement] = useState(null);
  const mapContainerRef = useRef(null);
  const originRef = useRef(null);
  const infoWindowRef = useRef(null);
  const [selectedVariety, setSelectedVariety] = useState("");
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const stages = ["Primary Tender", "Tender", "Green Jackfruit", "Matured"];
  const [selectedStage, setSelectedStage] = useState([]);
  const [seasonType, setSeasonType] = useState("");
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [weekMonth, setWeekMonth] = useState("");
  const handleStageChange = (event) => {
    setSelectedStage(event.target.value);
  };
  const handleSeasonChange = (event) => {
    setSeasonType(event.target.value);
  };
  const handleViewDetails = (user) => {
    setSelectedHarvest(user);
    setDetailsModalOpen(true);
  };
  const handleCloseModal = () => {
    setDetailsModalOpen(false);
    setSelectedHarvest(null);
  };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["places", "marker"],
  });

  useEffect(() => {
    fetchVariety();
  }, []);

  const fetchVariety = async () => {
    try {
      const requestUrl = `/api/variety/varietyList`;
      const response = await privateApiCall(requestUrl, "GET");
      setVariety(response.data.variety);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `error`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  useEffect(() => {
    loadAdvancedMarkerElement();
  }, []);

  const loadAdvancedMarkerElement = async () => {
    try {
      const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
        "marker"
      );
      setAdvancedMarkerElement(() => AdvancedMarkerElement);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVarietyChange = (e) => {
    setSelectedVariety(e.target.value);
  };

  const handleSearch = async () => {
    console.log(seasonType,"season type")
    const stageParam = selectedStage.join(",");
    if (!location.lat || !location.lng) {
      Swal.fire({
        title: `${NO_LOCATION}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${VALID_LOCATION}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
      return;
    }
    if (
      !selectedVariety ||
      !distanceFrom ||
      !stageParam ||
      !pincode ||
      !state ||
      !city||
      !seasonType
    ) {
      Swal.fire({
        title: "Please fill required fields",
        icon: `${ALERT_ICON_ERR}`,
        text: ``,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
      return;
    }

    try {
      const requestUrl = `${ADD_EMPLOYEE}/searchHarvestData?latitude=${location.lat}&longitude=${location.lng}&distance=${distanceFrom}&variety=${selectedVariety}&stage=${stageParam}&periodType=${seasonType}`;
      const response = await privateApiCall(requestUrl, "GET");
      console.log(response, "response");
      if (response.data.data.length === 0) {
        Swal.fire({
          icon: `${ALERT_ICON_ERR}`,
          text: `${NO_DATA}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
      setHarvestUser(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const clearData = async () => {
    try {
      markersRef.current.forEach((marker) => {
        marker.setMap(null);
      });
      markersRef.current = [];
      setSelectedVariety("");
      setLocation({ lat: null, lng: null });
      setDistanceFrom("");
      setHarvestUser([]);
      setSelectedStage([]);
      setPincode("");
      setCity("");
      setState("");
      setSeasonType("")
      setStartMonth("")
      setEndMonth("")
      setWeekMonth("")


      if (originRef.current) {
        originRef.current.value = "";
      }
      if (infoWindowRef.current) {
        infoWindowRef.current.close();
        infoWindowRef.current = null; // Clear the reference
      }
    } catch (error) {
      console.error(error);
    }
  };

  const markersRef = useRef([]);

  // Initialize the map and place the advanced marker
  useEffect(() => {
    if (advancedMarkerElement && mapContainerRef.current) {
      const map = new window.google.maps.Map(mapContainerRef.current, {
        center: { lat: 11.060432245829782, lng: 76.07166445683364 },
        zoom: 5,
        disableDefaultUI: true,
        mapId: GOOGLE_MAP_API_KEY,
      });

      const markerClusterer = new MarkerClusterer({ map });

      const markers = harvestUser
        .map((user) => {
          const lat = parseFloat(user.latitude);
          const lng = parseFloat(user.longitude);

          if (!isNaN(lat) && !isNaN(lng)) {
            const marker = new advancedMarkerElement({
              position: { lat, lng },
              map,
            });

            // Add marker to the clusterer
            markerClusterer.addMarker(marker);

            marker.addListener("click", async () => {
              if (map.getZoom() < 20) {
                map.setZoom(map.getZoom() + 2);
                map.setCenter(marker.position);
              }
            });

            return marker;
          } else {
            console.warn(lat, lng);
            return null;
          }
        })
        .filter(Boolean);

      markersRef.current = markers;
    }
  }, [advancedMarkerElement, harvestUser]);
  const autocompleteRef = useRef(null);
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setLocation({ lat, lng });
      let tempPincode = "";
      let tempCity = "";
      let tempState = "";
      if (place.address_components) {
        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("postal_code")) {
            tempPincode = component.long_name; // Extract pincode
          }
          if (types.includes("locality")) {
            tempCity = component.long_name; // Extract city
          }
          if (types.includes("administrative_area_level_1")) {
            tempState = component.long_name; // Extract state
          }
        });
      }

      setPincode(tempPincode);
      setCity(tempCity);
      setState(tempState);
    } else {
    }
  };
  const [open, setOpen] = useState({});

  const handleToggle = (userName) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [userName]: !prevOpen[userName],
    }));
  };
  const groupedData = harvestUser.reduce((acc, user) => {
    if (!acc[user.userName]) {
      acc[user.userName] = [];
    }
    acc[user.userName].push(user);
    return acc;
  }, {});

  if (!isLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div
        style={{ padding: "20px", backgroundColor: "#f7f8fa" }}
        className="content-page"
      >
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div
                  className="page-title-box"
                  style={{ marginBottom: "20px" }}
                >
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.HARVEST_PLANNER}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.HARVEST_PLANNER}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div
                  className="row"
                  style={{
                    background: "white",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {[
                    {
                      name: "Jackfruit",
                      image: "/assets/images/harvest/jackfruit.png",
                    },
                    {
                      name: "Rambuttan",
                      image: "/assets/images/harvest/rambuttan.png",
                    },
                    {
                      name: "Avocado",
                      image: "/assets/images/harvest/avacado.png",
                    },
                    {
                      name: "Papaya",
                      image: "/assets/images/harvest/pappaya.png",
                    },
                    {
                      name: "Pineapple",
                      image: "/assets/images/harvest/p.png",
                    },
                    {
                      name: "Dragon fruit",
                      image: "/assets/images/harvest/dragon.png",
                    },
                  ].map((item, index) => (
                    <div
                      className="progress-card"
                      key={index}
                      style={{
                        background:
                          selectedCard === index ? "skyblue" : "#eef7f9",
                        borderRadius: "8px",
                        padding: "20px",
                        textAlign: "center",
                        marginBottom: "20px",
                        flex: "0 0 calc(16.66% - 20px)",
                        margin: "10px",
                        transform:
                          hoveredCard === index ? "scale(1.05)" : "scale(1)",
                        transition: "transform 0.2s",
                        boxShadow:
                          hoveredCard === index
                            ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                            : "0 2px 4px rgba(0, 0, 0, 0.1)",
                        zIndex: hoveredCard === index ? "10" : "1",
                        cursor: index === 0 ? "pointer" : "default",
                      }}
                      onMouseOver={() => index === 0 && setHoveredCard(index)}
                      onMouseOut={() => index === 0 && setHoveredCard(null)}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{ width: "100px", height: "120px" }}
                      />
                      <p>{item.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgHarvestPlanner"
              )) && (
              <Container>
                <Grid container spacing={3} style={{ marginTop: "2rem" }}>
                  <Grid item xs={12} md={6}>
                    <form>
                      <div className="w-full flex items-start">
                        <Autocomplete
                          onLoad={(autocomplete) => {
                            autocompleteRef.current = autocomplete;
                          }}
                          onPlaceChanged={handlePlaceSelect}
                        >
                          <TextField
                            label="Location"
                            fullWidth
                            variant="standard"
                            inputRef={originRef}
                            style={{ marginBottom: "1rem" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <InfoIcon
                                      data-tooltip-id="1"
                                      data-tooltip-content="This address is used to identify  harvest data"
                                    />
                                  </IconButton>
                                  <Tooltip id="1" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Autocomplete>
                        <TextField
                          label="Pincode"
                          value={pincode}
                          onChange={(e) => setPincode(e.target.value)}
                          fullWidth
                          variant="standard"
                          style={{ marginBottom: "1rem" }}
                        />
                        <TextField
                          label="City"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          fullWidth
                          variant="standard"
                          style={{ marginBottom: "1rem" }}
                        />
                        <TextField
                          label="State"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          fullWidth
                          variant="standard"
                          style={{ marginBottom: "1rem" }}
                        />

                        <div
                          className="w-full"
                          style={{ marginBottom: "1rem" }}
                        >
                          <InputLabel>{STRING_CONSTANTS.RANGE}</InputLabel>
                          <TextField
                            type="number"
                            value={distanceFrom}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value >= 1 || value === "") {
                                setDistanceFrom(value);
                              }
                            }}
                            fullWidth
                            variant="standard"
                            placeholder="Enter distance"
                          />
                        </div>
                        <div
                          className="w-full"
                          style={{ marginBottom: "1rem" }}
                        >
                          <InputLabel>
                            {STRING_CONSTANTS.HARVEST_SEASON}
                          </InputLabel>
                          <Select
                            labelId="Season"
                            value={seasonType}
                            onChange={handleSeasonChange}
                            required
                            fullWidth
                          >
                            <MenuItem value="">
                              <em>{STRING_CONSTANTS.SELECT_SEASON}</em>
                            </MenuItem>
                            <MenuItem value="Monthly">Monthly</MenuItem>
                            <MenuItem value="Weekly">Weekly</MenuItem>
                            <MenuItem value="Seasonal">Seasonal</MenuItem>
                          </Select>
                        </div>
                        {seasonType === "Monthly" && (
                          <>
                            <div
                              className="w-full"
                              style={{ marginBottom: "1rem" }}
                            >
                              <InputLabel>Start Month</InputLabel>
                              <Select
                                value={startMonth}
                                onChange={(e) => setStartMonth(e.target.value)}
                                fullWidth
                                variant="standard"
                              >
                                <MenuItem value="">
                                  <em>{STRING_CONSTANTS.SELECT_MONTH}</em>
                                </MenuItem>
                                {STRING_CONSTANTS.MONTH_LABEL_ARRAY.map(
                                  (month, index) => (
                                    <MenuItem key={index} value={month}>
                                      {month}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </div>

                            <div
                              className="w-full"
                              style={{ marginBottom: "1rem" }}
                            >
                              <InputLabel>End Month</InputLabel>
                              <Select
                                value={endMonth}
                                onChange={(e) => setEndMonth(e.target.value)}
                                fullWidth
                                variant="standard"
                              >
                                <MenuItem value="">
                                  <em>{STRING_CONSTANTS.SELECT_MONTH}</em>
                                </MenuItem>
                                {STRING_CONSTANTS.MONTH_LABEL_ARRAY.map(
                                  (month, index) => (
                                    <MenuItem key={index} value={month}>
                                      {month}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </div>
                          </>
                        )}

                        {seasonType === "Weekly" && (
                          <div
                            className="w-full"
                            style={{ marginBottom: "1rem" }}
                          >
                            <InputLabel>Week Month</InputLabel>
                            <Select
                              value={weekMonth}
                              onChange={(e) => setWeekMonth(e.target.value)}
                              fullWidth
                              variant="standard"
                            >
                              <MenuItem value="">
                                <em>{STRING_CONSTANTS.SELECT_MONTH}</em>
                              </MenuItem>
                              {STRING_CONSTANTS.MONTH_LABEL_ARRAY.map(
                                (month, index) => (
                                  <MenuItem key={index} value={month}>
                                    {month}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </div>
                        )}

                        {seasonType === "Seasonal" && (
                          <>
                            <div
                              className="w-full"
                              style={{ marginBottom: "1rem" }}
                            >
                              <InputLabel>Start Month</InputLabel>
                              <Select
                                value={startMonth}
                                onChange={(e) => setStartMonth(e.target.value)}
                                fullWidth
                                variant="standard"
                              >
                                <MenuItem value="">
                                  <em>{STRING_CONSTANTS.SELECT_MONTH}</em>
                                </MenuItem>
                                {STRING_CONSTANTS.MONTH_LABEL_ARRAY.map(
                                  (month, index) => (
                                    <MenuItem key={index} value={month}>
                                      {month}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </div>

                            <div
                              className="w-full"
                              style={{ marginBottom: "1rem" }}
                            >
                              <InputLabel>End Month</InputLabel>
                              <Select
                                value={endMonth}
                                onChange={(e) => setEndMonth(e.target.value)}
                                fullWidth
                                variant="standard"
                              >
                                <MenuItem value="">
                                  <em>{STRING_CONSTANTS.SELECT_MONTH}</em>
                                </MenuItem>
                                {STRING_CONSTANTS.MONTH_LABEL_ARRAY.map(
                                  (month, index) => (
                                    <MenuItem key={index} value={month}>
                                      {month}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </div>
                          </>
                        )}

                        <div
                          className="w-full"
                          style={{ marginBottom: "1rem" }}
                        >
                          <InputLabel>{STRING_CONSTANTS.VARIETY}</InputLabel>
                          <Select
                            labelId="variety-label"
                            value={selectedVariety}
                            onChange={handleVarietyChange}
                            required
                            fullWidth
                          >
                            <MenuItem value="">
                              <em>{STRING_CONSTANTS.ALL_VARIETY_TYPES}</em>
                            </MenuItem>
                            {variety.map((item, index) => (
                              <MenuItem key={index} value={item.variety}>
                                {item.variety}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div
                          className="w-full"
                          style={{ marginBottom: "1rem" }}
                        >
                          <InputLabel>
                            {STRING_CONSTANTS.HARVEST_STAGE}
                          </InputLabel>
                          <MUIAutocomplete
                            multiple
                            id="stages-outlined"
                            options={stages}
                            value={selectedStage}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(event, value) => {
                              setSelectedStage(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Stages"
                                placeholder="Select Stages"
                              />
                            )}
                          />
                        </div>

                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "1rem" }}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleSearch}
                            >
                              {STRING_CONSTANTS.SEARCH}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={clearData}
                            >
                              {STRING_CONSTANTS.CLEAR}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </Grid>

                  <Grid item xs={12} md={6} style={{ height: "600px" }}>
                    <div
                      ref={mapContainerRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Container>
            )}

            <div className="row">
              <div className="col-12">
                <div
                  className="card-box"
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                  }}
                >
                  <h4 className="header-title mb-4">
                    {STRING_CONSTANTS.HARVEST_DATA}
                  </h4>
                  <div
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  ></div>
                  <div className="table-responsive">
                    {harvestUser.length === 0 ? (
                      <p>{STRING_CONSTANTS.NO_HARVEST_DATA_FOUND}</p>
                    ) : (
                      <table className="table table-hover table-centered mb-0">
                        <thead className="thead-light">
                          <tr>
                            <th>{STRING_CONSTANTS.SN_NO}</th>
                            <th>{STRING_CONSTANTS.USER_NAME}</th>
                            <th>{STRING_CONSTANTS.PERIOD_TYPE}</th>
                            <th>{STRING_CONSTANTS.START_DATE}</th>
                            <th>{STRING_CONSTANTS.VARIETY}</th>
                            <th>{STRING_CONSTANTS.ACTIONS}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Object.keys(groupedData).map((userName, index) => {
                            const userRows = groupedData[userName];

                            return (
                              <React.Fragment key={userName}>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    {userName}
                                    {userRows.length > 1 && (
                                      <IconButton
                                        onClick={() => handleToggle(userName)}
                                      >
                                        {open[userName] ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </IconButton>
                                    )}
                                  </td>
                                  <td>{userRows[0].periodType}</td>
                                  <td>{userRows[0].startMonth}</td>
                                  <td>{userRows[0].variety?.variety}</td>
                                  <td>
                                    <i
                                      data-tooltip-id="view-details"
                                      data-tooltip-content="View Details"
                                    >
                                      <FiEye
                                        onClick={() =>
                                          handleViewDetails(userRows[0])
                                        }
                                        color="#9368f3"
                                        size="20"
                                      />
                                    </i>
                                    <Tooltip
                                      id="view-details"
                                      style={{ fontStyle: "normal" }}
                                    />
                                  </td>
                                </tr>

                                {open[userName] &&
                                  userRows.map((user) => (
                                    <tr key={user._id}>
                                      <td></td>
                                      <td></td>
                                      <td>{user.periodType}</td>
                                      <td>{user.startMonth}</td>
                                      <td>{user.variety?.variety}</td>
                                      <td>
                                        <i
                                          data-tooltip-id="view-details"
                                          data-tooltip-content="View Details"
                                        >
                                          <FiEye
                                            onClick={() =>
                                              handleViewDetails(user)
                                            }
                                            color="#9368f3"
                                            size="20"
                                          />
                                        </i>
                                        <Tooltip
                                          id="view-details"
                                          style={{ fontStyle: "normal" }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                              </React.Fragment>
                            );
                          })}
                        </tbody>

                        <Modal
                          open={detailsModalOpen}
                          onClose={handleCloseModal}
                          aria-labelledby="modal-title"
                          aria-describedby="modal-description"
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              top: "55%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "65vw",
                              maxHeight: "75vh",
                              bgcolor: "background.paper",
                              boxShadow: 24,
                              p: 4,
                              overflowY: "auto",
                            }}
                          >
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="modal-content"
                            >
                              <Grid item xs={12} className="modal-header">
                                <Typography
                                  id="modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  {STRING_CONSTANTS.DETAILS}
                                </Typography>
                                <Button
                                  onClick={handleCloseModal}
                                  color="inherit"
                                >
                                  <span
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  >
                                    &#128473;
                                  </span>
                                </Button>
                              </Grid>
                              <Grid item xs={12} className="modal-body">
                                <Table className="col-12">
                                  {selectedHarvest && (
                                    <>
                                      {selectedHarvest.userName && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.USER_NAME}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {selectedHarvest.userName}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.periodType && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.PERIOD_TYPE}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {selectedHarvest.periodType}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.startMonth && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.START_MONTH}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {selectedHarvest.startMonth}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.endMonth && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.END_MONTH}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {selectedHarvest.endMonth}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.week && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.WEEK}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {selectedHarvest.week}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.variety?.variety && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.VARIETY}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {selectedHarvest.variety.variety}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.harvestingStage && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.HARVEST_STAGE}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {selectedHarvest.harvestingStage}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.expectedHarvest
                                        ?.primaryTender?.income > 0 && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {
                                              STRING_CONSTANTS.INCOME_PRIMARY_TENDER
                                            }
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {
                                              selectedHarvest.expectedHarvest
                                                .primaryTender.income
                                            }
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.expectedHarvest
                                        ?.primaryTender?.quantity > 0 && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {
                                              STRING_CONSTANTS.QUANTITY_PRIMARY_TENDER
                                            }
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {
                                              selectedHarvest.expectedHarvest
                                                .primaryTender.quantity
                                            }
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.expectedHarvest?.tender
                                        ?.income > 0 && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.INCOME_TENDER}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {
                                              selectedHarvest.expectedHarvest
                                                .tender.income
                                            }
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.expectedHarvest?.tender
                                        ?.quantity > 0 && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.QUANTITY_TENDER}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {
                                              selectedHarvest.expectedHarvest
                                                .tender.quantity
                                            }
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.expectedHarvest
                                        ?.greenJackfruit?.income > 0 && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {
                                              STRING_CONSTANTS.INCOME_GREEN_JACKFRUIT
                                            }
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {
                                              selectedHarvest.expectedHarvest
                                                .greenJackfruit.income
                                            }
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.expectedHarvest
                                        ?.greenJackfruit?.quantity > 0 && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {
                                              STRING_CONSTANTS.QUANTITY_GREEN_JACKFRUIT
                                            }
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {
                                              selectedHarvest.expectedHarvest
                                                .greenJackfruit.quantity
                                            }
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.expectedHarvest?.matured
                                        ?.income > 0 && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.INCOME_MATURED}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {
                                              selectedHarvest.expectedHarvest
                                                .matured.income
                                            }
                                          </TableCell>
                                        </TableRow>
                                      )}
                                      {selectedHarvest.expectedHarvest?.matured
                                        ?.quantity > 0 && (
                                        <TableRow>
                                          <TableCell className="text-left">
                                            {STRING_CONSTANTS.QUANTITY_MATURED}
                                          </TableCell>
                                          <TableCell className="text-dark text-right font-weight-normal">
                                            {
                                              selectedHarvest.expectedHarvest
                                                .matured.quantity
                                            }
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </>
                                  )}
                                </Table>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                className="modal-footer"
                                sx={{
                                  display: "flex",

                                  mt: 2,
                                }}
                              >
                                <Button
                                  onClick={handleCloseModal}
                                  variant="outlined"
                                  color="error"
                                >
                                  {STRING_CONSTANTS.CLOSE}
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Modal>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HarvestPlanner;
