import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { deleteSurvey } from "../../actions/adminAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  FiEye,
  FiTrash2,
  FiMapPin,
  FiArrowUp,
  FiArrowDown,
} from "react-icons/fi";
import { FaImage } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import {
  DELETE,
  ORG_SURVEY,
  TREE_LOC,
  VIEW_DET,
  VIEW_IMG,
  ORG_SEARCH_SURVEY,
} from "../../constants/url";
import {ALERT_BUTTON_OK, CONFIRM_TITLE,
  CONFIRM_TXT_DEL,} from "../../constants/alertMessage"
import { SURVEY_QUESTIONS } from "../../constants/surveyQuestionnaire";
import { privateApiCall } from "../../api/privateApi";
import ImageModal from "../../images/imageModal";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const Survey = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const [values, setValues] = useState({
    data: "",
    orders: [],
    questions: false,
    qHeader: "View Questions",
    user: "All",
    users: ["All", "Farmer", "Organisation"],
    removed: false,
  });
  const { data, questions, user, users } = values;
  const [show, setShow] = useState(false);
  const [survey, setSurvey] = useState({});
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    loadSurvey();
  }, []);

  const loadSurvey = () => {
    const requestUrl = `${ORG_SURVEY}/?token=${cookies.get(
      "org_token"
    )}&org_name=${cookies.get("org_name")}&type=All`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        setValues((prevState) => ({
          ...prevState,
          data: res.data.data,
        }));
      })
      .catch((err) => {
        Swal.fire({
          title: `${err}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      });
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${ORG_SEARCH_SURVEY}?query=${searchValue}&org_name=${cookies.get(
        "org_name"
      )}`;

      const response = await privateApiCall(requestUrl, "GET");

      if (response.data && Array.isArray(response.data.data)) {
        setValues((prevState) => ({
          ...prevState,
          data: response.data.data,
        }));
      } else {
        setValues((prevState) => ({
          ...prevState,
          data: [],
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `ERROR`,
        text: `Error`,
        icon: "error",
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    }
  };

  const handleChange = (e) => {
    e.persist();

    const jwtToken = cookies.get("jwtToken");
    const requestUrl = `${ORG_SURVEY}?token=${cookies.get(
      "org_token"
    )}&org_name=${cookies.get("org_name")}&type=${e.target.value}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        setValues((prevState) => ({
          ...prevState,
          data: res.data.data,
          user: res.data.type,
        }));
      })
      .catch((err) => {
        Swal.fire({
          title: `${err}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      });
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleImageClick = (survey) => {
    const imageData = [
      survey.image1,
      survey.image2,
      survey.image3,
      survey.image4,
    ].filter((image) => image !== "" && image !== null);

    if (imageData.length > 0) {
      setImages(imageData);
      setOpen(true);
    } else {
      console.error("No image data available for this survey.");
    }
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setShow(false);
  };
  const deletea = (cell, row) => {
    console.log(row, "row data");
    const handleDelete = (e) => {
      Swal.fire({
        title:`${CONFIRM_TITLE}`,
        text: `${CONFIRM_TXT_DEL}`,
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteSurvey(row.id, cookies.get("org_token")).then((data) => {
            if (data.error) {
              Swal.fire({
                title: `${data.error}`,
                icon: "error",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            } else {
              setValues((prevState) => ({
                ...prevState,
                data: prevState.data.filter(
                  (survey) => survey.id !== row.id
                ),
              }));
            }
          });
        }
      });
    };

    const handlePOP = (e) => {
      setSurvey(row);
      setShow(true);
    };
  
    return (
      <div className="d-flex align-items-start justify-content-start">
        {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgSurvey" && item.isDetailedInfo
          )) && (
          <>
            <i
              className="mx-1 cursor-pointer "
              data-tooltip-id="1"
              data-tooltip-content={`${VIEW_DET}`}
            >
              {" "}
              <FiEye onClick={handlePOP} color="#9368f3" size="20" />
            </i>
          </>
        )}

        {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgSurvey" && item.isShowImage
          )) && (
          <>
            {row.image1 !== "" ? (
              <i
                className="mx-1 cursor-pointer "
                data-tooltip-id="2"
                data-tooltip-content={`${VIEW_IMG}`}
              >
                {" "}
                <FaImage
                  onClick={() => handleImageClick(row)}
                  color="green"
                  size="17"
                />
              </i>
            ) : null}
          </>
        )}

        {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgSurvey" && item.isTreeLocation
          )) && (
          <>
            {row.treelocation !== "" ? (
              <a
                id="data"
                href={
                  `https://maps.google.com?q=` +
                  row.latitude +
                  `,` +
                  row.longitude
                }
                target="_blank"
              >
                <i
                  className="mx-1 cursor-pointer "
                  data-tooltip-id="3"
                  data-tooltip-content={`${TREE_LOC}`}
                >
                  {" "}
                  <FiMapPin color="#ff9800" size="20" />
                </i>{" "}
              </a>
            ) : null}
          </>
        )}

        {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgSurvey" && item.isDelete
          )) && (
          <>
            <i
              className="mx-1 cursor-pointer "
              data-tooltip-id="4"
              data-tooltip-content={`${DELETE}`}
            >
              {" "}
              <FiTrash2 onClick={handleDelete} color="red" size="20" />
            </i>
          </>
        )}

        <Tooltip id="1" />
        <Tooltip id="2" />
        <Tooltip id="3" />
        <Tooltip id="4" />

        <div
          className="modal fade"
          id={"myModal" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" modal-sm role="dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  All Images
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="root">
                  <div className="row">
                    <div className="box">
                      <img
                        className="img-responsive"
                        src={row.image1}
                        alt="No Image"
                        style={{ width: "320px", height: "320px" }}
                      />
                    </div>
                    <div className="box">
                      {row.image2 !== "" && (
                        <img
                          className="img-responsive"
                          src={row.image2}
                          alt="No Image"
                          style={{ width: "320px", height: "320px" }}
                        />
                      )}
                    </div>
                    <div className="box mt-3">
                      {row.image3 !== "" && (
                        <img
                          className="img-responsive"
                          src={row.image3}
                          alt="No Image"
                          style={{ width: "320px", height: "320px" }}
                        />
                      )}
                    </div>
                    <div className="box mt-3">
                      {row.image4 !== "" && (
                        <img
                          className="img-responsive"
                          src={row.image4}
                          alt="No Image"
                          style={{ width: "320px", height: "320px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">Survey</h4>

                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/organisation/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Survey</li>
                  </ol>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div className="row container-fluid">
                    <div className="col-md-3 mb-3">

                    {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgSurvey" && item.isSort
          )) && (
          <>
           <select
                        id="single"
                        className="form-control"
                        value={user}
                        onChange={handleChange}
                      >
                        {users.map((person, i) => (
                          <option key={i} value={person}>
                            {" "}
                            {person}
                          </option>
                        ))}
                      </select>
          </>
        )}
                     
                    </div>


                    <div style={{ position: "relative", marginLeft: "auto" }}>

                      
                    {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgSurvey" && item.isSearch
          )) && (
          <>
          
          <input
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                        style={{
                          width: "200px",
                          paddingLeft: "35px",
                          boxSizing: "border-box",
                          marginLeft: "auto",
                        }}
                        onChange={(e) => {
                          handleSearch(e);
                          if (e.target.value.trim() === "") {
                            e.target.nextElementSibling.style.display =
                              "inline-block";
                          } else {
                            e.target.nextElementSibling.style.display = "none";
                          }
                        }}
                      />
                      <i
                        className="fas fa-search"
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "30%",
                          transform: "translateY(-50%)",
                        }}
                      ></i>
          </>
        )}
                     
                    </div>
                  </div>
                  {questions === true && (
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <link
                            href="https://getbootstrap.com/docs/4.0/dist/css/bootstrap.min.css"
                            rel="stylesheet"
                          />
                          <div className="container"></div>
                          <p>
                            <span className="Apple-converted-space">
                              &nbsp;&nbsp;{" "}
                            </span>
                            <strong>English</strong>
                          </p>

                          <ol>
                            <li>
                              Do you have Jackfruit ?
                              <span className="Apple-converted-space">
                                &nbsp;
                              </span>
                            </li>
                          </ol>
                          <p>
                            Answer : Yes or No
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                          <ol start="2">
                            <li>
                              Are you willing to make income from jackfruit?
                              <span className="Apple-converted-space"></span>
                            </li>
                          </ol>
                          <p>
                            Answer : Yes or No
                            <span className="Apple-converted-space"></span>
                          </p>
                          <ol start="3">
                            <li>
                              how many Jack Trees are there ? give number typing
                              option.
                              <span className="Apple-converted-space">
                                &nbsp;
                              </span>
                            </li>
                          </ol>
                          <ol>
                            <p>
                              3a. How many Koozha Plavu ?
                              <span className="Apple-converted-space">
                                {" "}
                                &nbsp;
                              </span>
                              3b. How Many Varikka Plavu ?
                            </p>
                          </ol>
                          <ol start="4">
                            <li>
                              What are the types of Jack trees available in your
                              property ?
                            </li>
                          </ol>
                          <p>Options :</p>
                          <p>Muttom Varikka</p>
                          <p>Singapore jack or Ceylon jack</p>
                          <p>Thenvarikka</p>
                          <p>Sindhoor</p>
                          <p>Vietnam Super Early</p>
                          <p>Ayur jack</p>
                          <p>Others (Enter manually)</p>
                          <ol start="5">
                            <li>
                              What is the usual harvesting time for your Jack
                              Tree ?
                              <span className="Apple-converted-space"></span>
                            </li>
                          </ol>
                          <p>
                            Date ( from to to)
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                          <ol start="6">
                            <li>
                              How much you earned last year from jackfruit
                              sales.?
                              <span className="Apple-converted-space"></span>
                            </li>
                          </ol>
                          <p>
                            Enter the figure ( only numbers)
                            <span className="Apple-converted-space"></span>
                          </p>
                          <ol start="7">
                            <li>
                              Are you willing to sell
                              <span className="Apple-converted-space">
                                &nbsp;{" "}
                              </span>
                              through jackfruit world for better advantage?
                              <span className="Apple-converted-space">
                                &nbsp;
                              </span>
                            </li>
                          </ol>
                          <p>
                            Yes or no
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                          <ol start="8">
                            <li>
                              Are you willing to deliver Jackfruit
                              <span className="Apple-converted-space"></span>to
                              our local collection center without any damage ?
                            </li>
                          </ol>
                          <p>Yes Or no</p>
                          <ol start="9">
                            <li>
                              Are you willing to plant jack tress for commercial
                              usage ?
                            </li>
                          </ol>
                          <p>Yes Or no</p>
                          <ol start="10">
                            <li>
                              If Answer is Yes -
                              <span className="Apple-converted-space">
                                &nbsp;{" "}
                              </span>
                              Do you interested to get back from our
                              professional team for commercial validation ?
                              <span className="Apple-converted-space">
                                &nbsp;
                              </span>
                            </li>
                          </ol>
                          <p>
                            Yes or No
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <link
                            href="https://getbootstrap.com/docs/4.0/dist/css/bootstrap.min.css"
                            rel="stylesheet"
                          />
                          <div className="container"></div>
                          <p>
                            <strong>Malayalam</strong>
                          </p>
                          <p>1. നിങ്ങളുടെ വീട്ടിൽ പ്ലാവുണ്ടോ ?</p>
                          <p>
                            2. ചക്കയുടെ വിപണത്തിൽ കൂടി വരുമാനം ഉണ്ടാക്കുവാൻ
                            താത്പ്പര്യം ഉണ്ടോ ?
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                          <p>
                            3
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            . ഉണ്ടെങ്കിൽ എത്ര പ്ലാവുണ്ട് ?
                          </p>
                          <p>
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                            .a എത്ര കൂഴ ? b
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            . എത്ര വരിക്ക ?
                          </p>
                          <p>4. നിങളുടെ വീട്ടിൽ എന്തൊക്കെ ഇനം പ്ലാവുണ്ട് ?</p>
                          <p>options:</p>
                          <p>മുട്ടം വരിക്ക</p>
                          <p>സിംഗപ്പൂർ പ്ലാവ്</p>
                          <p>തേൻ വരിക്ക</p>
                          <p>സിന്ദൂർ</p>
                          <p>വിയറ്റ്നാം സൂപ്പർ ഏർളി</p>
                          <p>ആയുർ ജാക്</p>
                          <p>മറ്റുള്ളവ (Others )</p>
                          <p>&nbsp;</p>
                          <p>5. നിങ്ങളുടെ പ്ലാവ് കായ്ക്കുന്ന സമയം ?</p>
                          <p>മാസം ( starting Month ) (ending month )</p>
                          <p>
                            6 .പോയവർഷം ചക്കയുടെ വിപണനത്തിൽകൂടി എത്ര വരുമാനം
                            ലഭിച്ചു ?
                            <span className="Apple-converted-space"></span>
                          </p>
                          <p>
                            7 . കൂടുതൽ വരുമാനം ലഭിച്ചാൽ ജാക്ക്ഫ്രൂട്ട് വേൾഡ്
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            (Jackfruitworld) മുഘേനെ{" "}
                            <span className="Apple-converted-space"> </span>ചക്ക
                            കൊടുക്കാൻ നിങ്ങൾക്ക്
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            താത്പ്പര്യം ഉണ്ടോ
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            ?
                          </p>
                          <p>
                            8 . നിങ്ങളുടെ വീട്ടിലെ ചക്ക കേടുപാട് കൂടാതെ ഇട്ട്
                            അടുത്തുള്ള കളക്ഷൻ പോയിന്റിൽ
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            എത്തിച്ചു തരാൻ നിങ്ങൾക് സാധിക്കുമോ
                            <span className="Apple-converted-space"> </span>?
                          </p>
                          <p>
                            9 .ചക്ക വ്യവസായ അടിസ്ഥാനത്തിൽ കൃഷി ചെയ്യുവാൻ
                            താത്പ്പര്യമുണ്ടോ ?
                          </p>
                          <p>
                            If the Answer is Yes
                            <span className="Apple-converted-space"></span>
                          </p>
                          <p>
                            10 .ഞങ്ങളുടെ പ്ലാവുകൃഷി വിദക്തരുടെ കൺസൾട്ടൻസി
                            ആവശ്യമുണ്ടോ ?
                          </p>
                          <p>Yes or No</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      width: "98%",
                    }}
                  >
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell width="80px" align="center">
                              S.No
                            </TableCell>
                            <TableCell width="180px" align="center">
                              Name
                            </TableCell>
                            <TableCell width="180px" align="center">
                              Organisation
                            </TableCell>
                            <TableCell width="180px" align="center">
                              Agent
                            </TableCell>
                            <TableCell width="180px" align="center">
                              User Type
                            </TableCell>
                            <TableCell width="180px" align="center">
                              Mobile
                            </TableCell>
                            <TableCell width="180px" align="center">
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.length > 0 ? (
                            data.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell align="center">{index+1}</TableCell>
                                <TableCell align="center">{row.name}</TableCell>
                                <TableCell align="center">
                                <span
                                    data-tooltip-id="5"
                                    data-tooltip-content={`${row.orgname}`}
                                  >
                                    {row.organisation}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  {row.agent}
                                </TableCell>
                                <TableCell align="center">{row.type}</TableCell>
                                <TableCell align="center">
                                  {row.mobile_no}
                                </TableCell>

                                <TableCell>{deletea(null, row)}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={7} align="center">
                              {STRING_CONSTANTS.NO_DATA_FOUND}
                              </TableCell>
                            </TableRow>
                          )}
                          <Dialog
                            open={show}
                            onClose={handleClose}
                            // aria-labelledby="survey-details-dialog-title"

                            aria-labelledby="details-modal-title"
                            aria-describedby="details-modal-description"
                            fullWidth
                            disableEnforceFocus
                            maxWidth="lg"
                          >
                            <Grid item xs={12} className="modal-header">
                              <h3 id="details-modal-title"> Survey Details</h3>
                              <Button onClick={handleClose} color="inherit">
                                <span
                                  style={{ fontSize: "20px" }}
                                  aria-hidden="true"
                                >
                                  &#128473;
                                </span>
                              </Button>
                            </Grid>
                            <DialogContent dividers>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION1}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans1}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION2}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans2}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION2A}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans2a}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION2B}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans2b}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION3}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans3}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION4}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans4}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION5}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans5}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION6}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans6}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION7}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans7}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION8}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans8}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION9}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.specialJackfruitInfo && (
                                        <Button
                                          onClick={toggleDetails}
                                          aria-label={
                                            showDetails ? "Collapse" : "Expand"
                                          }
                                          style={{
                                            border: "none",
                                            background: "none",
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {showDetails ? (
                                            <span>
                                              Hide Details
                                              <FiArrowUp
                                                style={{
                                                  verticalAlign: "middle",
                                                  paddingRight: "5px",
                                                }}
                                              />
                                            </span>
                                          ) : (
                                            <span>
                                              Show Details
                                              <FiArrowDown
                                                style={{
                                                  verticalAlign: "middle",
                                                  paddingRight: "5px",
                                                }}
                                              />
                                            </span>
                                          )}
                                        </Button>
                                      )}
                                      {survey.ans9}
                                    </TableCell>
                                  </TableRow>
                                  {survey.specialJackfruitInfo &&
                                    showDetails && (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_LOCAL_NAME
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .hasLocalName
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {SURVEY_QUESTIONS.SPECIAL_INFO_AGE}
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .ageOfTree
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_HEIGHT
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .heightOfTree
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_WIDTH
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .widthOfTrunk
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_YIELD
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .fruitsPerYear
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_SEASON
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .harvestSeason
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {SURVEY_QUESTIONS.SPECIAL_INFO_SIZE}
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .fruitSize
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_BULBS
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .numberOfBulbs
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_BULB_COLOR
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .bulbColor
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_RIND_COLOR
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .jackfruitRindColor
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_BULB_SIZE
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .bulbSize
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_BULB_TEXTURE
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .bulbTexture
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_TRADITIONAL_USE
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .traditionalUse
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_OTHER_DETAILS
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .otherDetails
                                            }
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION10}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans10}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleClose}
                                variant="outlined"
                                color="error"
                              >
                                Close
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <ImageModal
                            open={open}
                            handleClose={handleClose1}
                            imageSrc={images}
                          ></ImageModal>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
