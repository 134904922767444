import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { getAllInvoices } from "../actions/adminAction";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  Grid,
  Button,
} from "@mui/material";
import {
  ALERT_BUTTON_OK,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../constants/alertMessage";
import { privateApiCall } from "../api/privateApi";
import { STRING_CONSTANTS } from "../constants/stringConstants";
import { formatDate } from "../utils/dateUtils";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { VIEW_DET } from "../constants/url";
import { FiEye } from "react-icons/fi";

const Invoices = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [detailedInfo, setDetailedInfo] = useState({});
  const [open1, setOpen1] = useState(false);

  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [values, setValues] = useState({
    error: "",
    invoices: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    loadInvoices();
  }, []);

  useEffect(() => {
    if (searchValue || startDate || endDate) {
      handleSearch();
    } else {
        loadInvoices();
    }
  }, [startDate, endDate, searchValue]);

  const handleChangeStart = (date) => {
    setStartDate(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };
  const handleChangeEnd = (date) => {
    setEndDate(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };


  const handleViewDetails = async (id)=>{
const viewDetailsInfo = values.invoices.filter((item)=>{
   return item._id === id;
})
setDetailedInfo(...viewDetailsInfo)
console.log(detailedInfo,"detialsedINfo");

setOpen1(true)
  }

  const loadInvoices = () => {
    getAllInvoices()
      .then((response) => {
        if (response && response.status !== undefined && response.invoices) {
          if (response.status) {
            console.log(response.invoices,"invoies");
            
            setValues({ ...values, invoices: response.invoices });
          } else {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  const handleSearch = async () => {
    try {
      const requestUrl = `/api/payments/searchPayments?&query=${searchValue}&startDate=${startDate}&endDate=${endDate}`;
      const response = await privateApiCall(requestUrl, "GET");
      if (response.data.payments) {
        setValues({ ...values, invoices: response.invoices });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleClose1 = ()=>{
    setOpen1(false)
  }

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.INVOICES}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.INVOICES}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div
                      className="row container-fluid"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {permissions.some(
                        (item) =>
                          item.keyword === "payment" &&
                          item.isDateSearch === true
                      ) && (
                        <>
                          <div style={{ paddingRight: "16px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  label="Start Date&nbsp;&nbsp;&nbsp;"
                                  format="DD/MM/YYYY"
                                  defaultValue={dayjs(startDate)}
                                  name="startDate"
                                  onChange={handleChangeStart}
                                  required
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!startDate &&
                                        !dayjs(startDate).isValid(),
                                      sx: {
                                        width: "160px", // Adjust width
                                        "& .MuiInputBase-root": {
                                          fontSize: "11px", // Adjust font size
                                          padding: "-4px", // Adjust padding
                                        },
                                        "& .MuiInputLabel-root": {
                                          fontSize: "14px", // Adjust label size
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>

                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  label="End Date&nbsp;&nbsp;&nbsp;"
                                  format="DD/MM/YYYY"
                                  defaultValue={dayjs(endDate)}
                                  name="endDate"
                                  onChange={handleChangeEnd}
                                  required
                                  // You can control the error state with this condition
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!endDate && !dayjs(endDate).isValid(),
                                      sx: {
                                        width: "160px", // Adjust width
                                        "& .MuiInputBase-root": {
                                          fontSize: "11px", // Adjust font size
                                          padding: "-4px", // Adjust padding
                                        },
                                        "& .MuiInputLabel-root": {
                                          fontSize: "14px", // Adjust label size
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </>
                      )}

                      <div
                        style={{
                          position: "relative",
                          marginLeft: "auto",
                        }}
                      >
                        {permissions.some(
                          (item) =>
                            item.keyword === "payment" && item.isSearch === true
                        ) && (
                          <>
                            <input
                              type="text"
                              placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                              className="form-control"
                              style={{
                                width: "200px",
                                paddingLeft: "35px",
                                boxSizing: "border-box",
                                marginLeft: "auto",
                              }}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <i
                              className="fas fa-search"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#aaa",
                              }}
                            ></i>
                          </>
                        )}
                      </div>
                    </div>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                            <TableCell>{STRING_CONSTANTS.INVOICE_ID}</TableCell>
                            <TableCell>{STRING_CONSTANTS.SOLD_BY}</TableCell>
                            <TableCell>{STRING_CONSTANTS.CONTACT_NO}</TableCell>
                            <TableCell>{STRING_CONSTANTS.AMOUNT}</TableCell>
                            <TableCell>{STRING_CONSTANTS.DATE}</TableCell>
                            <TableCell>{STRING_CONSTANTS.ACTIONS}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.invoices
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((invoice, index) => (
                              <TableRow key={invoice._id}>
                                <TableCell>
                                  {page * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell>{invoice._id}</TableCell>
                                <TableCell>{invoice.soldBy.companyName}</TableCell>
                                <TableCell>{invoice.totals.totalTax}</TableCell>
                                <TableCell>{invoice.totals.totalAmount}</TableCell>
                                <TableCell>
                                  {formatDate(invoice.orderDetails.orderDate)}
                                </TableCell>
                                <TableCell align="center">
                                <IconButton
                                      onClick={() => handleViewDetails(invoice._id)}
                                    >
                                     <FiEye
                                        size="18px"
                                        color="#0978F2"
                                        data-tooltip-id="1"
                                        data-tooltip-content={`${VIEW_DET}`}
                                      />
                                </IconButton>

                             
                                </TableCell>
                              </TableRow>
                            ))}
                              <Dialog
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="details-modal-title"
                            aria-describedby="details-modal-description"
                            style={{
                              maxHeight: "80vh",
                              overflowY: "auto",
                              marginTop: "100px",
                              
                            }}
                            fullWidth
                            disableEnforceFocus
                            maxWidth="lg"
                          >
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="modal-content"
                            >
                              <Grid item xs={12} className="modal-header">
                                <h5 id="details-modal-title">
                                  {STRING_CONSTANTS.DETAILS}
                                </h5>
                                <Button onClick={handleClose1} color="inherit">
                                  <span
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  >
                                    &#128473;
                                  </span>
                                </Button>
                              </Grid>
                              <Grid item xs={12} className="modal-body">
                                <Table className="col-12">
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.BILLING_ADD}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                     {detailedInfo.billingAddress?.name || ""}
                                     <div>

                                     {detailedInfo.billingAddress?.address || ""}
                                     </div>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.SHIPPING_ADDRESS}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                     {detailedInfo.shippingAddress?.name  || ""}
                                     <div>

                                     {detailedInfo.shippingAddress?.address || ""}
                                     </div>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.SOLD_BY}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.soldBy?.name || ""}
                                   <div>   {detailedInfo.soldBy?.address || ""}</div>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.ORD_ID}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {detailedInfo.orderDetails?.orderNumber || ""}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.ORDER_DATE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {detailedInfo.orderDetails?.orderDate || ""
                                      }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.INVOICE_NO}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {detailedInfo.orderDetails?.invoiceNumber || "" }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.INVOICE_DATE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {detailedInfo.orderDetails?.invoiceDate || "" }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.TOTAL_AMOUNT}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                   {"₹"}   {detailedInfo.totals?.totalAmount || ""}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.TOTAL_TAX}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {detailedInfo.totals?.totalTax || ""} {"%"}
                                    </TableCell>
                                  </TableRow>                                 
                                </Table>
                              </Grid>
                              <Grid item xs={12} className="modal-footer">
                                <Button
                                  onClick={handleClose1}
                                  variant="outlined"
                                  color="error"
                                >
                                  {STRING_CONSTANTS.CLOSE}
                                </Button>
                              </Grid>
                            </Grid>
                          </Dialog>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      component="div"
                      count={values.invoices.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
