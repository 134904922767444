import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, TextField, Grid } from "@mui/material";
import {
  ADD_MORE_DETAILS,
  GET_ORG_PROFILE
} from "../../constants/url";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { privateApiCall } from "../../api/privateApi";
import { ALERT_BUTTON_OK, ALERT_ICON_ERR, CONFIRM_PASSWORD_ERR, ERR_ALERT_TITLE, ERR_GETTING, ERR_POSTING, SUCC_ALERT_TITLE } from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const Editprofile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orglogo, setOrglogo] = useState("/assets/icons/try.jpg");
  const [imageURL, setImageURL] = useState("/assets/icons/try.jpg");
  const [password, setPassword] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [currentField,setCurrentField]=useState(false)
  const [newField,setNewField]=useState(false)
  const [confirmField,setConfirmField]=useState(false)
  const [formData, setFormData] = useState({
    secondaryEmail: "",
    password: "",
    yearOfEstablishment: "",
    ceoName: "",
    websiteURL: "",
    googleBusinessURL: "",
    facebookBusinessURL: "",
    instagramBusinessURL: "",
    pincode: "",
    state: "",
    Address: "",
    locality: "",
    city: "",
    country: "",
    area: "",
    landmark: "",
    gstin: "",
    tan: "",
    iec: "",
    pan: "",
    cin: "",
    accountnumber: "",
    holdername: "",
    bankname: "",
    reaccountnumber: "",
    ifsc: "",
    branchname: "",
  });
 
  const [value, setValue] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleTogglePasswordVisibility=(field)=>{
    if (field === "current") {
      setCurrentField(!currentField);
    } else if (field === "new") {
      setNewField(!newField);
    } else if (field === "confirm") {
      setConfirmField(!confirmField);
    }
  }

  const navigation = useNavigate();

  const handleNext = (event) => {
    event.preventDefault();
    const nextTabMap = {
      1: "2",
      2: "3",
      3: "4",
    };

    const nextTab = nextTabMap[value];
    if (nextTab) {
      setValue(nextTab);
    } else {
      handleEditSubmit();
    }
  };
  const handlePasswordChanges = (e) => {
    const { name, value } = e.target;
    if (name === "currentPassword") setCurrentPassword(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "retypeNewPassword") setRetypeNewPassword(value);
  };

  const togglePasswordFields = () => {
    setShowPasswordFields(!showPasswordFields);
    if (!showPasswordFields) {
      setCurrentPassword("");
      setNewPassword("");
      setRetypeNewPassword("");
    }
  };
  useEffect(() => {
    if (
      cookies.get("login_type") !== "callSupport"
      // && cookies.get("login_type") !== "admin"
    ) {
      const requestUrl =`${GET_ORG_PROFILE}/${cookies.get("org_id")}?token=${cookies.get("org_token")}`
      privateApiCall(requestUrl,'GET')
      .then((res) => {
        console.log(res.data.admin, "edit profile");
        console.log(res.data.admin[0].logo, "logo path got it.");
        const adminData = res.data.admin[0];
        setName(adminData.name);
        setEmail(adminData.email);
        setPassword(adminData.password);
        setFormData(adminData);
        // setOrglogo(adminData.logo)
        setImageURL(adminData.logo);
        setValue("1")

      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      });

      
    }
  }, []);
 
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setOrglogo(file);
    setImageURL(URL.createObjectURL(file));
  };

  const org_token = cookies.get("org_token");

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      formDataToSend.append("email", email);
      formDataToSend.append("logo", orglogo);

      if (!showPasswordFields || !retypeNewPassword) {
        try {
          const requestUrl = `${ADD_MORE_DETAILS}?token=${org_token}`
          const response = await privateApiCall(requestUrl,'POST',formDataToSend)
          if (response.data.error) {
           await  Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ ALERT_ICON_ERR }`,
              text: `${ERR_POSTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ ALERT_BUTTON_OK }`,
            });
          } else {
            await Swal.fire({
              title: `${SUCC_ALERT_TITLE}`,
              text: response.data.message,
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
              showCancelButton: false,
            }).then(() => {
              navigation("/organisation/viewprofile");
            });
          }
        } catch (error) {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ ALERT_ICON_ERR }`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ ALERT_BUTTON_OK }`,
          });
        }
  
     
      } else {
        if (newPassword !== retypeNewPassword) {
          Swal.fire({
            icon: "error",
            title: `${ERR_ALERT_TITLE}`,
            text: `${CONFIRM_PASSWORD_ERR}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
          return;
        }
  
        formDataToSend.append("currentPassword", currentPassword);
        formDataToSend.append("newPassword", newPassword);
        try {
            const requestUrl = `${ADD_MORE_DETAILS}?token=${org_token}`
            const response = await privateApiCall(requestUrl,"POST",formDataToSend)
            if (response.data.error) {
             await  Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                icon: `${ ALERT_ICON_ERR }`,
                text: `${ERR_POSTING}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ ALERT_BUTTON_OK }`,
              });
            } else {
              await Swal.fire({
                title: response.data.message,
                text: "",
                icon: "success",
                allowOutsideClick: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
                showCancelButton: false,
              }).then(() => {
                navigation("/organisation/viewprofile");
              });
            }
          } catch (error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: "error",
              confirmButtonText: `${ALERT_BUTTON_OK}`,
              showCancelButton: false,
            });
          }
  
        
      }
    } catch (error) {
      
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ ALERT_ICON_ERR }`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ ALERT_BUTTON_OK }`,
        });
      }
    }
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">{STRING_CONSTANTS.EDIT_PROFILE}</h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">{STRING_CONSTANTS.EDIT_PROFILE}</li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <form onSubmit={handleEditSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="card-box">
                    <div className="row">
                      <div className="col-12">
                        <div className="p-20">
                          <TabContext value={value}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                              >
                                <Tab label="Company Details" value="1" />
                                <Tab label="Address Details" value="2" />
                                <Tab label="Statutory Details" value="3" />
                                <Tab label="Bank Account Details" value="4" />
                              </TabList>
                            </Box>
                            <TabPanel value="1">
                              <div style={{ marginBottom: "30px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={6}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "left",
                                      }}
                                    >
                                      <label
                                         
                                      >
                                        {STRING_CONSTANTS.UPDATE_LOGO}
                                      </label>
                                      <img
                                        src={
                                          imageURL || "/assets/icons/try.jpg"
                                        }
                                        alt={STRING_CONSTANTS.COMPANY_LOGO}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          marginBottom: "10px",
                                          borderRadius: "50%",
                                          objectFit: "cover",
                                        }}
                                      />
                                       
                                      <input
                                         
                                        type="file"
                                        accept="image/*"
                                        style={{
                                          marginTop: "10px",
                                        }}
                                        onChange={handleImageUpload}
                                      />
                                    </div>
                                    <TextField
                                      label="Company Name"
                                      value={formData.name}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                     <TextField
                                      
                                      value={formData.displayName}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Primary Email"
                                      name="email"
                                      value={formData.email}
                                      fullWidth
                                      InputProps={{ readOnly: true }}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                    <TextField
                                      label="Secondary Email"
                                      name="secondaryEmail"
                                      value={formData.secondaryEmail}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          secondaryEmail: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      variant="outlined"
                                      margin="normal"
                                      tabIndex="5"
                                    />
                                    <TextField
                                      label="Year of Establishment"
                                      name="yearOfEstablishment"
                                      value={formData.yearOfEstablishment}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          yearOfEstablishment: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      variant="outlined"
                                      margin="normal"
                                      placeholder={STRING_CONSTANTS.ENTER_YEAR}
                                      tabIndex="7"
                                    />
                                    {showPasswordFields && (
                                      <>
                                        <TextField
                                          label="Current Password"
                                          name="currentPassword"
                                          type={currentField ? "text" : "password"}
                                          
                                          value={currentPassword}
                                          onChange={handlePasswordChanges}
                                          fullWidth
                                          variant="outlined"
                                          margin="normal"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={() => handleTogglePasswordVisibility("current")}
                                                  edge="end"
                                                >
                                                  {currentField ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                        <TextField
                                          label="New Password"
                                          name="newPassword"
                                          type={newField ? "text" : "password"}
                                          value={newPassword}
                                          onChange={handlePasswordChanges}
                                          fullWidth
                                          variant="outlined"
                                          margin="normal"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={() => handleTogglePasswordVisibility("new")}
                                                  edge="end"
                                                >
                                                  {newField ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                        <TextField
                                          label="Confirm Password"
                                          name="retypeNewPassword"
                                          type={confirmField ? "text" : "password"}
                                          value={retypeNewPassword}
                                          onChange={handlePasswordChanges}
                                          fullWidth
                                          variant="outlined"
                                          margin="normal"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={() => handleTogglePasswordVisibility("confirm")}
                                                  edge="end"
                                                >
                                                  {confirmField ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    md={6}
                                    style={{ marginTop: "177px" }}
                                  >
                                    <TextField
                                      label="CEO Name"
                                      name="ceoName"
                                      value={formData.ceoName}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          ceoName: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      variant="outlined"
                                      margin="normal"
                                      placeholder={STRING_CONSTANTS.ENTER_CEO_NAME}
                                      tabIndex="1"
                                    />
                                    <TextField
                                      label="Website URL"
                                      name="websiteURL"
                                      value={formData.websiteURL}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          websiteURL: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      variant="outlined"
                                      margin="normal"
                                      placeholder={STRING_CONSTANTS.ENTER_WEBSITE_URL}
                                      tabIndex="2"
                                    />
                                    <TextField
                                      label="Google Business URL"
                                      name="googleBusinessURL"
                                      value={formData.googleBusinessURL}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          googleBusinessURL: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      variant="outlined"
                                      margin="normal"
                                      placeholder={STRING_CONSTANTS.ENTER_GOOGLE_BUSINESS_URL}
                                      tabIndex="4"
                                    />
                                    <TextField
                                      label="Facebook Business URL"
                                      name="facebookBusinessURL"
                                      value={formData.facebookBusinessURL}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          facebookBusinessURL: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      variant="outlined"
                                      margin="normal"
                                      placeholder={STRING_CONSTANTS.ENTER_FACEBOOK_BUSINESS_URL}
                                      tabIndex="6"
                                    />
                                    <TextField
                                      label="Instagram Business URL"
                                      name="instagramBusinessURL"
                                      value={formData.instagramBusinessURL}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          instagramBusinessURL: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      variant="outlined"
                                      margin="normal"
                                      placeholder={STRING_CONSTANTS.ENTER_INSTA_BUSINESS_URL}
                                      tabIndex="8"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#0BAAE5",
                                    marginRight: "10px",
                                  }}
                                  onClick={handleNext}
                                  type="button"
                                >
                                  {STRING_CONSTANTS.NEXT}
                                </Button>
                                {!showPasswordFields && (
                                  <Button
                                    variant="contained"
                                    style={{ backgroundColor: "#808080" }}
                                    onClick={togglePasswordFields}
                                    type="button"
                                  >
                                    {STRING_CONSTANTS.CHANGE_PASSWORD}
                                  </Button>
                                )}
                              </div>
                            </TabPanel>

                            <TabPanel value="2">
                              <div style={{ marginBottom: "30px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Address (Building No/floor)"
                                      value={formData.Address}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          Address: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.ADDRESS_PLACEHOLDER}
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="1"
                                    />
                                    <TextField
                                      label="Address (Area/Street)"
                                      value={formData.area}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          area: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.AREA_STREET_PLACEHOLDER}
                                      variant="outlined"
                                      margin="normal"
                                      tabIndex="2"
                                    />
                                    <TextField
                                      label="Locality"
                                      value={formData.locality}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          locality: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.LOCALITY_PLACEHOLDER}
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="3"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Landmark"
                                      value={formData.landmark}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          landmark: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.ENTER_LANDMARK}
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="4"
                                    />
                                    <TextField
                                      label="City"
                                      value={formData.city}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          city: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.ENTER_LANDMARK}
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="5"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "#0BAAE5" }}
                                  onClick={handleNext}
                                  type="button"
                                >
                                  {STRING_CONSTANTS.NEXT}
                                </Button>
                              </div>
                            </TabPanel>

                            <TabPanel value="3">
                              <div style={{ marginBottom: "30px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="GSTIN"
                                      value={formData.gstin}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          gstin: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.ENTER_GST}
                                      name="gstin"
                                      variant="outlined"
                                      margin="normal"
                                      tabIndex="1"
                                    />
                                    <TextField
                                      label="TAN"
                                      value={formData.tan}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          tan: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.TAN_PALCEHOLDER}
                                      name="tan"
                                      variant="outlined"
                                      margin="normal"
                                      tabIndex="3"
                                    />
                                    <TextField
                                      label="Import Export Code(IEC)"
                                      value={formData.iec}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          iec: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.IEC_PLACEHOLDER}
                                      name="iec"
                                      variant="outlined"
                                      margin="normal"
                                      tabIndex="5"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="PAN"
                                      value={formData.pan}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          pan: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.PAN_PLACEHOLDER}
                                      name="pan"
                                      variant="outlined"
                                      margin="normal"
                                      tabIndex="2"
                                    />
                                    <TextField
                                      label="CIN/LLPIN"
                                      value={formData.cin}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          cin: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.CIN_PLACEHOLDER}
                                      name="cin"
                                      variant="outlined"
                                      margin="normal"
                                      tabIndex="4"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "#0BAAE5" }}
                                  onClick={handleNext}
                                  type="button"
                                >
                                  {STRING_CONSTANTS.NEXT}
                                </Button>
                              </div>
                            </TabPanel>

                            <TabPanel value="4">
                              <div style={{ marginBottom: "30px" }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Account Number"
                                      value={formData.accountnumber}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          accountnumber: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.ACCOUNT_NUMBER_PALCEHOLDER}
                                      name="accountnumber"
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="1"
                                    />
                                    <TextField
                                      label="Re-enter Account Number"
                                      value={formData.reaccountnumber}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          reaccountnumber: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.CONFIRM_ACCOUNT_NUMBER_PALCEHOLDER}
                                      name="reaccountnumber"
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="2"
                                    />
                                    <TextField
                                      label="A/c Holder Name"
                                      value={formData.holdername}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          holdername: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.ACCNT_HOLDER_NAME_PLACEHOLDER}
                                      name="holdername"
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="3"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="IFSC Code"
                                      value={formData.ifsc}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          ifsc: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.IFSE_CODE_PLACEHOLDER}
                                      name="ifsc"
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="4"
                                    />
                                    <TextField
                                      label="Bank Name (As per IFSC Code)"
                                      value={formData.bankname}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          bankname: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.BANK_NAME_PLACEHOLDER}
                                      name="bankname"
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="5"
                                    />
                                    <TextField
                                      label="Branch Name (As per IFSC Code)"
                                      value={formData.branchname}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          branchname: e.target.value,
                                        });
                                      }}
                                      fullWidth
                                      placeholder={STRING_CONSTANTS.ENTER_BRANCH_NAME}
                                      name="branchname"
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      tabIndex="6"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "#0BAAE5" }}
                                  type="submit"
                                >
                                  {STRING_CONSTANTS.UPDATE}
                                </Button>
                              </div>
                            </TabPanel>
                          </TabContext>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editprofile;
