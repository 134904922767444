import fetch from "isomorphic-fetch";
import { API as url1 } from "../config";
import Cookies from "universal-cookie";
import {
  GET_ALL_ORDERS,
  GET_ALL_PAYMENTS,
  APP_ORG_MEM,
  ADD_ORG_MEM,
  EDIT_ORG_MEM,
  DELETE_ORG_MEM,
  MY_SELL_PRODUCT_DETAILS,
  GET_ORG_MEMBER,
  GET_MEMBER_WITH,
  ADD_CROP,
  GET_ORG_FILTER,
  GET_ORG,
  ADD_ORG,
  EDIT_ORG,
  DELETE_ORG,
  USERTYPE,
  ADDUSER_TYPE,
  EDITUSER_TYPE,
  DELETE_USERYTPE,
  INTRESTED_CROP,
  DELETE_CROP,
  KEYWORDS,
  ADMIN_ADD_USER_URL,
  SURVEY_DELETE,
  ASSIGN_VOUCHER,
  SEARCH_USERTYPE,
  GET_ROLES,
  GET_ROLEBYID,
  GET_PERMISSIONS,
  MAP_PERMISSIONS,
  GET_PORTAL_USERS,
  SAVE_PORTAL_USER,
  UPDATE_PORTAL_USER_STATUS,
  GET_ALL_INVOICES
} from "../constants/url";
import { ERR_POSTING, ERR_GETTING, ERR_ALERT_TITLE, NETWORK_NOT_OK } from "../constants/alertMessage";
import Swal from "sweetalert2/dist/sweetalert2";
import { privateApiCall } from "../api/privateApi";

const cookies = new Cookies();

export const getAllOrders = () => {
  return privateApiCall(`${GET_ALL_ORDERS}`, 'GET')

    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const getAllPayments = () => {
  return privateApiCall(`${GET_ALL_PAYMENTS}`, 'GET')

    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const getAllInvoices = () => {
  return privateApiCall(`${GET_ALL_INVOICES}`, 'GET')

    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};


export const getSellProductDetails = (id) => {
  const jwtToken = cookies.get("jwtToken");

  return fetch(`${url1}${MY_SELL_PRODUCT_DETAILS}/?product_id=${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`, // Add JWT token to headers
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

//Organisation Member
export const getOrganisationMember = (token) => {
  const requestUrl = `${GET_ORG_MEMBER}?token=${token}`
  return privateApiCall(requestUrl, "GET")

    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const getMemberWithOrg = (_id) => {
  const requestUrl = `${GET_MEMBER_WITH}?org_id=${_id}`
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
  // return fetch(`${url1}${GET_MEMBER_WITH}?org_id=${_id}`, {
  //   method: "GET",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${jwtToken}`, // Add JWT token to headers
  //   },
  // })

};


export const addOrganisationMember = (body) => {
  const requestUrl = `${ADD_ORG_MEM}`;
  return privateApiCall(requestUrl, 'POST', body)
    .then((response) => {
      
      return response.data;
    })
    .catch((err) => {
      

      // Check if the error response contains a custom message
      const errorMessage = err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : `${ERR_POSTING}`;

      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: errorMessage,
        confirmButtonText: "OK",
      });
    });
};


export const editOrganisationMember = (body, type_id) => {
  const requestUrl = `${EDIT_ORG_MEM}?type_id=${type_id}`
  return privateApiCall(requestUrl, "POST", body)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_POSTING}`,
        confirmButtonText: "OK",
      });
    });

};

export const deleteOrganisationMember = (type_id) => {
  const requestUrl = `${DELETE_ORG_MEM}/?type_id=${type_id}`
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });


};

export const ApproveOrganisationMember = (type_id) => {
  const requestUrl = `${APP_ORG_MEM}/?type_id=${type_id}`
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};


//Organisation

export const getOrgFilters = (token) => {
  const requestUrl = `${GET_ORG_FILTER}/?token=${token}`
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};


export const getOrganisation = (token, place, pincode, page, rowsPerPage) => {


  const requestUrl = `${GET_ORG}/?token=${token}&place=${place}&pincode=${pincode}&page=${page}&limit=${rowsPerPage}`
  return privateApiCall(requestUrl, "GET")

    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
      }
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};
export const addOrganisation = (name, token) => {
  const jwtToken = cookies.get("jwtToken");

  return fetch(`${url1}${ADD_ORG}/?name=${name}&token=${token}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`, // Add JWT token to headers
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const editOrganisation = (name, type_id) => {
  const jwtToken = cookies.get("jwtToken");

  return fetch(`${url1}${EDIT_ORG}/?name=${name}&type_id=${type_id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`, // Add JWT token to headers
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};


export const deleteOrganisation = (type_id, status) => {
  const requestUrl = `${DELETE_ORG}/?type_id=${type_id}&status=${status}`
  return privateApiCall(requestUrl, 'GET')

    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};


export const getParentOrganisation = (_id) => {


  const requestUrl = `/api/organisation/getParentOrganisation?org_id=${_id}`
  return privateApiCall(requestUrl, "GET")

    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
      }
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};
//child organisation
export const getChildOrganisation = (_id) => {


  const requestUrl = `/api/organisation/getChildOrganisation?org_id=${_id}`
  return privateApiCall(requestUrl, "GET")

    .then((response) => {
      if (response.status !== 200) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
      }
      return response.data
    })
    .catch((err) => {
      
      // Swal.fire({
      //   icon: "warning",
       
      //   text:'No data found',
      //   confirmButtonText: "OK",
      // });
    });
};

export const addChildOrganisation = (name, token) => {
  const jwtToken = cookies.get("jwtToken");

  return fetch(`/api/organisation/addChildOrganisation`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`, // Add JWT token to headers
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

//User Type
export const getUserType = (token) => {
  const requestUrl = `${USERTYPE}/?token=${token}`
  return privateApiCall(requestUrl, 'GET')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};


export const addName = (name) => {
  const requestUrl = `${ADDUSER_TYPE}/?name=${name}`
  return privateApiCall(requestUrl, 'GET')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const searchUserType = (query) => {
  const jwtToken = cookies.get("jwtToken");

  return fetch(`${url1}${SEARCH_USERTYPE}?query=${query}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${NETWORK_NOT_OK}`);
      }
      return response.json();
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const editName = (name, type_id) => {
  const requestUrl = `${EDITUSER_TYPE}/?name=${name}&type_id=${type_id}`
  return privateApiCall(requestUrl, 'GET')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const deleteUserType = (_id) => {
  const requestUrl = `${DELETE_USERYTPE}?_id=${_id}`
  return privateApiCall(requestUrl, 'GET')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};


// intrested Crops
export const getIntrestedCrops = (token) => {
  const requestUrl = `${INTRESTED_CROP}/?token=${token}`
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};
export const addCrop = (crops) => {
  const requestUrl = `${ADD_CROP}`
  return privateApiCall(requestUrl, "POST", crops)

    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};


export const editCrops = (crops, type_id) => {
  const requestUrl = `${EDITUSER_TYPE}/?type_id=${type_id}`
  return privateApiCall(requestUrl, 'POST', crops)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_POSTING}`,
        confirmButtonText: "OK",
      });
    });
};


export const deleteCrop = (token, _id) => {

  const requestUrl = `${DELETE_CROP}/?_id=${_id}&token=${token}`
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

//Keywords

export const getKeywords = (admin_id) => {
  const requestUrl = `${KEYWORDS}/?admin_id=${admin_id}`
  return privateApiCall(requestUrl, 'GET')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};
export const postKeywords = (keyword_id, language, keys) => {

  const reqUrl = `${KEYWORDS}/?keyword_id=${keyword_id}&language=${language}`
  return privateApiCall(reqUrl, "Post", keys).then((res) => {
    return res.data
  })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_POSTING}`,
        confirmButtonText: "OK",
      });
    })

};
// Add Customer
export const addCustomer = (mobile_no, name, email, country_code) => {
  const requestUrl = `${ADMIN_ADD_USER_URL}/?mobile_no=${mobile_no}&refCode=&name=${name}&email=${email}&country_code=${country_code}`
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};
export const deleteSurvey = (id, token) => {
  // const requestUrl = `/surveyDelete/?token=${token}&id=${id}`
  const requestUrl = `/surveyDelete/?id=${id}&token=${token}`

  console.log(requestUrl,"REQUEST URLSS")
  return privateApiCall(requestUrl, "GET")
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const assignVoucher = (body, token) => {
  const requestUrl = `${ASSIGN_VOUCHER}/?token=${token}`
  return privateApiCall(requestUrl, 'POST', body)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_POSTING}`,
        confirmButtonText: "OK",
      });
    });
};

export const getRoles = async () => {
  const requestUrl = `${GET_ROLES}`
  try {
    const response = await privateApiCall(requestUrl, "GET");
    if (response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    }
    return response.data;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: `${ERR_ALERT_TITLE}`,
      text: `${ERR_GETTING}`,
      confirmButtonText: "OK",
    });
  }
};

export const getRoleById = async (roleId) => {
  const requestUrl = `${GET_ROLEBYID}/?roleId=${roleId}`
  try {
    const response = await privateApiCall(requestUrl, "GET");
    if (response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    }
    return response.data;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: `${ERR_ALERT_TITLE}`,
      text: `${ERR_GETTING}`,
      confirmButtonText: "OK",
    });
  }
};

export const getPrmissions = async () => {
  const requestUrl = `${GET_PERMISSIONS}`
  try {
    const response = await privateApiCall(requestUrl, "GET");
    if (response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    }
    return response.data;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: `${ERR_ALERT_TITLE}`,
      text: `${ERR_GETTING}`,
      confirmButtonText: "OK",
    });
  }
};

export const mapPermissions = async (body) => {
  const requestUrl = `${MAP_PERMISSIONS}`
  try {
    const response = await privateApiCall(requestUrl, 'POST', body);
    return response.data;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: `${ERR_ALERT_TITLE}`,
      text: `${ERR_POSTING}`,
      confirmButtonText: "OK",
    });
  }
};

export const getPortalUsers = async () => {
  const requestUrl = `${GET_PORTAL_USERS}`
  try {
    const response = await privateApiCall(requestUrl, "GET");
    if (response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: "OK",
      });
    }
    return response.data;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: `${ERR_ALERT_TITLE}`,
      text: `${ERR_GETTING}`,
      confirmButtonText: "OK",
    });
  }
};

export const savePortalUser = async (url,body) => {

  try {
    const response = await privateApiCall(url, 'POST', body);
    return response.data;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: `${ERR_ALERT_TITLE}`,
      text: `${ERR_POSTING}`,
      confirmButtonText: "OK",
    });
  }
};

export const updatePortalUserStatus = (id, status) => {
  
  const requestUrl = `${UPDATE_PORTAL_USER_STATUS}?id=${id}&status=${status}`
  return privateApiCall(requestUrl,'post')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
        Swal.fire({
            icon: 'error',
            title: `${ERR_ALERT_TITLE}`,
            text: `${ERR_GETTING}`,
            confirmButtonText: 'OK'  
          })
    });
};

export const searchPortalUsers = (searchQuery)=>{
  const queryParams = new URLSearchParams({ query: searchQuery }).toString();
  const requestUrl = `/app/searchPortalUsers?${queryParams}&`
  return privateApiCall(requestUrl,'GET')
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      Swal.fire({
        icon: 'error',
        title: `${ERR_ALERT_TITLE}`,
        text: `${ERR_GETTING}`,
        confirmButtonText: 'OK'  
      });
    });
}