import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import { FiTrash2, FiEdit2 } from "react-icons/fi";
import {
  getUserType,
  addName,
  editName,
  deleteUserType,
  searchUserType,
} from "../../actions/adminAction";
import Cookies from "universal-cookie";
import { Tooltip } from "react-tooltip";
import { DELETE, EDIT } from "../../constants/url";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  ALERT_BUTTON_OK,
  CONFIRM_TITLE,
  DELETE_YES_BUTTON,
  ERR,
  ERR_ALERT_TITLE,
  SUCC_ADD_USER_TYPE,
  SUCC_DEL_USER_TYPE,
  SUCC_UPDATE_USER_TYPE,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const UserType = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false)
    

  };
  const [values, setValues] = useState({
    name: "",
    error: "",
    header: "Add Type",
    success: false,
    types: [],
    message: "",
    type_id: "",
    type: "add",
    removed: false,
  });
  const { name, type, types, type_id } = values;
  const token = cookies.get("agritech_token");

  useEffect(() => {
    loadUserType();
  }, []);

  const loadUserType = () => {
    getUserType(token).then((data) => {
      if (data.error) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: data.error,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        setValues({
          ...values,
          types: data.userType,
          name: "",
          type: "add",
          type_id: "",
          header: `${STRING_CONSTANTS.ADD_TYPE}`,
        });
      }
    });
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value;
    searchUserType(searchQuery).then((data) => {
      if (!data) {
        return;
      }
      if (data.error) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: data.error,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        setValues({
          ...values,
          types: data.userType || [],
          _id: "",
          type: "add",
          header: `${STRING_CONSTANTS.ADD_TYPE}`,
          name: "",
        });
      }
    });
  };

  const handleEdit = (row) => {
    setValues({
      ...values,
      error: "",
      name: row.name,
      type: "edit",
      type_id: row._id,
    });
    setShow1(true);
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${STRING_CONSTANTS.QUEST} ${row.name}?`,
      icon: "warning",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      cancelButtonColor: " #3085d6",
      confirmButtonText: `${DELETE_YES_BUTTON}`,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserType(row._id).then((data) => {
          if (data.error) {
            setValues({ ...values, error: data.error, loading: false });
            Swal.fire({
              title: "Error",
              text: `${STRING_CONSTANTS.FAIL} ${data.error}`,
              icon: "error",
            });
          } else {
            loadUserType();
            Swal.fire({
              title: "Deleted!",
              text: `${row.name} ${STRING_CONSTANTS.DELETE_USER}`,
              icon: "success",
            });
          }
        });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, error: "", success: false });

    if (type === "add") {
      addName(name).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error });
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`, 
            text: `${STRING_CONSTANTS.FAIL_ADD} ${data.error}`, 
            icon: "error"
          });
        } else {
          setShow(false);
          loadUserType();
          Swal.fire({
            title: "Success!",
            text: `${name} ${STRING_CONSTANTS.ADD_SUCC}`,
            icon: "success"
          });
        }
      });
    } else if (type === "edit") {
      editName(name, type_id).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error });
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`, 
            text: `Failed to update user type. Reason: ${data.error}`, 
            icon: "error"
          });
        } else {
          setShow1(false);
          loadUserType();
          Swal.fire({
            title: "Success!",
            text: `User type "${name}" has been updated successfully.`,
            icon: "success"
          });
        }
      });
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="settings" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.CUSTOMER_TYPE}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">
                    {STRING_CONSTANTS.CUSTOMER_TYPE}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                    {permissions.some(
                    (item) =>
                      item.keyword === "settingsCustomerType" && item.isCreate === true
                  ) && (
                    <>
                  <button
                        className="btn btn-icon waves-effect waves-light btn-primary"
                        onClick={handleShow}
                      >
                        <i className="fa fa-plus"></i>{" "}
                        {STRING_CONSTANTS.ADD_CUSTOMER_TYPE}
                      </button>
                    </>
                  )}
                        
                      
                      <div
                        style={{
                          width: "200px",
                          position: "relative",
                          marginLeft: "auto",
                        }}
                      >
                        {permissions.some(
                          (item) =>
                            item.keyword === "settingsCustomerType" &&
                            item.isSearch === true
                        ) && (
                          <>
                            <input
                              type="text"
                              placeholder="Search..."
                              className="form-control"
                              style={{
                                width: "100%",
                                paddingLeft: "30px",
                                boxSizing: "border-box",
                              }}
                              onChange={(e) => {
                                handleSearch(e);
                                if (e.target.value.trim() === "") {
                                  e.target.nextElementSibling.style.display =
                                    "inline-block";
                                } else {
                                  e.target.nextElementSibling.style.display =
                                    "none";
                                }
                              }}
                            />
                            <i
                              className="fas fa-search"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "#aaa",
                              }}
                            ></i>
                          </>
                        )}
                      </div>
                    </div>
                    <Modal
                      open={show}
                      onClose={handleClose}
                      aria-labelledby="add-customer-type-modal"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          padding: 4,
                          width: "60%",
                        }}
                      >
                        <h3 id="add-customer-type-modal">
                          {" "}
                          {STRING_CONSTANTS.ADD_CUSTOMER_TYPE}
                        </h3>
                        <form onSubmit={handleSubmit}>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "20px" }}
                          >
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                id="name"
                                label="Customer Type"
                                value={name}
                                onChange={handleChange("name")}
                                required
                              />
                            </Grid>
                          </Grid>
                          <div style={{ marginTop: 20, textAlign: "right" }}>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={handleClose}
                              style={{ marginRight: 10 }}
                            >
                              {STRING_CONSTANTS.CLOSE}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              style={{ backgroundColor: "#769A0F" }}
                            >
                              {STRING_CONSTANTS.SUBMIT}
                            </Button>
                          </div>
                        </form>
                      </Box>
                    </Modal>
                    <Modal
                      open={show1}
                      onClose={handleClose1}
                      aria-labelledby="edit-customer-type-modal"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          padding: 4,
                          width: "60%",
                        }}
                      >
                        <h3 id="edit-customer-type-modal">
                          {STRING_CONSTANTS.EDIT_CUSTOMER_TYPE}
                        </h3>
                        <form onSubmit={handleSubmit}>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "20px" }}
                          >
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                id="name"
                                label="Customer Type"
                                value={name}
                                onChange={handleChange("name")}
                                required
                              />
                            </Grid>
                          </Grid>
                          <div style={{ marginTop: 20, textAlign: "right" }}>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={handleClose1}
                              className="mr-3"
                            >
                              {STRING_CONSTANTS.CLOSE}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              style={{ backgroundColor: "#769A0F" }}
                            >
                              {STRING_CONSTANTS.UPDATE}
                            </Button>
                          </div>
                        </form>
                      </Box>
                    </Modal>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ paddingLeft: "100px" }}>
                              S.No
                            </TableCell>
                            <TableCell>{STRING_CONSTANTS.NAME}</TableCell>
                            <TableCell>{STRING_CONSTANTS.ACTIONS}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {types.map((type, index) => (
                            <TableRow key={type._id}>
                              <TableCell style={{ paddingLeft: "100px" }}>
                                {index + 1}
                              </TableCell>
                              <TableCell>{type.name}</TableCell>
                              <TableCell>
                                <span>
                                {permissions.some(
                    (item) =>
                      item.keyword === "settingsCustomerType" && item.isEdit === true
                  ) && (
                    <>
            
            <FiEdit2
                                    size="18px"
                                    color="#0978F2"
                                    data-tooltip-id="1"
                                    data-tooltip-content={`${EDIT}`}
                                    onClick={() => handleEdit(type)}
                                  />
                                  <Tooltip id="1" />
                    </>
                  )}
                                {permissions.some(
                    (item) =>
                      item.keyword === "settingsCustomerType" && item.isDelete === true
                  ) && (
                    <>
                
                <FiTrash2
                                    size="18px"
                                    color="red"
                                    data-tooltip-id="2"
                                    data-tooltip-content={`${DELETE}`}
                                    onClick={() => handleDelete(type)} // Correctly passing the row object here
                                    style={{ marginLeft: "10px" }}
                                  />
                                  <Tooltip id="2" />
       
                    </>
                  )}
                             
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserType;
