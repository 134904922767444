import { useState, useEffect, useRef } from "react";
import Topbar from "./topBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Sidebar from "./sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FiEdit2, FiEye, FiTruck } from "react-icons/fi";
import { FaGavel, FaShoppingCart } from "react-icons/fa";
import RedeemIcon from "@mui/icons-material/Redeem";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete as GoogleAuto,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { FaImage, FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tooltip } from "react-tooltip";
import { toast, Bounce } from "react-toastify";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Tab,
  Box,
  Paper,
  Button,
  InputLabel,
  Autocomplete,
  Grid,
  FormControl,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Modal,
  Typography,
  Table,
  TableBody,
  TablePagination,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  KEYWORDS,
  ADD_EMPLOYEE,
  DIS_BUY,
  IN_BUY,
  DIS_SELL,
  IN_SELL,
  DEACT,
  ACTIVE,
  EDIT,
  VIEW_DET,
  VIEW_IMG,
  VIEW_BIDS,
  VIEW_OFFERS,
} from "../../constants/url";

import { privateApiCall } from "../../api/privateApi";
import {
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  CONFIRM_PASSWORD_ERR,
  DISABLE,
  ENABLE,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  NO_OFFER,
  SUCC_ALERT_TITLE,
} from "../../constants/alertMessage";
import ImageModal from "../../images/imageModal";
import { getStatusString, PRODUCT_STATUS } from "../../constants/enumConstants";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { formatDate } from "../../utils/dateUtils";
import { GOOGLE_MAP_API_KEY, RAZORPAY_API_KEY } from "../../constants/apiKey";
import BuyviewModal from "./modals/buyviewModal";
import SellViewModal from "./modals/sellviewModal";
import PaymentDetails from "./modals/paymentDetails";
import ChatBoxModal from "./modals/chatboxModal";

const cookies = new Cookies();
const Products = () => {

  
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const inputRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["places", "marker"],
  });

  const [locations, setLocations] = useState({ lat: null, lng: null });
  const [pincodeError, setPincodeError] = useState("");
  const [pincodeBError, setPincodeBError] = useState("");
  const [showKeywordAlert, setShowKeywordAlert] = useState("");
  const handleOnPlacesChanged = (formType) => {
    // Log to confirm function call
    const places = inputRef.current.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];
      const { geometry, formatted_address } = place;

      if (geometry && geometry.location) {
        const lat = geometry.location.lat();
        const lng = geometry.location.lng();
        setLocations({ lat, lng });
        let tempPincode = "";
        let tempCity = "";
        let tempState = "";
        if (place.address_components) {
          place.address_components.forEach((component) => {
            const types = component.types;
            if (types.includes("postal_code")) {
              tempPincode = component.long_name; // Extract pincode
            }
            if (types.includes("locality")) {
              tempCity = component.long_name; // Extract city
            }
            if (types.includes("administrative_area_level_1")) {
              tempState = component.long_name; // Extract state
            }
          });
        }
        if (formType === "buy") {
          setFormData((prevData) => ({
            ...prevData,
            location: formatted_address,
            pincode: tempPincode || prevData.pincode,
            city: tempCity,
            state: tempState,
          }));
        } else if (formType === "sell") {
          setValues((prevData) => ({
            ...prevData,
            location: formatted_address,
            pincode: tempPincode || prevData.pincode,
            city: tempCity,
            state: tempState,
          }));
        }
      }
    }
  };
  const [openChat, setOpenChat] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatId, setChatId] = useState(null);
  // Function to open the chat modal
  const handleOpenChat = async (offer) => {
    try {
      const payload = {
        senderId: offer.org_id,
        receiverId: offer.user_id,
        productId: offer.product_id,
      };
      const requestUrl = `${ADD_EMPLOYEE}/createChat`;
      const response = await privateApiCall(requestUrl, "POST", payload);
      if (response && response.data && response.data.chat) {
        setChatId(response.data.chat._id);
        setChatHistory(response.data.messages || []);
        setSelectedOffer(offer);
        setOpenChat(true);
      }
    } catch (error) {
      console.error("Error opening chat:", error);
    }
  };
  const handleCloseChat = () => {
    setOpenChat(false);
    setSelectedOffer(null);
  };
 

  const [showAlert, setShowAlert] = useState(false);
  const [imageSrcc, setImageSrcc] = useState("");
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const handleClose1 = () => setOpen1(false);
  const handleClose3 = () => setOpen3(false);
  const handleClose5 = () => setOpen5(false);

  const [details, setDetails] = useState({});
  const handleClose = () => setOpen(false);
  const [orgName, setOrgName] = useState("");
  const [buyPId, setBuyPId] = useState(null);
  const [orgMobile, setOrgMobile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [org_id, setOrgid] = useState(cookies.get("org_id"));
  const [newKeyword, setNewKeyword] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [show, setShow] = useState(false);
  const [shipProd, setShipProd] = useState("");
  const handleShow = () => setShow(true);
  const [sellShow, setSellshow] = useState(false);
  const [sellPId, setSellPId] = useState(null);
  const [sellProducts, setSellProducts] = useState([]);
  const [sellView, setSellView] = useState(false);
  const handleSellViewClose = () => setSellView(false);
  const [sellDetails, setSellDetails] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [activeLanguages, setActiveLanguages] = useState([]);
  // adding new keywords for the modal
  const [keyword, setKeyword] = useState("");
  const [value, setValue] = useState("1");
  const [errors, setErrors] = useState({ location: "" });
  const [bids, setBids] = useState([]);
  const [bidModalInfo, setBidModalInfo] = useState({});
  const [offerModalInfo, setOfferModalInfo] = useState({});
  const [offers, setOffers] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const handleRefresh = async () => {
    const result = await getBids(bidModalInfo._id);
    setBids(result.bids);
  };
  const handleRefresh2 = async () => {
    const result = await getOffers(offerModalInfo._id);
    setOffers(result.offers);
  };
  const [formData, setFormData] = useState({
    title: "",
    img1: "",
    unit: "",
    qty: "",
    pincode: "",
    city: "",
    state: "",
    startDate: dayjs().format("MM/DD/YYYY"),
    endDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
    location: "",
    description: "",
    org_id: cookies.get("org_id"),
  });

  const [shipInfo, setShipInfo] = useState({
    shippingImage: "",
    shippingComment: "",
  });
  const [values, setValues] = useState({
    data: [],
    success: false,
    removed: false,
    pincode: "",
    city: "",
    state: "",
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    keywords: "",
    title: "",
    description: "",
    qty: "",
    price: "",
    unit: "Kg",
    location: "",
    latitude: "",
    longitude: "",
    expdate: dayjs().add(1, "day").format("MM/DD/YYYY"),
    availableDate: dayjs().format("MM/DD/YYYY"),
    auction_status: "0",
    ecommerce_status: "0",
    organic_status: "0",
    safe_status: "0",
    transpot_status: "0",
    chargesAfter: "",
    freeDelivery: "",
    startDate: "",
    endDate: "",
    org_id: cookies.get("org_id"),
  });
  const [open2, setOpen2] = useState(false);
  const handleOpenClose = () => setOpen2(false);
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [shippingImg, setShippingImg] = useState("");

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterSell, setFilterSell] = useState([]);

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleSellshow = () => {
    setSellshow(true);
    setValues({
      ...values,
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      pincode: "",
      city: "",
      state: "",
      keywords: "",
      title: "",
      description: "",
      qty: "",
      price: "",
      unit: "",
      location: "",
      latitude: "",
      longitude: "",
      expdate: dayjs().add(1, "day").format("MM/DD/YYYY"),
      availableDate: dayjs().format("MM/DD/YYYY"),
      auction_status: "0",
      ecommerce_status: "0",
      organic_status: "0",
      safe_status: "0",
      transpot_status: "0",
      chargesAfter: "",
      freeDelivery: "",
    });
    setSellPId(null);
    setSelectedKeyword([]);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setKeyword("");
    setSelectedLanguage("");
  };
  const handleModalOpen = () => setShowModal(true);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
    fetchAllData();
    fetchAllData();
  };

  const handleStartDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      startDate: date ? dayjs(date).format("MM/DD/YYYY") : "",
    }));
  };
  const handleEndDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      endDate: date ? dayjs(date).format("MM/DD/YYYY") : "",
    }));
  };

  const handleSellStartDateChange = (date) => {
    setValues((prevData) => ({
      ...prevData,
      availableDate: date ? dayjs(date).format("MM/DD/YYYY") : "",
    }));
  };

  const handleSellEndDateChange = (date) => {
    setValues((prevData) => ({
      ...prevData,
      expdate: date
        ? dayjs(date).format("MM/DD/YYYY")
        : dayjs().add(1, "day").format("MM/DD/YYYY"),
    }));
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  const handleShowClose = () => {
    setFormData({
      title: "",
      unit: "",
      img1: "",
      quantity: "",
      startDate: dayjs().format("MM/DD/YYYY"),
      endDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
      location: "",
      description: "",
    });
    setLocations({ lat: null, lng: null });
    setFormData((prevData) => ({
      ...prevData,
      location: "",
    }));
    setBuyPId(null);
    document.getElementById("image").value = "";
    setShow(false);
    setSelectedKeyword([]);
  };
  const handleShowClose1 = () => {
    // setSellPId(null)
    setSellshow(false);
    setLocations({ lat: null, lng: null });
    setValues((prevData) => ({
      ...prevData,
      location: "",
    }));
  };
  const onFileChange1 = (e) => {
    setValues({
      ...values,
      error: false,
      img1: URL.createObjectURL(e.target.files[0]),
    });
    setImage1(e.target.files[0]);
  };
  const onFileChange5 = (e) => {
    setShipInfo({
      ...shipInfo,
      shippingImage: URL.createObjectURL(e.target.files[0]),
    });
    setShippingImg(e.target.files[0]);
  };
  const shippingCommentSubmit = (e) => {
    setShipInfo({
      ...shipInfo,
      shippingComment: e.target.value,
    });
  };
  const onFileChange2 = (e) => {
    setValues({
      ...values,
      error: false,
      img2: URL.createObjectURL(e.target.files[0]),
    });
    setImage2(e.target.files[0]);
  };
  const onFileChange3 = (e) => {
    setValues({
      ...values,
      error: false,
      img3: URL.createObjectURL(e.target.files[0]),
    });
    setImage3(e.target.files[0]);
  };
  const onFileChange4 = (e) => {
    setValues({
      ...values,
      error: false,
      img4: URL.createObjectURL(e.target.files[0]),
    });
    setImage4(e.target.files[0]);
  };

  const handleChange = (nameParams) => (e) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      if (value.length === 6 && /^\d{6}$/.test(value)) {
        setPincodeError("");
      } else {
        console.log("not setted the pincode 6 length")
        setPincodeError(`${STRING_CONSTANTS.PIN_ALERT}`); // Set error if invalid
      }
    }
    if (name === "qty") {
      const numericValue = Number(value);
      if (value === "" || numericValue > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setValues({ ...values, error: false, [nameParams]: e.target.value });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Quantity must be greater than 0", // Set error message for quantity
        }));
      }
      return;
    }
    if (name === "price") {
      const numericValue = Number(value);
      if (value === "" || numericValue > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setValues({ ...values, error: false, [nameParams]: e.target.value });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Price must be greater than 0", // Set error message for quantity
        }));
      }
      return;
    }
    if (name === "location" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Location is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    setValues({ ...values, error: false, [nameParams]: e.target.value });
  };
  const handleCheckBoxChange = (name, value) => (e) => {
    const isChecked = e.target.checked ? value : "0";
    setShowAlert(false);

    switch (name) {
      case "organic_status":
        setValues({
          ...values,
          organic_status: isChecked,
          safe_status: isChecked,
        });
        break;
      case "safe_status":
        setValues({
          ...values,
          organic_status: isChecked ? "0" : "1",
          safe_status: isChecked,
        });

        break;
      case "auction_status":
        setValues({
          ...values,
          auction_status: isChecked,
          ecommerce_status: isChecked ? "0" : values.ecommerce_status,
        });
        break;
      case "ecommerce_status":
        setValues({
          ...values,
          ecommerce_status: isChecked,
          auction_status: isChecked ? "0" : values.auction_status,
        });
        break;
      default:
        setValues({ ...values, [name]: isChecked });
    }
  };

  const fetchAllData = async () => {
    try {
      const requestUrl = "/api/organisation/getMarketplaceApi";
      const response = await privateApiCall(requestUrl, "GET");
       console.log(response,"****")
      if (response.status === 200) {
        const {
          activeLanguages,
          activeKeywords,
          buyProducts,
          sellProducts,
          sellPaymentDetails,
          buyPaymentDetails,
        } = response.data.data;
        const combinedPaymentDetails = [
          ...sellPaymentDetails.map((item) => ({ ...item, type: "sell" })),
          ...buyPaymentDetails.map((item) => ({ ...item, type: "buy" })),
        ];

        // Update state with the retrieved data
        setNewKeyword([...activeKeywords, { keywords: "Other" }]);
        setActiveLanguages(activeLanguages);
        setProducts(buyProducts);
        setFilteredProducts(buyProducts);
        setSellProducts(sellProducts);
        setFilterSell(sellProducts);
        setPaymentDetails(combinedPaymentDetails);
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          text: response.data.msg,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: "error",
        text: error.message || `${CONFIRM_PASSWORD_ERR}`,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  const handleModalSubmit = async () => {
    try {
      const type = "org";
      const requestUrl = `${KEYWORDS}/addKeyword?type=${type}`;
      const status = type === "org" ? "inactive" : "active";
      const response = await privateApiCall(requestUrl, "POST", {
        keyword: keyword,
        languageCode: selectedLanguage,
        status: status,
      });

      if (response.status === 200) {
        setNewKeyword((prevKeywords) => [
          ...prevKeywords,
          { keywords: keyword, language: selectedLanguage },
        ]);
        setShowModal(false);
        setKeyword("");
        setSelectedLanguage("");
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          text: response.data.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          text: error.response?.data?.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    }
  };
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "pincode") {
      if (value.length === 6 && /^\d{6}$/.test(value)) {
        setPincodeBError("");
      } else {
        setPincodeBError(`${STRING_CONSTANTS.PIN_ALERT}`); // Set error if invalid
      }
    }
    if (name === "quantity") {
      const numericValue = Number(value);

      if (value === "" || numericValue > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setFormData({ ...formData, [name]: value });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Quantity must be greater than 0", // Set error message for quantity
        }));
      }

      return;
    }

    // Validate empty location field
    if (name === "location" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Location is required", // Set error message for location
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear error message for location
      }));
    }

    // Update form data state
    setFormData({ ...formData, [name]: value });
  };
  const [imageURL, setImageURL] = useState("");
  const handleImageChange = (file) => {
    setFormData({
      ...formData,
      img1: file,
    });
    setImageURL(URL.createObjectURL(file));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
     let isValid = true;
    if (
      !formData.pincode ||
      formData.pincode.length !== 6 ||
      !/^\d{6}$/.test(formData.pincode)
    ) {
      setPincodeBError(`${STRING_CONSTANTS.PIN_ALERT}`);
        isValid = false;
      
    }

  if (Number(formData.quantity) <= 0) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      quantity: "Quantity must be greater than 0",
    }));
    isValid = false;
  }
  if (formData.location.trim() === "") {
    setErrors((prevErrors) => ({
      ...prevErrors,
      location: "Location is required",
    }));
    isValid = false;
  }
    if (!isValid) return;
    setPincodeBError("");
     setErrors({});
    try {
      const {
        img1,
        title,
        unit,
        quantity,
        startDate,
        endDate,
        description,
        location,
        pincode,
        city,
        state,
      } = formData;
      console.log(formData,"+++++")
      const { lat: latitude, lng: longitude } = locations;
      const formDataWithCoordinates = new FormData();
      formDataWithCoordinates.append("image1", img1);
      formDataWithCoordinates.append("title", title);
      formDataWithCoordinates.append("unit", unit);
      formDataWithCoordinates.append("qty", quantity);
      formDataWithCoordinates.append("startDate", startDate);
      formDataWithCoordinates.append("endDate", endDate);
      formDataWithCoordinates.append("location", location);
      formDataWithCoordinates.append("description", description);
      formDataWithCoordinates.append("latitude", latitude);
      formDataWithCoordinates.append("longitude", longitude);
      formDataWithCoordinates.append("org_id", org_id);
      formDataWithCoordinates.append("pincode", pincode);
      formDataWithCoordinates.append("city", city);
      formDataWithCoordinates.append("state", state);
      const requestUrl = `${ADD_EMPLOYEE}/addBuyproduct`;
      const response = await privateApiCall(
        requestUrl,
        "POST",
        formDataWithCoordinates
      );

      if (response.status === 200) {
        setShow(false);
        Swal.fire({
          icon: "success",
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.msg,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        }).then(() => {
          setFormData({
            title: "",
            unit: "",
            img1: "",
            pincode: "",
            city: "",
            state: "",
            quantity: "",
            startDate: dayjs().format("MM/DD/YYYY"),
            endDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
            location: "",
            description: "",
          });
          fetchAllData();
        });
      } else {
        setShow(false);
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: response.data.message,
        }).then(() => {
          fetchAllData();
        });
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const handleShipSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("image", shippingImg);
      formData.append("shipment_comment", shipInfo.shippingComment);
      formData.append("product_id", shipProd._id);

      const requestUrl = `${ADD_EMPLOYEE}/updateOrgShipment`;
      let response = await privateApiCall(requestUrl, "POST", formData);
      setShipProd("");
      if (response.status === 200) {
        setOpen4(false);
        return Swal.fire({
          icon: "success",
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.msg,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        }).then(() => {
          setFormData({
            title: "",
            unit: "",
            img1: "",
            quantity: "",
            startDate: dayjs().format("MM/DD/YYYY"),
            endDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
            location: "",
            description: "",
          });
          fetchAllData();
        });
      } else {
        setOpen4(false);
        return Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: response.data.message,
        })
          .then(() => {
            fetchAllData();
          })
          .catch(() => {
            fetchAllData();
          });
      }
    } catch (error) {
      // Handle errors
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_POSTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      const {
        img1,
        title,
        unit,
        quantity,
        startDate,
        endDate,
        description,
        location,
        pincode,
        
        city,
        state,
      } = formData;
      const { lat: latitude, lng: longitude } = locations;
      console.log(formData,"formdata of the buy edit ....",locations)
      const formDataWithCoordinates = new FormData();
      formDataWithCoordinates.append("image1", formData.img1);
      formDataWithCoordinates.append("title", formData.title);
      formDataWithCoordinates.append("unit", formData.unit);
      formDataWithCoordinates.append("qty", formData.quantity);
      formDataWithCoordinates.append("startDate", formData.startDate);
      formDataWithCoordinates.append("endDate", formData.endDate);
      formDataWithCoordinates.append("location", formData.location);
      formDataWithCoordinates.append("description", formData.description);
      formDataWithCoordinates.append("latitude", latitude);
      formDataWithCoordinates.append("longitude", longitude);
      formDataWithCoordinates.append("org_id", org_id);
      formDataWithCoordinates.append("pincode", pincode);
      formDataWithCoordinates.append("city", city);
      formDataWithCoordinates.append("state", state)
      const requestUrl = `${ADD_EMPLOYEE}/editBuyproduct/${buyPId}`;
      let response = await privateApiCall(
        requestUrl,
        "PUT",
        formDataWithCoordinates
      );
      if (response.status === 200) {
        setShow(false);
        return Swal.fire({
          icon: "success",
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.msg,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        }).then(() => {
          setFormData({
            title: "",
            unit: "",
            img1: "",
            quantity: "",
            startDate: "",
            endDate: "",
            location: "",
            description: "",
          });
          fetchAllData();
        });
      } else {
        setShow(false);
        return Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: response.data.message,
        }).then(() => {
          fetchAllData();
        });
      }
    } catch (error) {
      // Handle errors
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  const handleSellSubmit = async (e) => {
    e.preventDefault();
    if (values.auction_status !== "1" && values.ecommerce_status !== "1") {
      setShowAlert(true);
      return;
    }
    if (selectedKeyword.length === 0) {
      // setShowKeywordAlert(true)
      setShowKeywordAlert(`${STRING_CONSTANTS.KEYWORD_ALERT}`);
      return;
    }
    if (
      !values.pincode ||
      values.pincode.length !== 6 ||
      !/^\d{6}$/.test(values.pincode)
    ) {
      setPincodeError(`${STRING_CONSTANTS.PIN_ALERT}`);
      return;
    }
    setPincodeError("");
    setShowKeywordAlert("");
    try {
      const { lat: latitude, lng: longitude } = locations;
      const formData = new FormData();
      formData.append("image1", image1);
      formData.append("image2", image2);
      formData.append("image3", image3);
      formData.append("image4", image4);
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append(
        "selectedKeyword",
        selectedKeyword.map((keyword) => keyword.keywords)
      );
      formData.append("qty", values.qty);
      formData.append("price", values.price);
      formData.append("location", values.location);
      formData.append("unit", values.unit);
      formData.append("org_id", values.org_id);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("expDate", values.expdate);
      formData.append("availableDate", values.availableDate);
      formData.append("auction_status", values.auction_status);
      formData.append("ecommerce_status", values.ecommerce_status);
      formData.append("organic_status", values.organic_status);
      formData.append("safe_status", values.safe_status);
      formData.append("transpot_status", values.transpot_status);
      formData.append("chargesAfter", values.chargesAfter);
      formData.append("freeDelivery", values.freeDelivery);
      formData.append("pincode", values.pincode);
      formData.append("city", values.city);
      formData.append("state", values.state);
      const requestUrl = `${ADD_EMPLOYEE}/addSellproduct`;
      const response = await privateApiCall(requestUrl, "POST", formData);
      if (!response.data.status) {
        setSellshow(false);
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      } else {
        setSellshow(false);
        Swal.fire({
          title: response.data.msg,
          text: "",
          icon: "success",
          confirmButtonText: `${ALERT_BUTTON_OK}`,
          showCancelButton: false,
        });
        fetchAllData();
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const handleEditSellSubmit = async (e) => {
    e.preventDefault();
    if (values.auction_status !== "1" && values.ecommerce_status !== "1") {
      setShowAlert(true);
      return;
    }
    if (selectedKeyword.length === 0) {
      // setShowKeywordAlert(true)
      setShowKeywordAlert(`${STRING_CONSTANTS.KEYWORD_ALERT}`);
      return;
    }
     
    setShowKeywordAlert("");
    try {
      const { lat: latitude, lng: longitude } = locations;
      const formData = new FormData();
      formData.append("image1", image1);
      formData.append("image2", image2);
      formData.append("image3", image3);
      formData.append("image4", image4);
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append(
        "selectedKeyword",
        selectedKeyword.map((keyword) => keyword.keywords)
      );
      formData.append("qty", values.qty);
      formData.append("price", values.price);
      formData.append("location", values.location);
      formData.append("unit", values.unit);
      formData.append("org_id", values.org_id);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("expDate", values.expdate);
      formData.append("availableDate", values.availableDate);
      formData.append("auction_status", values.auction_status);
      formData.append("ecommerce_status", values.ecommerce_status);
      formData.append("organic_status", values.organic_status);
      formData.append("safe_status", values.safe_status);
      formData.append("transpot_status", values.transpot_status);
      formData.append("chargesAfter", values.chargesAfter);
      formData.append("freeDelivery", values.freeDelivery);
      formData.append("pincode",values.pincode)
      formData.append("city",values.city)
      formData.append("state",values.state)
      
      const requestUrl = `${ADD_EMPLOYEE}/editSellproduct/${sellPId}`;
      const response = await privateApiCall(requestUrl, "PUT", formData);

      if (!response.data.status) {
        setSellshow(false);
        toast
          .error(response.data.message, {
            position: "top-right",
            transition: Bounce,
          })
          .then((result) => {
            if (result.isConfirmed) {
              fetchAllData();
            }
          });
      } else {
        setSellshow(false);
        toast.info(response.data.msg, {
          position: "top-right",
          transition: Bounce,
        });
        fetchAllData();
      }
    } catch (error) {}
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shippedData, setShippedData] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [orId, setOrId] = useState(null);
  const Actions = ({ row }) => {
    
    const handleImageClick = () => {
      if (row.image1) {
        setImageSrcc(row.image1);
        setOpen(true);
      }
    };
    const handleEditShow = (e) => {
      console.log(row,"edit products")
      setBuyPId(row._id);
      setShow(true);
      setFormData({
        ...formData,
        title: row.title,
        quantity: row.qty,
        description: row.description,
        startDate: row.startDate,
        endDate: row.endDate,
        location: row.location,
        city:row.city,
        state:row.state,
        pincode:row.pincode,
        unit: row.unit,
        img1: row.image1,
      });
      setLocations({
      lat:row.latitude,
      lng:row.longitude
      })
      setImageURL(row.image1);
    };
    const handleView = (e) => {
      setDetails(row);
      setOpen1(true);
    };

    const disableBuy = async () => {
      Swal.fire({
        title: `${DEACT} ${row.title} ?`,
        icon: "question",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then(async (result) => {
        if (result) {
          try {
            const requestUrl = `${DIS_BUY}/${row._id}`;
            const response = await privateApiCall(requestUrl, "POST", null);
            toast.info(response.data.message, {
              position: "top-right",
              transition: Bounce,
            });
            fetchAllData();
          } catch (error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        }
      });
    };
    const EnableBuy = () => {
      Swal.fire({
        title: `${ACTIVE} ${row.title} ?`,
        icon: "question",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const requestUrl = `${IN_BUY}/${row._id}`;
            const response = await privateApiCall(requestUrl, "POST", null);
            toast.info(response.data.message, {
              position: "top-right",
              transition: Bounce,
            });
            fetchAllData();
          } catch (error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        }
      });
    };

    const handleOffers = async (e) => {
      const result = await getOffers(row._id);

      setOfferModalInfo(row);
      setOffers(result.offers);
      setOpen5(true);
    };
    const handleCart = async (e) => {
      try {
        const result = await getOffers(row._id);
        const winner = result.offers.find((offer) => offer.accept_status === 1);
        if (!winner) {
          Swal.fire({
            icon: `${ALERT_ICON_ERR}`,
            title: `${NO_OFFER}`,
            text: "Please try again later or check other offers.",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
          return;
        }
        const payload = {
          offer_id: winner.offer_id,
          seller_name: winner.seller_name,
          seller_mobile: winner.seller_mobile,
          offer_price: winner.offer_price,
          prodId: row._id,
        };

        const orderPayload = {
          products: [
            {
              productId: payload.prodId,
              seller_mobile_no: winner.seller_mobile,
              type: "buy",
            },
          ],
        };

        const createOrderResponse = await privateApiCall(
          "/api/organisation/createOrder",
          "POST",
          { products: orderPayload.products }
        );

        const orderId = createOrderResponse.data.orderProducts._id;
        console.log(orderId, "orderss id");
        setOrId(orderId);

        if (createOrderResponse.data.status) {
          const amountInPaise = winner.offer_price * 100;

          var options = {
            key: RAZORPAY_API_KEY,
            amount: amountInPaise,
            currency: "INR",
            name: winner.seller_name,
            description: row.title,

            handler: async function (response) {
              try {
                const updateOrderResponse = await privateApiCall(
                  "/api/organisation/submitOrder",
                  "POST",
                  {
                    orderId: orderId,
                    paymentId: response.razorpay_payment_id,
                  }
                );

                if (updateOrderResponse.data.status) {
                  const postPaymentResponse = await privateApiCall(
                    "/api/organisation/postPayment",
                    "POST",
                    {
                      payment_id: response.razorpay_payment_id,

                      amount: winner.offer_price,
                      payment_status: "success",
                      purpose: "Product Purchase",
                      product_id: payload.prodId,
                    }
                  );
                } else {
                  alert(
                    "Payment successful but failed to update order. Please contact support."
                  );
                }
              } catch (updateError) {
                console.error(updateError);
                alert("Error updating order. Please contact support.");
              }
            },

            theme: {
              color: "#3399cc",
            },
          };

          var pay = new window.Razorpay(options);
          pay.open();
        } else {
          alert("Failed to place order. Please try again.");
        }
      } catch (error) {
        console.error("Error in handleCart process", error);
        alert("An error occurred. Please try again.");
      }
    };
    const getShippedData = async () => {
      try {
        const requestUrl = `/api/organisation/getShippedData?prodId=${row._id}`;
        const response = await privateApiCall(requestUrl, "GET");
        console.log(response, "get shipped data response");
        setModalIsOpen(true);
        const orders = response.data.orders;
        console.log(response.data.orders, "++++");
        if (
          orders &&
          orders[0] &&
          orders[0].products &&
          orders[0].products[0] &&
          orders[0].products[0].details
        ) {
          setShippedData(orders[0].products[0].details);
        } else {
          alert("No shipment details available for the selected product.");
        }
      } catch (error) {
        alert("An error occurred while fetching the shipment data.");
      }
    };

    return (
      <span>
        {row.status === 0 &&
          (permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isEdit
            )) && (
            <>
              <i
                style={{ padding: "2px" }}
                data-tooltip-id="1"
                data-tooltip-content={`${EDIT}`}
                onClick={handleEditShow}
              >
                <FiEdit2 size="18px" color="#00C5B2" />
              </i>
            </>
          )}

        {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgProducts" && item.isViewOffers
          )) && (
          <>
            <i
              style={{ padding: "2px" }}
              data-tooltip-id="offer1"
              data-tooltip-content={`${VIEW_OFFERS}`}
            >
              {" "}
              <RedeemIcon
                style={{
                  fontSize: "20px",
                  color: row.status === 0 ? "grey" : "inherit",
                }}
                onClick={row.status === 0 ? undefined : handleOffers}
              />
            </i>
          </>
        )}

        {row.status == 4 &&
          (permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isPayNow
            )) && (
            <>
              <i
                style={{ padding: "2px" }}
                data-tooltip-id="12"
                data-tooltip-content="payNow"
              >
                <FaShoppingCart
                  size="18px"
                  onClick={handleCart}
                  color="#00C5B2"
                />
              </i>
            </>
          )}

        <Tooltip id="12" style={{ fontStyle: "normal" }} />
        <Tooltip id="offer1" style={{ fontStyle: "normal" }} />

        {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgProducts" && item.isDetailedInfo
          )) && (
          <>
            <i
              data-tooltip-id="2"
              style={{ padding: "2px" }}
              data-tooltip-content={`${VIEW_DET}`}
            >
              {" "}
              <FiEye onClick={handleView} color="#9368f3" size="20" />
            </i>
          </>
        )}

        {row.status == 11 &&
          (permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isReceive
            )) && (
            <>
              <i
                data-tooltip-id="r1"
                style={{ padding: "2px" }}
                data-tooltip-content="Receive"
              >
                {" "}
                <CreditScoreIcon
                  onClick={getShippedData}
                  style={{ fontSize: "20px", color: "#9365f5" }}
                />
              </i>
            </>
          )}
        <Tooltip id="r1" style={{ fontStyle: "normal" }} />

        <Tooltip id="2" style={{ fontStyle: "normal" }} />

        <Tooltip id="3" style={{ fontStyle: "normal" }} />
        {row.status < PRODUCT_STATUS.PAYMENT_PENDING &&
          (row.delete_status
            ? (permissions === "All" ||
                permissions.some(
                  (item) => item.keyword === "orgProducts" && item.isUnblock
                )) && (
                <>
                  <i
                    style={{ padding: "2px" }}
                    className="ml-2"
                    onClick={EnableBuy}
                    data-tooltip-id="tooltip1"
                    data-tooltip-content={`${ACTIVE}`}
                  >
                    <FaRegThumbsUp size="18px" color="green" />
                  </i>
                </>
              )
            : (permissions === "All" ||
                permissions.some(
                  (item) => item.keyword === "orgProducts" && item.isBlock
                )) && (
                <>
                  <i
                    style={{ padding: "2px" }}
                    className="ml-2"
                    onClick={disableBuy}
                    data-tooltip-id="tooltip2"
                    data-tooltip-content={`${DEACT}`}
                  >
                    <FaRegThumbsDown size="18px" color="red" />
                  </i>
                </>
              ))}
        <Tooltip id="tooltip2" style={{ fontStyle: "normal" }} />
        <Tooltip id="tooltip1" style={{ fontStyle: "normal" }} />
        {row.image1 &&
          (permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isShowImage
            )) && (
            <>
              <i
                style={{ padding: "2px" }}
                className="ml-1"
                data-tooltip-id="3"
                data-tooltip-content={`${VIEW_IMG}`}
              >
                {" "}
                <FaImage onClick={handleImageClick} color="green" size="17" />
              </i>
            </>
          )}
      </span>
    );
  };
  const handleMarkAsReceived = async (id) => {
    try {
      const receivedResponse = await privateApiCall(
        "/api/organisation/receivedOrder",
        "POST",
        {
          product_id: id,
        }
      );

      if (receivedResponse.data.status === true) {
        setFeedbackOpen(true);
      } else {
        alert("Failed to mark order as received.");
      }
    } catch (error) {
      console.error("Error marking order as received:", error);
      alert("An error occurred while marking the order as received.");
    }
  };
  const handleCloseOrder = async (id) => {
    try {
      const closeResponse = await privateApiCall(
        "/api/organisation/closeOrder",
        "POST",
        {
          product_id: id,
          feedback: feedback,
        }
      );

      if (closeResponse.data.status === true) {
        Swal.fire({
          title: `${STRING_CONSTANTS.CLOSE_ORDER}`,
          icon: "success",
          allowOutsideClick: false,
        });
        setModalIsOpen(false);
      } else {
        alert("Failed to close order.");
      }
    } catch (error) {
      console.error("Error closing order:", error);
      alert("An error occurred while closing the order.");
    }
  };

  const handleSearchBuy = async (e) => {
    try {
      const value = e.target.value;
      if (value === "") {
        setFilteredProducts(products);
        return;
      }
      const requestUrl = `/api/organisation/searchBuy/${value}`;
      const response = await privateApiCall(requestUrl, "GET");

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setFilteredProducts(response.data.data);
      } else {
        setFilteredProducts([]);
      }
    } catch (error) {
      setFilteredProducts([]);
    }
  };
  const isExpired = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    return end < today; // Check if endDate is less than today
  };

  const handleAwards = async (buyer_name, auction_id, win_mobile, prodId) => {
    setOpen3(false);
    Swal.fire({
      title: `${STRING_CONSTANTS.AWARD} ${buyer_name} ?`,
      icon: "question",
      allowOutsideClick: false,
      confirmButtonText: `${ALERT_BUTTON_YES}`,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const requestUrl = `/api/organisation/awardBid`;
          const postData = {
            auction_id,
            win_mobile,
            prodId,
          };
          let response = await privateApiCall(requestUrl, "POST", postData);
          toast.info(response.data.msg, {
            position: "top-right",
            transition: Bounce,
          });
          fetchAllData();
        } catch (error) {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      }
    });
  };
  const getBids = async (value) => {
    try {
      const requestUrl = `/api/organisation/getBids/${value}`;
      let bids = await privateApiCall(requestUrl, "GET");

      return bids.data;
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const Action = ({ row }) => {
    const handleSellImageClick = () => {
      setOpen2(true);
      setImage1(row.image1);
      setImage2(row.image2);
      setImage3(row.image3);
      setImage4(row.image4);
    };

    const handleEditSellShow = (e) => {
      setSellPId(row._id);
      setSellshow(true);
      setValues({
        ...values,
        title: row.title,
        description: row.description,
        price: row.price,
        img1: row.image1,
        img2: row.image2,
        img3: row.image3,
        img4: row.image4,
        unit: row.unit,
        location: row.location,
        expDate: row.expDate,
        availableDate: row.availableDate,
        keywords: row.keywords,
        qty: row.qty,
        city: row.city,
        state: row.state,
        pincode: row.pincode,
        auction_status: row.auction_status,
        ecommerce_status: row.ecommerce_status,
        organic_status: row.organic_status,
        safe_status: row.safe_status,
        transpot_status: row.transpot_status,
        chargesAfter: row.chargesAfter,
        freeDelivery: row.freeDelivery,
      });
      setLocations({
        lat:row.latitude,
        lng:row.longitude
        })
      setSelectedKeyword(
        row.keywords.map((keyword) => ({ keywords: keyword }))
      );
    };
    const handleSellView = (e) => {
      setSellDetails(row);
      setSellView(true);
    };
    const handleShippingView = () => {
      setOpen4(true);
      setShipProd(row);
    };

    const disableSell = async () => {
      Swal.fire({
        title: `${DISABLE} ${row.title} ?`,
        icon: "question",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const requestUrl = `${DIS_SELL}/${row._id}`;
            const response = await privateApiCall(requestUrl, "POST", null);
            toast.info(response.data.message, {
              position: "top-right",
              transition: Bounce,
            });
            fetchAllData();
          } catch (error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: `${ERR_POSTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        }
      });
    };
    const EnableSell = () => {
      Swal.fire({
        title: `${ENABLE} ${row.title} ?`,
        icon: "question",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const requestUrl = `${IN_SELL}/${row._id}`;
            const response = await privateApiCall(requestUrl, "POST", null);
            toast.info(response.data.message, {
              position: "top-right",
              transition: Bounce,
            });
            fetchAllData();
          } catch (error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        }
      });
    };
    const handleBids = async (e) => {
      const result = await getBids(row._id);

      setBidModalInfo(row);
      setBids(result.bids);
      setOpen3(true);
    };
    return (
      <span>
        {row.status === 0 &&
          (permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isEdit
            )) && (
            <>
              <i
                style={{ padding: "2px" }}
                data-tooltip-id="1"
                data-tooltip-content={`${EDIT}`}
                onClick={handleEditSellShow}
              >
                <FiEdit2 size="18px" color="#00C5B2" />
              </i>
            </>
          )}

        <Tooltip id="1" style={{ fontStyle: "normal" }} />

        {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgProducts" && item.isDetailedInfo
          )) && (
          <>
            <i
              data-tooltip-id="2"
              className="ml-1"
              style={{ padding: "2px" }}
              data-tooltip-content={`${VIEW_DET}`}
            >
              {" "}
              <FiEye onClick={handleSellView} color="#9368f3" size="20" />
            </i>
          </>
        )}

        {row.status === 9 &&
          (permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isShip
            )) && (
            <>
              <i
                data-tooltip-id="3"
                className="ml-1"
                style={{ padding: "2px" }}
                data-tooltip-content="Ship Order"
              >
                <FiTruck
                  onClick={handleShippingView}
                  color="#9368f3"
                  size="20"
                />
              </i>
            </>
          )}
        {(permissions === "All" ||
          permissions.some(
            (item) => item.keyword === "orgProducts" && item.isViewBids
          )) && (
          <>
            <i
              style={{ padding: "2px" }}
              data-tooltip-id="2"
              className="ml-1"
              data-tooltip-content={`${VIEW_BIDS}`}
            >
              {row.status === 0 ? (
                <FaGavel color="grey" size="20" />
              ) : (
                row.auction_status === "1" && (
                  <FaGavel onClick={handleBids} color="#9368f3" size="20" />
                )
              )}
            </i>
          </>
        )}

        <Tooltip id="2" style={{ fontStyle: "normal" }} />

        <Tooltip id="3" style={{ fontStyle: "normal" }} />
        {row.status < PRODUCT_STATUS.PAYMENT_PENDING &&
          (row.delete_status
            ? (permissions === "All" ||
                permissions.some(
                  (item) => item.keyword === "orgProducts" && item.isUnblock
                )) && (
                <>
                  <i
                    style={{ padding: "2px" }}
                    className="ml-1"
                    onClick={EnableSell}
                    data-tooltip-id="tooltip1"
                    data-tooltip-content={`${ACTIVE}`}
                  >
                    <FaRegThumbsUp size="18px" color="green" />
                  </i>
                </>
              )
            : (permissions === "All" ||
                permissions.some(
                  (item) => item.keyword === "orgProducts" && item.isBlock
                )) && (
                <>
                  <i
                    style={{ padding: "2px" }}
                    className="ml-1"
                    onClick={disableSell}
                    data-tooltip-id="tooltip2"
                    data-tooltip-content={`${DEACT}`}
                  >
                    <FaRegThumbsDown size="18px" color="red" />
                  </i>
                </>
              ))}

        <Tooltip id="tooltip2" style={{ fontStyle: "normal" }} />
        <Tooltip id="tooltip1" style={{ fontStyle: "normal" }} />
        {row.image1 &&
          (permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProducts" && item.isShowImage
            )) && (
            <>
              <i
                style={{ padding: "2px" }}
                className="ml-1"
                data-tooltip-id="3"
                data-tooltip-content={`${VIEW_IMG}`}
              >
                {" "}
                <FaImage
                  onClick={handleSellImageClick}
                  color="green"
                  size="17"
                />
              </i>
            </>
          )}
      </span>
    );
  };
  const handleAcceptOffer = async (
    seller_name,
    offer_id,
    seller_mobile,
    prodId
  ) => {
    setOpen5(false);

    Swal.fire({
      title: `Accept ${seller_name}'s offer?`,
      icon: "question",
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const requestUrl = `/api/organisation/acceptOffer/${offer_id}`;
          let response = await privateApiCall(requestUrl, "POST");
          console.log(response, "accepted api response");

          if (response.status) {
            fetchAllData();
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Offer accepted successfully!",
              allowOutsideClick: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          } else {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: response.message || "Failed to accept the offer.",
              allowOutsideClick: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "An error occurred while accepting the offer.",
            allowOutsideClick: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
          console.error("Error accepting offer:", error);
        }
      }
    });
  };
  const getOffers = async (value) => {
    try {
      const requestUrl = `/api/organisation/getOffers/${value}`;
      const offers = await privateApiCall(requestUrl, "GET");

      return offers.data;
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  const imageSources = [image1, image2, image3, image4];
  const handleSearchSell = async (e) => {
    try {
      const value = e.target.value;

      if (value === "") {
        setFilterSell(sellProducts);
        return;
      }

      const requestUrl = `${ADD_EMPLOYEE}/searchSell/${value}`;
      const response = await privateApiCall(requestUrl, "GET");

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setFilterSell(response.data.data);
      } else {
        setFilterSell([]);
      }
    } catch (error) {
      setFilterSell([]);
    }
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.PRODUCTS}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.PRODUCTS}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-12">
                      <div className="p-20">
                        {/* -------------------------------------------------------------------- */}
                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={handleChanges}
                              aria-label="lab API tabs example"
                            >
                              <Tab label="Buy Products" value="1" />
                              <Tab label="Sell Products" value="2" />
                              <Tab label="Payment" value="3" />
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {(permissions === "All" ||
                                permissions.some(
                                  (item) =>
                                    item.keyword === "orgProducts" &&
                                    item.isCreate
                                )) && (
                                <>
                                  <button
                                    className="btn btn-icon waves-effect waves-light btn-info"
                                    style={{ marginRight: "10px" }}
                                    onClick={handleShow}
                                  >
                                    <i className="fa fa-plus"></i>{" "}
                                    {STRING_CONSTANTS.ADD_BUY_PROD}
                                  </button>
                                </>
                              )}

                              {(permissions === "All" ||
                                permissions.some(
                                  (item) =>
                                    item.keyword === "orgProducts" &&
                                    item.isSearch
                                )) && (
                                <>
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "200px",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      placeholder={
                                        STRING_CONSTANTS.SEARCH_PLACEHOLDER
                                      }
                                      className="form-control"
                                      style={{
                                        width: "100%",
                                        paddingLeft: "35px",
                                        boxSizing: "border-box",
                                      }}
                                      onChange={handleSearchBuy}
                                    />
                                    <i
                                      className="fas fa-search"
                                      style={{
                                        position: "absolute",
                                        left: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        color: "#aaa",
                                      }}
                                    ></i>
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              <style>
                                {`
          .pac-container {
            z-index: 2000 !important;
          }
        `}
                              </style>
                              <Modal
                                open={show}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginLeft: "100px",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "900px",
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "8px",
                                    boxShadow: 24,
                                    outline: "none",
                                    maxHeight: "80vh",
                                    overflowY: "auto",
                                  }}
                                >
                                  <h3>
                                    {buyPId
                                      ? `${STRING_CONSTANTS.EDIT_BUY_PROD}`
                                      : `${STRING_CONSTANTS.ADD_BUY_PROD}`}
                                  </h3>
                                  <form
                                    onSubmit={
                                      buyPId ? handleEditSubmit : handleSubmit
                                    }
                                  >
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6} md={4}>
                                        {formData.img1 !== "" ? (
                                          <img
                                            src={imageURL}
                                            alt=""
                                            height="150px"
                                            width="100%"
                                          />
                                        ) : (
                                          <img
                                            src="/assets/icons/try.jpg"
                                            alt=""
                                            height="150px"
                                            width="100%"
                                          />
                                        )}
                                        <div className="mt-3">
                                          <input
                                            type="file"
                                            id="image"
                                            name="image1"
                                            className="mt-3 mb-3"
                                            onChange={(e) =>
                                              handleImageChange(
                                                e.target.files[0]
                                              )
                                            }
                                            accept="image/png, image/jpeg"
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          label="Title"
                                          name="title"
                                          value={formData.title}
                                          onChange={handleInputChange}
                                          placeholder={
                                            STRING_CONSTANTS.TITLE_PLACEHOLDER
                                          }
                                          required
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={4}>
                                        <FormControl
                                          fullWidth
                                          variant="outlined"
                                          required
                                        >
                                          <InputLabel>
                                            {STRING_CONSTANTS.UNIT}
                                          </InputLabel>
                                          <Select
                                            label="Unit"
                                            value={formData.unit}
                                            onChange={handleInputChange}
                                            name="unit"
                                          >
                                            <MenuItem value="">
                                              {STRING_CONSTANTS.SELECT_UNIT}
                                            </MenuItem>
                                            {STRING_CONSTANTS.UNIT_ARRAY.map(
                                              (unit, index) => (
                                                <MenuItem
                                                  key={index}
                                                  value={unit}
                                                >
                                                  {unit}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          type="number"
                                          label="Quantity"
                                          name="quantity"
                                          value={formData.quantity}
                                          placeholder={
                                            STRING_CONSTANTS.QUANTITY_PLACEHOLDER
                                          }
                                          onChange={handleInputChange}
                                          error={!!errors.quantity} // Set error prop if there is an error
                                          helperText={errors.quantity} // Display the error message
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DemoContainer
                                            components={["DatePicker"]}
                                          >
                                            <DatePicker
                                              label="Start Date"
                                              format="DD/MM/YYYY"
                                              defaultValue={dayjs(
                                                formData.startDate
                                              )}
                                              name="startDate"
                                              onChange={handleStartDateChange}
                                              required
                                              minDate={dayjs()}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DemoContainer
                                            components={["DatePicker"]}
                                          >
                                            <DatePicker
                                              label=" End Date"
                                              format="DD/MM/YYYY"
                                              // defaultValue={  dayjs(formData.endDate)}
                                              defaultValue={dayjs(
                                                formData.endDate
                                              )}
                                              name="endDate"
                                              onChange={handleEndDateChange}
                                              required
                                              minDate={dayjs(
                                                formData.startDate
                                              ).add(1, "day")}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        style={{
                                          position: "relative",
                                          zIndex: 1302,
                                          marginTop: "10px",
                                        }}
                                      >
                                        {isLoaded && (
                                          <StandaloneSearchBox
                                            onLoad={(ref) =>
                                              (inputRef.current = ref)
                                            }
                                            onPlacesChanged={() =>
                                              handleOnPlacesChanged("buy")
                                            }
                                          >
                                            <TextField
                                              fullWidth
                                              label="Location"
                                              name="location"
                                              value={formData.location || ""}
                                              onChange={handleInputChange}
                                              required
                                              placeholder="Search for a location"
                                            />
                                          </StandaloneSearchBox>
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          // label="Pincode"
                                          name="pincode"
                                          value={formData.pincode}
                                          onChange={handleInputChange}
                                          placeholder="Enter Pincode"
                                        />
                                        {pincodeBError && (
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {pincodeBError}
                                          </span>
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          // label="City"
                                          name="city"
                                          value={formData.city}
                                          onChange={handleInputChange}
                                          placeholder="Enter City"
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          // label="State"
                                          name="state"
                                          value={formData.state}
                                          onChange={handleInputChange}
                                          placeholder="Enter State"
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          label="Description"
                                          name="description"
                                          value={formData.description}
                                          placeholder={
                                            STRING_CONSTANTS.DESCRIPTION_PLACEHOLDER
                                          }
                                          onChange={handleInputChange}
                                          required
                                        />
                                      </Grid>
                                    </Grid>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={handleShowClose}
                                        sx={{ mt: 2, mr: 1 }}
                                      >
                                        {STRING_CONSTANTS.CLOSE}
                                      </Button>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 2, mr: 1 }}
                                        style={{
                                          backgroundColor: "#0BAAE5",
                                          marginRight: "10px",
                                        }}
                                      >
                                        {buyPId ? "Update" : "Submit"}
                                      </Button>
                                    </div>
                                  </form>
                                </Box>
                              </Modal>
                            </div>

                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ minWidth: 100 }}>
                                      {STRING_CONSTANTS.SN_NO}
                                    </TableCell>
                                    <TableCell style={{ minWidth: 150 }}>
                                      {STRING_CONSTANTS.TITLE}
                                    </TableCell>
                                    <TableCell width="180px" align="left">
                                      {STRING_CONSTANTS.CUSTOMER}
                                    </TableCell>
                                    <TableCell style={{ minWidth: 150 }}>
                                      {STRING_CONSTANTS.STATUS}
                                    </TableCell>

                                    <TableCell style={{ minWidth: 150 }}>
                                      {STRING_CONSTANTS.ACTIONS}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {filteredProducts.length > 0 ? (
                                    filteredProducts.map((product, index) => (
                                      <TableRow key={product._id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{product.title}</TableCell>
                                        <TableCell>
                                          {product.customer}
                                        </TableCell>
                                        <TableCell>
                                          {isExpired(product.endDate)
                                            ? `${STRING_CONSTANTS.EXPIRED}`
                                            : getStatusString(product.status)}
                                        </TableCell>
                                        <TableCell>
                                          <Actions row={product} />
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={5} align="center">
                                        {STRING_CONSTANTS.NO_SEARCH_DATA}
                                      </TableCell>
                                    </TableRow>
                                  )}

                                  {/* Buy View Details Modal */}
                                  <BuyviewModal
                                    open={open1}
                                    handleClose={handleClose1}
                                    details={details}
                                    STRING_CONSTANTS={STRING_CONSTANTS}
                                  />

                                  {/* offer detaails */}
                                  <Modal
                                    open={open5}
                                    aria-labelledby="modal-title"
                                    aria-describedby="modal-description"
                                  >
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "55%",
                                        left: "55%",
                                        transform: "translate(-50%, -50%)",
                                        width: "70vw",
                                        maxHeight: "80vh",
                                        bgcolor: "background.paper",
                                        boxShadow: 24,
                                        p: 4,
                                        overflowY: "auto",
                                      }}
                                    >
                                      <TableContainer component={Paper}>
                                        <Grid
                                          item
                                          xs={12}
                                          className="modal-header"
                                        >
                                          <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h2"
                                          >
                                            {offerModalInfo.title}{" "}
                                            {STRING_CONSTANTS.OFFER_LIST}
                                          </Typography>

                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <Button onClick={handleRefresh2}>
                                              <RefreshIcon />
                                            </Button>
                                            <Button
                                              onClick={handleClose5}
                                              color="inherit"
                                            >
                                              <span
                                                style={{ fontSize: "20px" }}
                                                aria-hidden="true"
                                              >
                                                &#128473;
                                              </span>
                                            </Button>
                                          </div>
                                        </Grid>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="left">
                                                {STRING_CONSTANTS.SN_NO}
                                              </TableCell>
                                              <TableCell align="left">
                                                {STRING_CONSTANTS.SELLER}
                                              </TableCell>
                                              <TableCell align="left">
                                                {STRING_CONSTANTS.SELLER_MOBILE}
                                              </TableCell>

                                              <TableCell align="left">
                                                {STRING_CONSTANTS.OFFERPRICE}
                                              </TableCell>
                                              <TableCell align="left">
                                                {STRING_CONSTANTS.ACTIONS}
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {offers && offers.length > 0 ? (
                                              offers.map((offer, index) => (
                                                <TableRow
                                                  style={{
                                                    backgroundColor:
                                                      offer.accept_status === 1
                                                        ? "lime"
                                                        : "inherit",
                                                  }}
                                                  key={index}
                                                >
                                                  <TableCell align="left">
                                                    {index + 1}
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {offer.seller_name}
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {offer.seller_mobile}
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {offer.offer_price}
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {offer.accept_status ===
                                                    3 ? (
                                                      <span>
                                                        {
                                                          STRING_CONSTANTS.WITHDRAWN
                                                        }
                                                      </span>
                                                    ) : offerModalInfo.status >
                                                      8 ? (
                                                      <span>
                                                        {
                                                          STRING_CONSTANTS.OFFER_CLOSED
                                                        }
                                                      </span>
                                                    ) : (
                                                      <>
                                                        <Button
                                                          onClick={() =>
                                                            handleAcceptOffer(
                                                              offer.seller_name,
                                                              offer._id,
                                                              offer.seller_mobile,
                                                              offerModalInfo._id
                                                            )
                                                          }
                                                          variant="outlined"
                                                          color={
                                                            offer.accept_status ===
                                                            1
                                                              ? "secondary"
                                                              : "primary"
                                                          }
                                                          disabled={
                                                            offer.accept_status ===
                                                              1 ||
                                                            offer.accept_status ===
                                                              3 ||
                                                            (offer.accept_status ===
                                                              0 &&
                                                              offer.isRejected)
                                                          }
                                                        >
                                                          {offer.accept_status ===
                                                            1 &&
                                                          !offer.isRejected &&
                                                          !offer.isDeleted
                                                            ? "Accepted"
                                                            : offer.accept_status ===
                                                                0 &&
                                                              offer.isRejected &&
                                                              !offer.isDeleted
                                                            ? "Lost"
                                                            : "Accept"}
                                                        </Button>
                                                        {/* <Button
                                                          variant="contained"
                                                          sx={{ marginLeft: 2 }}
                                                          color="success"
                                                          onClick={() =>
                                                            handleOpenChat(
                                                              offer
                                                            )
                                                          }
                                                        >
                                                          Chat
                                                        </Button> */}
                                                      </>
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              ))
                                            ) : (
                                              <TableRow>
                                                <TableCell
                                                  colSpan={5}
                                                  align="center"
                                                >
                                                  No offers available
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </TableBody>
                                          {openChat &&
                                            chatId &&
                                            selectedOffer && (
                                              <ChatBoxModal
                                                open={openChat}
                                                handleClose={handleCloseChat}
                                                chatId={chatId}
                                                offer={selectedOffer}
                                                chatHistory={chatHistory}
                                              />
                                            )}
                                        </Table>
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          className="modal-footer"
                                        >
                                          <Grid item>
                                            <Grid container alignItems="center">
                                              <div
                                                style={{
                                                  width: "15px",
                                                  height: "15px",
                                                  backgroundColor: "lime",

                                                  marginRight: "5px",
                                                }}
                                              ></div>
                                              <Typography variant="body2">
                                                {
                                                  STRING_CONSTANTS.WINNING_OFFER_INDICATOR
                                                }
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Button
                                            onClick={handleClose5}
                                            variant="outlined"
                                            color="error"
                                          >
                                            {STRING_CONSTANTS.CLOSE}
                                          </Button>
                                        </Grid>
                                      </TableContainer>
                                    </Box>
                                  </Modal>
                                  {/*get shipped detials */}
                                  <Modal
                                    open={modalIsOpen}
                                    onClose={() => setModalIsOpen(false)}
                                    aria-labelledby="shipping-modal-title"
                                    aria-describedby="shipping-modal-description"
                                  >
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: "80vw",
                                        maxHeight: "80vh",
                                        bgcolor: "background.paper",
                                        boxShadow: 24,
                                        p: 4,
                                        overflowY: "auto",
                                      }}
                                    >
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        className="modal-content"
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className="modal-header"
                                        >
                                          <Typography
                                            id="shipping-modal-title"
                                            variant="h6"
                                            component="h2"
                                          >
                                            {STRING_CONSTANTS.SHIP_DETAILS}
                                          </Typography>
                                          <Button
                                            onClick={() =>
                                              setModalIsOpen(false)
                                            }
                                            color="inherit"
                                          >
                                            <span
                                              style={{ fontSize: "20px" }}
                                              aria-hidden="true"
                                            >
                                              &#128473;
                                            </span>
                                          </Button>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          className="modal-body"
                                        >
                                          <div className="col-md-3 mb-3">
                                            <div
                                              className="text-dark text-left font-size-16 mb-2"
                                              style={{
                                                letterSpacing: "0.04rem",
                                              }}
                                            >
                                              Image
                                            </div>
                                            {shippedData?.shipment_image ? (
                                              <div>
                                                <img
                                                  src={
                                                    shippedData.shipment_image
                                                  }
                                                  alt="Shipment"
                                                  height="150px"
                                                  width="100%"
                                                />
                                              </div>
                                            ) : (
                                              <div>
                                                {STRING_CONSTANTS.NO_IMAGE}
                                              </div>
                                            )}
                                          </div>
                                          <TextField
                                            label="Shipping Comment"
                                            fullWidth
                                            value={
                                              shippedData?.shipment_comment ||
                                              ""
                                            }
                                            margin="normal"
                                            multiline
                                            rows={4}
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          className="modal-footer"
                                        >
                                          {!feedbackOpen ? (
                                            <Button
                                              onClick={() =>
                                                handleMarkAsReceived(
                                                  shippedData?._id
                                                )
                                              }
                                              variant="outlined"
                                              color="info"
                                              sx={{ mr: 2 }}
                                            >
                                              {STRING_CONSTANTS.MARK_RECIEVE}
                                            </Button>
                                          ) : (
                                            <Grid
                                              container
                                              className="modal-footer"
                                            >
                                              <TextField
                                                label="Feedback"
                                                fullWidth
                                                value={feedback}
                                                onChange={(e) =>
                                                  setFeedback(e.target.value)
                                                }
                                                margin="normal"
                                                multiline
                                                rows={4}
                                              />
                                              <Button
                                                onClick={() =>
                                                  handleCloseOrder(
                                                    shippedData?._id
                                                  )
                                                }
                                                variant="contained"
                                                color="primary"
                                                sx={{ mr: 2 }}
                                              >
                                                {STRING_CONSTANTS.SUB_FEED}
                                              </Button>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Modal>

                                  <ImageModal
                                    handleClose={handleClose}
                                    open={open}
                                    imageSrc={[imageSrcc]}
                                  ></ImageModal>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </TabPanel>

                          <TabPanel value="2">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {(permissions === "All" ||
                                permissions.some(
                                  (item) =>
                                    item.keyword === "orgProducts" &&
                                    item.isCreate
                                )) && (
                                <>
                                  <button
                                    className="btn btn-icon waves-effect waves-light btn-info"
                                    style={{ marginRight: "10px" }}
                                    onClick={handleSellshow}
                                  >
                                    <i className="fa fa-plus"></i>{" "}
                                    {STRING_CONSTANTS.ADD_SELL_PROD}
                                  </button>
                                </>
                              )}

                              {(permissions === "All" ||
                                permissions.some(
                                  (item) =>
                                    item.keyword === "orgProducts" &&
                                    item.isSearch
                                )) && (
                                <>
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "200px",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      placeholder={
                                        STRING_CONSTANTS.SEARCH_PLACEHOLDER
                                      }
                                      className="form-control"
                                      style={{
                                        width: "100%",
                                        paddingLeft: "35px",
                                        boxSizing: "border-box",
                                      }}
                                      onChange={handleSearchSell}
                                    />
                                    <i
                                      className="fas fa-search"
                                      style={{
                                        position: "absolute",
                                        left: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        color: "#aaa",
                                      }}
                                    ></i>
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              <style>
                                {`
                                  .pac-container {
                                    z-index: 2000 !important;
                                  }
                                `}
                              </style>
                              <Modal
                                open={sellShow}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginLeft: "100px",
                                }}
                              >
                                <Box
                                  sx={{
                                    position: "absolute",
                                    width: "90vw",
                                    maxHeight: "80vh",

                                    backgroundColor: "white",
                                    padding: "30px",
                                    borderRadius: "8px",

                                    boxShadow: 24,
                                    outline: "none",
                                    overflowY: "auto",
                                  }}
                                >
                                  <h3 style={{ marginBottom: 40 }}>
                                    {sellPId
                                      ? `${STRING_CONSTANTS.EDIT_SELL_PROD}`
                                      : `${STRING_CONSTANTS.ADD_SELL_PROD}`}
                                  </h3>
                                  <form
                                    onSubmit={
                                      sellPId
                                        ? handleEditSellSubmit
                                        : handleSellSubmit
                                    }
                                  >
                                    <Grid container spacing={3}>
                                      <div className="col-md-3 mb-3">
                                        <div
                                          className="text-dark text-left font-size-16 mb-2"
                                          style={{ letterSpacing: "0.04rem" }}
                                        >
                                          {STRING_CONSTANTS.IMAGE_1}
                                        </div>

                                        {values.img1 !== "" ? (
                                          <div>
                                            <img
                                              src={values.img1}
                                              alt=""
                                              height="150px"
                                              width="100%"
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            src="/assets/icons/try.jpg"
                                            alt=""
                                            height="150px"
                                            width="100%"
                                            className=""
                                          />
                                        )}

                                        <div className="mt-3">
                                          <input
                                            type="file"
                                            id="image"
                                            onChange={onFileChange1}
                                            accept="image/png,  image/jpeg"
                                            className="mt-3 margin-top-10"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3 mb-3">
                                        <div
                                          className="text-dark text-left font-size-16 mb-2"
                                          style={{ letterSpacing: "0.04rem" }}
                                        >
                                          {STRING_CONSTANTS.IMAGE_2}
                                        </div>

                                        {values.img2 !== "" ? (
                                          <div>
                                            <img
                                              src={values.img2}
                                              alt=""
                                              height="150px"
                                              width="100%"
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            src="/assets/icons/try.jpg"
                                            alt=""
                                            height="150px"
                                            width="100%"
                                            className=""
                                          />
                                        )}

                                        <div className="mt-3">
                                          <input
                                            type="file"
                                            id="image"
                                            className="mt-3 margin-top-10"
                                            onChange={onFileChange2}
                                            accept="image/png,  image/jpeg"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3 mb-3">
                                        <div
                                          className="text-dark text-left font-size-16 mb-2"
                                          style={{ letterSpacing: "0.04rem" }}
                                        >
                                          {STRING_CONSTANTS.IMAGE_3}
                                        </div>

                                        {values.img3 !== "" ? (
                                          <div>
                                            <img
                                              src={values.img3}
                                              alt=""
                                              height="150px"
                                              width="100%"
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            src="/assets/icons/try.jpg"
                                            alt=""
                                            height="150px"
                                            width="100%"
                                            className=""
                                          />
                                        )}

                                        <div className="mt-3">
                                          <input
                                            type="file"
                                            id="image"
                                            className="mt-3 margin-top-10"
                                            onChange={onFileChange3}
                                            accept="image/png,  image/jpeg"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3 mb-3">
                                        <div
                                          className="text-dark text-left font-size-16 mb-2"
                                          style={{ letterSpacing: "0.04rem" }}
                                        >
                                          {STRING_CONSTANTS.IMAGE_4}
                                        </div>

                                        {values.img4 !== "" ? (
                                          <div>
                                            <img
                                              src={values.img4}
                                              alt=""
                                              height="150px"
                                              width="100%"
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            src="/assets/icons/try.jpg"
                                            alt=""
                                            height="150px"
                                            width="100%"
                                            className=""
                                          />
                                        )}

                                        <div className="mt-3">
                                          <input
                                            type="file"
                                            id="image"
                                            className="mt-3 margin-top-10"
                                            onChange={onFileChange4}
                                            accept="image/png,  image/jpeg"
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          label="Title"
                                          name="title"
                                          value={values.title}
                                          onChange={handleChange("title")}
                                          placeholder="Enter Title"
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <Autocomplete
                                          multiple
                                          id="tags-outlined"
                                          options={newKeyword}
                                          value={selectedKeyword}
                                          getOptionLabel={(option) =>
                                            option.keywords
                                          }
                                          defaultValue={[]}
                                          filterSelectedOptions
                                          onChange={(event, value) => {
                                            if (
                                              value.some(
                                                (option) =>
                                                  option.keywords === "Other"
                                              )
                                            ) {
                                              handleModalOpen();
                                            } else {
                                              setSelectedKeyword(value);
                                              setShowKeywordAlert("");
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Keywords"
                                              placeholder="Select Your keyword"
                                            />
                                          )}
                                        />
                                        {showKeywordAlert && (
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {showKeywordAlert}
                                          </span>
                                        )}

                                        <Modal
                                          open={showModal}
                                          onClose={handleModalClose}
                                          aria-labelledby="simple-modal-title"
                                          aria-describedby="simple-modal-description"
                                        >
                                          <div
                                            style={{
                                              padding: "20px",
                                              background: "white",
                                              width: "60%",
                                              margin: "100px auto",
                                              position: "absolute",
                                              top: "40%",
                                              left: "50%",
                                              transform:
                                                "translate(-50%, -50%)",
                                            }}
                                          >
                                            <h2>
                                              {STRING_CONSTANTS.ADD_KEYWORDS}
                                            </h2>
                                            <FormControl
                                              fullWidth
                                              sx={{ mb: 3 }}
                                            >
                                              <TextField
                                                label="Keyword"
                                                type="text"
                                                value={keyword}
                                                onChange={(e) =>
                                                  setKeyword(e.target.value)
                                                }
                                                required
                                                style={{ marginTop: "20px" }}
                                              />
                                            </FormControl>
                                            <FormControl
                                              fullWidth
                                              sx={{ mb: 3 }}
                                              variant="outlined"
                                              required
                                            >
                                              <InputLabel id="language">
                                                {
                                                  STRING_CONSTANTS.SELECT_LANGUAGE
                                                }
                                              </InputLabel>
                                              <Select
                                                label="language"
                                                value={selectedLanguage}
                                                onChange={(e) =>
                                                  setSelectedLanguage(
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              >
                                                <MenuItem value="">
                                                  <em>
                                                    {
                                                      STRING_CONSTANTS.SELECT_LANGUAGE
                                                    }
                                                  </em>
                                                </MenuItem>
                                                {activeLanguages.map((lang) => (
                                                  <MenuItem
                                                    key={lang._id}
                                                    value={lang.language}
                                                  >
                                                    {lang.language}-{lang.code}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                            <Grid
                                              container
                                              justifyContent="flex-end"
                                              sx={{ mt: 3 }}
                                            >
                                              <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={handleModalClose}
                                                sx={{ mt: 2, mr: 1 }}
                                              >
                                                {STRING_CONSTANTS.CLOSE}
                                              </Button>
                                              <Button
                                                type="submit"
                                                variant="contained"
                                                color="success"
                                                sx={{ mt: 2 }}
                                                style={{
                                                  backgroundColor: "#0BAAE5",
                                                }}
                                                onClick={handleModalSubmit}
                                              >
                                                {STRING_CONSTANTS.ADD}
                                              </Button>
                                            </Grid>
                                          </div>
                                        </Modal>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <FormControl
                                          fullWidth
                                          variant="outlined"
                                          required
                                        >
                                          <InputLabel>
                                            {STRING_CONSTANTS.UNIT}
                                          </InputLabel>
                                          <Select
                                            label="Unit"
                                            value={values.unit}
                                            onChange={handleChange("unit")}
                                            name="unit"
                                          >
                                            <MenuItem value="">
                                              {STRING_CONSTANTS.SELECT_UNIT}
                                            </MenuItem>
                                            {STRING_CONSTANTS.UNIT_ARRAY.map(
                                              (unit, index) => (
                                                <MenuItem
                                                  key={index}
                                                  value={unit}
                                                >
                                                  {unit}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          type="number"
                                          label="Quantity"
                                          name="qty"
                                          value={values.qty}
                                          placeholder={
                                            STRING_CONSTANTS.QUANTITY_PLACEHOLDER
                                          }
                                          onChange={handleChange("qty")}
                                          error={!!errors.qty} // Set error prop if there is an error
                                          helperText={errors.qty}
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          type="number"
                                          name="price"
                                          label="Price"
                                          value={values.price}
                                          onChange={handleChange("price")}
                                          error={!!errors.price}  
                                          helperText={errors.price}
                                          required
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={4}>
                                        {isLoaded && (
                                          <StandaloneSearchBox
                                            onLoad={(ref) =>
                                              (inputRef.current = ref)
                                            }
                                            onPlacesChanged={() =>
                                              handleOnPlacesChanged("sell")
                                            }
                                          >
                                            <TextField
                                              fullWidth
                                              label="Location"
                                              name="location"
                                              value={values.location}
                                              onChange={handleChange(
                                                "location"
                                              )}
                                              required
                                              placeholder="Search for a location"
                                            />
                                          </StandaloneSearchBox>
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          label="Pincode"
                                          name="pincode"
                                          value={values.pincode}
                                          onChange={handleChange("pincode")}
                                          placeholder="Enter Pincode"
                                        />
                                        {pincodeError && (
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {pincodeError}
                                          </span>
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          label="City"
                                          name="city"
                                          value={values.city}
                                          onChange={handleChange("city")}
                                          placeholder="Enter City"
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          label="State"
                                          name="state"
                                          value={values.state}
                                          onChange={handleChange("state")}
                                          placeholder="Enter State"
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                          fullWidth
                                          label="Description"
                                          name="description"
                                          value={values.description}
                                          placeholder="Enter Description"
                                          onChange={handleChange("description")}
                                          required
                                        />
                                      </Grid>
                                      <Grid
                                        style={{ marginTop: "-10px" }}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                      >
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DemoContainer
                                            components={["DatePicker"]}
                                          >
                                            <DatePicker
                                              label=" Available Date "
                                              format="DD/MM/YYYY"
                                              defaultValue={dayjs(
                                                values.availableDate
                                              )}
                                              name="availableDate"
                                              onChange={
                                                handleSellStartDateChange
                                              }
                                              required
                                              minDate={dayjs()}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      </Grid>
                                      <Grid
                                        style={{ marginTop: "-10px" }}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                      >
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DemoContainer
                                            components={["DatePicker"]}
                                          >
                                            <DatePicker
                                              label=" Expiry Date"
                                              format="DD/MM/YYYY"
                                              defaultValue={dayjs(
                                                values.expdate
                                              )}
                                              name="expdate"
                                              onChange={handleSellEndDateChange}
                                              required
                                              minDate={dayjs(
                                                values.availableDate
                                              ).add(1, "day")}
                                            />
                                          </DemoContainer>
                                        </LocalizationProvider>
                                      </Grid>
                                      <Grid container spacing={1}>
                                        <Grid item xs={12} md={3}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.auction_status === "1"
                                                }
                                                onChange={handleCheckBoxChange(
                                                  "auction_status",
                                                  "1"
                                                )}
                                                color="primary"
                                                disabled={
                                                  values.ecommerce_status ===
                                                  "1"
                                                }
                                              />
                                            }
                                            label="For Auction"
                                            sx={{ marginLeft: 3 }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.ecommerce_status ===
                                                  "1"
                                                }
                                                onChange={handleCheckBoxChange(
                                                  "ecommerce_status",
                                                  "1"
                                                )}
                                                color="primary"
                                                disabled={
                                                  values.auction_status === "1"
                                                }
                                              />
                                            }
                                            label="For Ecommerce"
                                            sx={{ marginLeft: 3 }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.organic_status === "1"
                                                }
                                                onChange={handleCheckBoxChange(
                                                  "organic_status",
                                                  "1"
                                                )}
                                                color="primary"
                                              />
                                            }
                                            label="Organic"
                                            sx={{ marginLeft: 3 }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.safe_status === "1"
                                                }
                                                onChange={handleCheckBoxChange(
                                                  "safe_status",
                                                  "1"
                                                )}
                                                color="primary"
                                                disabled={
                                                  values.organic_status === "1"
                                                }
                                              />
                                            }
                                            label="Safe To Eat"
                                            sx={{ marginLeft: 3 }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  values.transpot_status === "1"
                                                }
                                                onChange={handleCheckBoxChange(
                                                  "transpot_status",
                                                  "1"
                                                )}
                                                color="primary"
                                              />
                                            }
                                            label="Transport Available"
                                            sx={{ marginLeft: 3 }}
                                          />
                                        </Grid>
                                        {values.transpot_status === "1" && (
                                          <>
                                            <Grid item xs={12} md={4}>
                                              <TextField
                                                fullWidth
                                                label="Free Delivery Upto KM"
                                                value={values.freeDelivery}
                                                placeholder="Enter KM"
                                                onChange={(e) =>
                                                  setValues({
                                                    ...values,
                                                    freeDelivery:
                                                      e.target.value,
                                                  })
                                                }
                                                required
                                              />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                              <TextField
                                                fullWidth
                                                label="Charges after KM"
                                                value={values.chargesAfter}
                                                placeholder="Enter Price"
                                                onChange={(e) =>
                                                  setValues({
                                                    ...values,
                                                    chargesAfter:
                                                      e.target.value,
                                                  })
                                                }
                                                required
                                              />
                                            </Grid>
                                          </>
                                        )}
                                      </Grid>
                                    </Grid>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={handleShowClose1}
                                        sx={{ mt: 2, mr: 1 }}
                                      >
                                        {STRING_CONSTANTS.CLOSE}
                                      </Button>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 2, mr: 1 }}
                                        style={{
                                          backgroundColor: "#0BAAE5",
                                          marginRight: "10px",
                                        }}
                                      >
                                        {sellPId ? "Update" : "Submit"}
                                      </Button>
                                    </div>
                                    <div>
                                      {showAlert && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {STRING_CONSTANTS.ALERT}
                                        </div>
                                      )}
                                      {/* Your Modal Code Here */}
                                    </div>
                                  </form>
                                </Box>
                              </Modal>
                            </div>

                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="80px" align="center">
                                      {STRING_CONSTANTS.SN_NO}
                                    </TableCell>
                                    <TableCell width="180px" align="left">
                                      {STRING_CONSTANTS.TITLE}
                                    </TableCell>
                                    <TableCell width="180px" align="left">
                                      {STRING_CONSTANTS.CUSTOMER}
                                    </TableCell>
                                    <TableCell width="180px" align="left">
                                      {STRING_CONSTANTS.STATUS}
                                    </TableCell>
                                    <TableCell width="80px" align="left">
                                      {STRING_CONSTANTS.ACTIONS}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {filterSell.length > 0 ? (
                                    filterSell.map((product, index) => (
                                      <TableRow key={product._id}>
                                        <TableCell width="80px" align="center">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {product.title}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {product.customer}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {getStatusString(product.status)}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          <Action row={product} />
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={5} align="center">
                                        {STRING_CONSTANTS.NO_SEARCH_DATA}
                                      </TableCell>
                                    </TableRow>
                                  )}

                                  <SellViewModal
                                    open={sellView}
                                    handleClose={handleSellViewClose}
                                    sellDetails={sellDetails}
                                    STRING_CONSTANTS={STRING_CONSTANTS}
                                  />

                                  <Modal
                                    open={open4}
                                    aria-labelledby="shipping-modal-title"
                                    aria-describedby="shipping-modal-description"
                                  >
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: "80vw",
                                        maxHeight: "80vh",
                                        bgcolor: "background.paper",
                                        boxShadow: 24,
                                        p: 4,
                                        overflowY: "auto",
                                      }}
                                    >
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        className="modal-content"
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className="modal-header"
                                        >
                                          <Typography
                                            id="shipping-modal-title"
                                            variant="h6"
                                            component="h2"
                                          >
                                            Shipping Details
                                          </Typography>
                                          <Button
                                            onClick={handleClose4}
                                            color="inherit"
                                          >
                                            <span
                                              style={{ fontSize: "20px" }}
                                              aria-hidden="true"
                                            >
                                              &#128473;
                                            </span>
                                          </Button>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          className="modal-body"
                                        >
                                          <div className="col-md-3 mb-3">
                                            <div
                                              className="text-dark text-left font-size-16 mb-2"
                                              style={{
                                                letterSpacing: "0.04rem",
                                              }}
                                            >
                                              {STRING_CONSTANTS.IMAGES}
                                            </div>

                                            {shipInfo.shippingImage !== "" ? (
                                              <div>
                                                <img
                                                  src={shipInfo.shippingImage}
                                                  alt=""
                                                  height="150px"
                                                  width="100%"
                                                />
                                              </div>
                                            ) : (
                                              <img
                                                src="/assets/icons/try.jpg"
                                                alt=""
                                                height="150px"
                                                width="100%"
                                                className=""
                                              />
                                            )}

                                            <div className="mt-3">
                                              <input
                                                type="file"
                                                id="image"
                                                onChange={onFileChange5}
                                                accept="image/png,  image/jpeg"
                                                className="mt-3 margin-top-10"
                                              />
                                            </div>
                                          </div>
                                          <TextField
                                            label="Shipping Comment"
                                            fullWidth
                                            value={shipInfo.shippingComment}
                                            onChange={shippingCommentSubmit}
                                            margin="normal"
                                            multiline
                                            rows={4}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          className="modal-footer"
                                        >
                                          <Button
                                            onClick={handleClose4}
                                            variant="outlined"
                                            color="error"
                                            sx={{ mr: 2 }}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            onClick={handleShipSubmit}
                                            variant="contained"
                                            color="primary"
                                          >
                                            Save
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Modal>

                                  <Modal
                                    open={open3}
                                    aria-labelledby="modal-title"
                                    aria-describedby="modal-description"
                                  >
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "55%",
                                        left: "55%",
                                        transform: "translate(-50%, -50%)",
                                        width: "70vw",
                                        maxHeight: "80vh",
                                        bgcolor: "background.paper",
                                        boxShadow: 24,
                                        p: 4,
                                        overflowY: "auto",
                                      }}
                                    >
                                      <TableContainer component={Paper}>
                                        <Grid
                                          item
                                          xs={12}
                                          className="modal-header"
                                        >
                                          <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h2"
                                          >
                                            {bidModalInfo.title}{" "}
                                            {
                                              STRING_CONSTANTS.AUCTION_LIST_TITLE
                                            }
                                          </Typography>

                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <Button onClick={handleRefresh}>
                                              <RefreshIcon />
                                            </Button>
                                            <Button
                                              onClick={handleClose3}
                                              color="inherit"
                                            >
                                              <span
                                                style={{ fontSize: "20px" }}
                                                aria-hidden="true"
                                              >
                                                &#128473;
                                              </span>
                                            </Button>
                                          </div>
                                        </Grid>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="left">
                                                {STRING_CONSTANTS.SN_NO}
                                              </TableCell>
                                              <TableCell align="left">
                                                {STRING_CONSTANTS.BIDDER}
                                              </TableCell>
                                              <TableCell align="left">
                                                {STRING_CONSTANTS.BIDDER_MOBILE}
                                              </TableCell>

                                              <TableCell align="left">
                                                {STRING_CONSTANTS.BIDPRICE}
                                              </TableCell>
                                              <TableCell align="left">
                                                {STRING_CONSTANTS.ACTIONS}
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {bids &&
                                              bids.length > 0 &&
                                              bids.map((bid, index) => (
                                                <TableRow
                                                  style={{
                                                    backgroundColor:
                                                      bid.win_status === 1
                                                        ? "lime"
                                                        : "inherit",
                                                  }}
                                                  key={index}
                                                >
                                                  <TableCell align="left">
                                                    {index + 1}
                                                  </TableCell>

                                                  <TableCell align="left">
                                                    {bid.buyer_name}
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {bid.buyer_mobile}
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {bid.bid_price}
                                                  </TableCell>
                                                  <TableCell align="left">
                                                    {bid.isRejected ? (
                                                      <span>
                                                        {
                                                          STRING_CONSTANTS.REJECTED
                                                        }
                                                      </span>
                                                    ) : bidModalInfo.status >
                                                      8 ? (
                                                      <span>
                                                        {
                                                          STRING_CONSTANTS.BID_CLOSED
                                                        }
                                                      </span>
                                                    ) : (
                                                      <Button
                                                        onClick={(e) => {
                                                          handleAwards(
                                                            bid.buyer_name,
                                                            bid._id,
                                                            bid.buyer_mobile,
                                                            bidModalInfo._id
                                                          );
                                                        }}
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={
                                                          !bid.participating ||
                                                          bid.win_mobile
                                                            .length > 0
                                                        }
                                                      >
                                                        {!bid.participating
                                                          ? STRING_CONSTANTS.LOST
                                                          : bid.win_mobile
                                                              .length > 0
                                                          ? STRING_CONSTANTS.AWARDED
                                                          : STRING_CONSTANTS.AWARD_BID}
                                                      </Button>
                                                    )}
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                          </TableBody>
                                        </Table>
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          className="modal-footer"
                                        >
                                          <Grid item>
                                            <Grid container alignItems="center">
                                              <div
                                                style={{
                                                  width: "15px",
                                                  height: "15px",
                                                  backgroundColor: "lime",

                                                  marginRight: "5px",
                                                }}
                                              ></div>
                                              <Typography variant="body2">
                                                {
                                                  STRING_CONSTANTS.WINNING_BIDDER_INDICATOR
                                                }
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Button
                                            onClick={handleClose3}
                                            variant="outlined"
                                            color="error"
                                          >
                                            {STRING_CONSTANTS.CLOSE}
                                          </Button>
                                        </Grid>
                                      </TableContainer>
                                    </Box>
                                  </Modal>

                                  <ImageModal
                                    open={open2}
                                    handleClose={handleOpenClose}
                                    imageSrc={imageSources}
                                  ></ImageModal>
                                </TableBody>
                              </Table>
                              
                            </TableContainer>
                          </TabPanel>
                          <PaymentDetails
                            value="3"
                            paymentDetails={paymentDetails}
                            formatDate={formatDate}
                            STRING_CONSTANTS={STRING_CONSTANTS}
                          />
                        </TabContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
