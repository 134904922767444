import React, { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Dialog,
  TextField,
} from "@mui/material";
import Cookies from "universal-cookie";
import {
  getrejected,
  acceptBProfile,
  replayToBProfile,
  searchRejected,
} from "../../actions/businessProfileAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FaImage } from "react-icons/fa";
import { FiMessageCircle, FiCheckCircle, FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { APPROVE, FEED, VIEW_DET, VIEW_IMG } from "../../constants/url";
import {
  ERR_POSTING,
  ERR_GETTING,
  ERR_ALERT_TITLE,
  ALERT_ICON_ERR,
  ALERT_BUTTON_OK,
  SUCC_ALERT_TITLE,
  SUCC_FEEDBACK,
  ALERT_ICON_SUCC,
  ALERT_BUTTON_YES,
  NO_DATA_FOUND,
  NO_BUS_REJ
} from "../../constants/alertMessage";
import ImageModal from "../../images/imageModal";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const Rejected = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [values, setValues] = useState({
    name: "",
    error: "",
    rejected: "",
    success: false,
    removed: false,
    selectedRows: [],
  });
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [feedId, setFeedId] = useState("");
  const [details, setDetails] = useState({});

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleReplay = (e) => {
    e.preventDefault();

    replayToBProfile(agritech_id, feedId, replay)
      .then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });

          // Show error message
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        } else {
          setOpen2(false);
          setReplay("");
          loadBType();
          // Show success message
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${SUCC_FEEDBACK}`,
            icon: `${ALERT_ICON_SUCC}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((err) => {
        // Show error message for replayToBProfile failure
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };
  const { rejected } = values;
  const agritech_id = cookies.get("agritech_token");
  const [replay, setReplay] = useState("");

  useEffect(() => {
    loadBType();
  }, []);

  const loadBType = () => {
    getrejected(agritech_id)
      .then((data) => {
        if (data.length===0) {
          Swal.fire({
            title: `${NO_DATA_FOUND}`,
            icon: "info",
            text: `${NO_BUS_REJ}`,
            allowOutsideClick: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        } else {
          setValues({ ...values, rejected: data.rejected });
        }
      })
      .catch(() => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const response = await searchRejected(searchValue);

      if (response && response.rejected) {
        setValues((prevState) => ({
          ...prevState,
          rejected: response.rejected,
        }));
      } else {
        setValues((prevState) => ({
          ...prevState,
          rejected: [],
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const handleChange = (name) => (e) => {
    setReplay(e.target.value);
  };

  function edit(cell, row, extraData, index) {
    const handleImageClick = () => {
      const imagesData = [row.image1, row.image2, row.image3, row.image4];

      const filteredImagesData = imagesData.filter(
        (image) => image !== undefined && image !== null && image !== ""
      );

      setImages(filteredImagesData);
      setOpen(true);
    };

    const hasImageData = [row.image1, row.image2, row.image3, row.image4].some(
      (image) => image !== undefined && image !== null && image !== ""
    );
    const handlePOP = (e) => {
      setDetails(row);

      setOpen1(true);
    };

    const handleAcceptClick = (e) => {
      Swal.fire({
        title: `${APPROVE} ${row.company_name}`,
        text: "",
        icon: `${ALERT_ICON_SUCC}`,
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      })
        .then((result) => {
          if (result.isConfirmed) {
            acceptBProfile(agritech_id, row.id).then((data) => {
              if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
              } else {
                var selections = rejected;
                selections.splice(index, 1);

                setValues({ ...values, rejected: selections });
              }
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_POSTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    };
    const handleS = (e) => {
      // Perform the state updates only if confirmed
      setFeedId(row.id);
      setOpen2(true);
    };

    return (
      <span>
        {permissions.some(
          (item) =>
            item.keyword === "businessProfilesRejectedList" &&
            item.isApprove === true
        ) && (
          <>
            <i data-tooltip-id="1" data-tooltip-content={`${APPROVE}`}>
              <FiCheckCircle
                onClick={handleAcceptClick}
                title="approve"
                color="#4fbde9"
                size="20"
              />
            </i>
            <Tooltip id="1" />
          </>
        )}
        {permissions.some(
          (item) =>
            item.keyword === "businessProfilesRejectedList" &&
            item.isFeedback === true
        ) && (
          <>
            {row.replay !== "" ? (
              <i
                className="ml-1"
                data-tooltip-id="2"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle
                  onClick={handleS}
                  title="message"
                  color="#769A0F"
                  size="20"
                />
              </i>
            ) : (
              <i
                className="ml-1"
                data-tooltip-id="2"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle
                  onClick={handleS}
                  title="message"
                  color="#f9bc0b"
                  size="20"
                />
              </i>
            )}
            <Tooltip id="2" />
          </>
        )}

        {permissions.some(
          (item) =>
            item.keyword === "businessProfilesRejectedList" &&
            item.isDetailedInfo === true
        ) && (
          <>
            <i
              className="ml-1"
              data-tooltip-id="3"
              data-tooltip-content={`${VIEW_DET}`}
            >
              {" "}
              <FiEye onClick={handlePOP} color="#9368f3" size="20" />
            </i>
            <Tooltip id="3" />
          </>
        )}

        {permissions.some(
          (item) =>
            item.keyword === "businessProfilesRejectedList" &&
            item.isShowImage === true
        ) && (
          <>
            {hasImageData && (
              <i
                className="ml-1"
                data-tooltip-id="5"
                data-tooltip-content={`${VIEW_IMG}`}
              >
                {" "}
                <FaImage onClick={handleImageClick} color="green" size="17" />
              </i>
            )}
            <Tooltip id="5" />
          </>
        )}
      </span>
    );
  }

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="businessProfiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashBoard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.REJECTED_LIST}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">
                    {STRING_CONSTANTS.BS_REJECTED_LIST}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div
                      style={{
                        marginLeft: "auto",
                        width: "200px",
                        marginBottom: "20px",
                        position: "relative",
                      }}
                    >
                      {permissions.some(
                        (item) =>
                          item.keyword === "businessProfilesRejectedList" &&
                          item.isSearch === true
                      ) && (
                        <>
                          <input
                            type="text"
                            placeholder="Search..."
                            className="form-control"
                            style={{
                              width: "100%",
                              paddingLeft: "30px",
                              boxSizing: "border-box",
                            }}
                            onChange={(e) => {
                              handleSearch(e);
                              if (e.target.value.trim() === "") {
                                e.target.nextElementSibling.style.display =
                                  "inline-block";
                              } else {
                                e.target.nextElementSibling.style.display =
                                  "none";
                              }
                            }}
                          />
                          <i
                            className="fas fa-search"
                            style={{
                              position: "absolute",
                              left: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                              color: "#aaa",
                            }}
                          ></i>
                        </>
                      )}
                    </div>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell align="left">
                              {STRING_CONSTANTS.CUSTOMER_NAME}
                            </TableCell>
                            <TableCell align="left">
                              {STRING_CONSTANTS.CUSTOMER_MOBILE}
                            </TableCell>
                            <TableCell align="left">
                              {STRING_CONSTANTS.COMPANY_NAME}
                            </TableCell>
                            <TableCell align="left">
                              {STRING_CONSTANTS.BUSSINESS_TYPE}
                            </TableCell>

                            <TableCell align="left">
                              {STRING_CONSTANTS.ACTIONS}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(rejected) &&
                            rejected.map((row, index) => (
                              <TableRow key={row._id}>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">
                                  {row.customer_mobile_no}
                                </TableCell>
                                <TableCell align="left">
                                  {row.company_name}
                                </TableCell>
                                <TableCell align="left">{row.btype}</TableCell>

                                <TableCell align="left">
                                  {edit(null, row, null, index)}
                                </TableCell>
                              </TableRow>
                            ))}

                          <ImageModal
                            handleClose={handleClose}
                            open={open}
                            imageSrc={images}
                          ></ImageModal>

                          <Dialog
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="details-modal-title"
                            aria-describedby="details-modal-description"
                            style={{
                              maxHeight: "80vh",
                              overflowY: "auto",
                              marginTop: "100px",
                            }}
                            fullWidth
                            disableEnforceFocus
                            maxWidth="lg"
                          >
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="modal-content"
                            >
                              <Grid item xs={12} className="modal-header">
                                <h5 id="details-modal-title">Details</h5>
                                <Button onClick={handleClose1} color="inherit">
                                  {" "}
                                  <span
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  >
                                    &#128473;
                                  </span>
                                </Button>
                              </Grid>
                              <Grid item xs={12} className="modal-body">
                                <Table className="col-12">
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CUSTOMER_NAME}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.name}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.customer_mobile_no}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.BUSSINESS_TYPE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.btype}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.COMPANY_NAME}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.company_name}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.DESCRIPTION}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.description}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CONTACT_PERSON}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.contact_person}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CONTACT_NUMBER}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.contact_number}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.LOCATION}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.address}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CHARGES}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.charges}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.DELEVERY_AVAILABLE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.deliveryAvailable}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.FREE_DELEVERY}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.freeDelivery}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CHARGES_AFTER}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.chargesAfter}
                                    </TableCell>
                                  </TableRow>
                                </Table>
                              </Grid>
                              <Grid item xs={12} className="modal-footer">
                                <Button
                                  onClick={handleClose1}
                                  variant="outlined"
                                  color="error"
                                >
                                  {STRING_CONSTANTS.CLOSE}
                                </Button>
                              </Grid>
                            </Grid>
                          </Dialog>
                          <Dialog
                            open={open2}
                            onClose={handleClose2}
                            maxWidth="lg"
                            fullWidth
                            centered
                            disableEnforceFocus
                          >
                            <div
                              style={{
                                backgroundColor: "#fff",
                                padding: "20px",
                                // minWidth: '500px',
                                borderRadius: "8px",
                              }}
                            >
                              <h3>{STRING_CONSTANTS.FEEDBACK_LABEL}</h3>
                              <form onSubmit={handleReplay}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <div className="text-left text-dark mb-1">
                                      {STRING_CONSTANTS.MESSAGE}
                                    </div>
                                    <TextField
                                      type="text"
                                      fullWidth
                                      multiline
                                      rows={4}
                                      variant="outlined"
                                      value={replay}
                                      placeholder={STRING_CONSTANTS.MESSAGE}
                                      onChange={handleChange()}
                                      required
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  justifyContent="flex-end"
                                  style={{ marginTop: "20px" }}
                                >
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleClose2}
                                    style={{ marginRight: "8px" }}
                                  >
                                    {STRING_CONSTANTS.CLOSE}
                                  </Button>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#769A0F",
                                      color: "#fff",
                                    }}
                                  >
                                    {STRING_CONSTANTS.SEND}
                                  </Button>
                                </Grid>
                              </form>
                            </div>
                          </Dialog>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rejected;
