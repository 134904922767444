import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import { FiEye } from "react-icons/fi";
import {formatDate} from "../../utils/dateUtils"
import {
  ADD_BUS,
  POST_PDT,
  REQ_PDT,
  ADMIN_ADD_USER_URL,
  LANGUAGE,
  VIEW_DET,
  HANDLE_ORG_REQ
} from "../../constants/url";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import {
  Table,
  TableBody,
  Dialog,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fade,
  Button,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { API as url1 } from "../../config";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FaCity, FaRegBuilding, FaRegCheckSquare, FaRegSquare, FaUniversity } from "react-icons/fa";
import { getBTypes } from "../../actions/businessProfileAction";
import { Tooltip } from "react-tooltip";
import {
  CUSTOMER_PATH,
  ENABLE_OFFER_PATH,
  ADMIN_SELL_PRODUCT_ADD_PATH,
  ADMIN_ADD_BUSINESS_PROFILE_PATH,
  ADMIN_REQUEST_PRODUCT_ADD_PATH,
  BLOCK_USER_PATH,
  UNBLOCK_USER_PATH,
  SEARCH_CUSTOMER,
  KEYWORDS,
} from "../../constants/url";
import {
  ERR_GETTING,
  ERR_POSTING,
  ERROR,
  ERR,
  CONFIRM,
  SUCC_ALERT_TITLE,
  SUCC_ADD_CUS,
  ERR_ALERT_TITLE,
  CONFIRM_BLOCK_CUS,
  CONFIRM_TITLE,
  CONFIRM_UNBLOCK_CUS,
  ALERT_ICON_ERR,
  ALERT_BUTTON_OK,
  ALERT_ICON_SUCC,
  ALERT_ICON_QUESTON,
  ALERT_BUTTON_YES,
  ALERT_ICON_WARNING,
  ALERT_BUTTON_NO,
  CONFIRM_ACCEPT_CUS,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { GOOGLE_MAP_API_KEY } from "../../constants/apiKey";

const cookies = new Cookies();
const Customer = () => {
  const [buyModal, setBuyModal] = useState(false);
  const [sellModal, setSellModal] = useState(false);
  const [details,setDetails]=useState({})
  const [open1,setOpen1]=useState(false)
  const [open2,setOpen2]=useState(false)
  const [orgList,setOrgList]=useState(false)
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const openModal = () => {console.log("truee")
    setIsModalOpen(true)};
 
  const handleModalOpen = () => setShowModal1(true);
  const handleModalClose = () => {
    setShowModal1(false);
    setKeyword("");
    setSelectedLanguage("");
  };
  console.log(isModalOpen,"ismodal closed")
  const [showAlert, setShowAlert] = useState(false);
  const [showKeywordAlert,setShowKeywordAlert]=useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [activeLanguages, setActiveLanguages] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(""); 
  // adding new keywords for the modal
  const [keyword, setKeyword] = useState("");
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [showModal, setShowModal] = useState(false);
  const [customer, setCustomer] = useState({
    namee: "",
    email: "",
    mobile: "",
    error: "",
    header: "Add Customer",
    succes: false,
    crops: [],
    message: "",
    imageName: "",
    type_id: "",
    type: "add",
    image: "",
    remove: false,
  });
  const { namee, mobile, email,error, message } = customer;
  
  const [values, setValues] = useState({
    data: [],
    success: false,
    removed: false,
    img1: "",
    s_mobile: "",
    img2: "",
    img3: "",
    img4: "",
    keywords: "",
    title: "",
    pincode:"",
    city:"",
    state:"",
    description: "",
    qty: "",
    price: "",
    unit: "",
    location: "",
    latitude: "",
    longitude: "",
    expdate: dayjs().add(1, "day").format("MM/DD/YYYY"),
    availableDate: dayjs().format("MM/DD/YYYY"),
    auction_status: "0",
    ecommerce_status: "0",
    organic_status: "0",
    safe_status: "0",
    transpot_status: "0",
    chargesAfter: "",
    freeDelivery: "",
    startDate: "",
    endDate: "",
  });
  
  const [profile, setProfile] = useState({
    bimg1: "",
    bimg2: "",
    bimg3: "",
    bimg4: "",
    selectedBtype: "",
    bType: [],
    cName: "",
    bDescription: "",
    contactPerson: "",
    contactNumber: "",
    address: "",
    serviceCharge: "",
    bLatitude: "",
    bLongitude: "",
    bDeliveryAvailable: "",
    bChargesAfter: "",
    bFreeDelivery: "",
     berror:false
  });
  const {
    serviceCharge,
    bimg1,
    bimg2,
    bimg3,
    bimg4,
    selectedBtype,
    bType,
    cName,
    bDescription,
    contactPerson,
    contactNumber,
    address,
    berror,
    bDeliveryAvailable,
    bChargesAfter,
    bFreeDelivery,
     
  } = profile;
  const handleCloseBusiness = () => {
    setIsModalOpen(false)
    setProfile((prevProfile) => ({
        ...prevProfile,
        serviceCharge:"",
        berror: false,   
    }));
     
};
  const [request, setRequest] = useState({
    rimg1: "",
    rstartDate: dayjs().format("MM/DD/YYYY"),
    rendDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
    rlocation: "",
    rpincode:"",
    rcity:"",
    rstate:"",
    rLatitude: "",
    rLongitude: "",
    runit: "Kg",
    rprice: "",
    rqty: "",
    rtitle: "",
    mobile_no: "",
    rdescription: "",
    qerror:false
  });
  const {
    rimg1,
    rstartDate,
    rendDate,
    rlocation,
    rpincode,
    rcity,
    rstate,
    runit,
    qerror,
    rprice,
    rqty,
    rtitle,
    mobile_no,
    rdescription,
  } = request;
  const handleStartDateChange = (date) => {
    setRequest((prevData) => ({
      ...prevData,
      rstartDate: date ? dayjs(date).format("MM/DD/YYYY") : "",
    }));
  };
  const handleEndDateChange = (date) => {
    setRequest((prevData) => ({
      ...prevData,
      rendDate: date ? dayjs(date).format("MM/DD/YYYY") : "",
    }));
  };
  
  const {
    startDate,
    endDate,
    data,
    img1,
    img2,
    img3,
    img4,
    keywords,
    title,
    description,
    qty,
    price,
    unit,
    location,
    pincode,
    city,
    state,
    expdate,
    availableDate,
    auction_status,
    ecommerce_status,
    organic_status,
    transpot_status,
    safe_status,
    freeDelivery,
    chargesAfter,
    s_mobile,
  } = values;
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [bimage1, setbImage1] = useState("");
  const [bimage2, setbImage2] = useState("");
  const [bimage3, setbImage3] = useState("");
  const [bimage4, setbImage4] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [pincodeBError, setPincodeBError] = useState("");
  const [rimage, setrImage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [errors, setErrors] = useState({ location: "" });
  const handleCustomerChange = (name) => (e) => {
    const value = e.target.value;
    let errorMsg = "";
     
    if (name === 'mobile') {
      
      if (!/^\d*$/.test(value)) {
        errorMsg = `${STRING_CONSTANTS.MOB_VAL}`;
      } else if (value.startsWith('0')) {
        errorMsg = `${STRING_CONSTANTS.MOB_VAL1}`;
      } else if (value.length !== 10) {
        errorMsg = `${STRING_CONSTANTS.MOB_VAL2}`;
      }
    }
    setCustomer({ ...customer,  error: errorMsg,message: errorMsg ? errorMsg : "", [name]: value });
  };
  const inputRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["places", "marker"],
  });
  const [locations, setLocations] = useState({ lat: null, lng: null });

  const handleOrgChange = (e) => {
    console.log(e.target.value);
    console.log(orgList,"orgLIst");
    
    setSelectedOrg(e.target.value);  // Update state with selected organization ID
  };
  const handleOnPlacesChanged = (formType) => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const { geometry, formatted_address } = place;

      if (geometry && geometry.location) {
        const lat = geometry.location.lat();
        const lng = geometry.location.lng();
        setLocations({ lat, lng });
      let tempPincode = "";
      let tempCity = "";
      let tempState = "";
      if (place.address_components) {
        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("postal_code")) {
            tempPincode = component.long_name; // Extract pincode
          }
          if (types.includes("locality")) {
            tempCity = component.long_name; // Extract city
          }
          if (types.includes("administrative_area_level_1")) {
            tempState = component.long_name; // Extract state
          }
        });
      }
        if (formType === "buy") {
          setRequest((prevData) => ({
            ...prevData,
            rlocation: formatted_address,
            rpincode: tempPincode || prevData.rpincode,
            rcity: tempCity,
            rstate:tempState
          }));
        } else if (formType === "sell") {
          setValues((prevData) => ({
            ...prevData,
            location: formatted_address, // Update location with the formatted address
            pincode: tempPincode || prevData.pincode,
            city: tempCity,
            state:tempState
          }));
        }
      }
    }
  };
  const onSubmit = (event) => {
    event.preventDefault();
    const requestUrl = `${ADMIN_ADD_USER_URL}?mobile_no=${mobile}&refCode=&name=${namee}&email=${email}&country_code=`;
    privateApiCall(requestUrl, "GET").then(
      (res) => {
        console.log(res,"customer add success response ")
     
        if (res.data.status) {
          handleCloseModal()
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            text: `${SUCC_ADD_CUS}`,
            icon: `${ALERT_ICON_SUCC}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then(() => {
           
            loadCustomer();
          });
        } else {
          handleCloseModal()
          Swal.fire({
            title: res.data.message,
            text: "",
            icon: `${ALERT_ICON_ERR}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
              
            }
          });
        }
      },
      (err) => {
        Swal.fire({
          title: `${ERR_POSTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    );
  };
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };
  const handleRowPageChange = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  const agritech_token = cookies.get("agritech_token");
 
  const [searchValue, setSearchValue] = useState("");
  const [startDatte, setStartDatte] = useState("");
  const [endDatte, setEndDatte] = useState("");
  const handleChangeStart = (date) => {
    setStartDatte(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };
  const handleChangeEnd = (date) => {
    setEndDatte(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };
  useEffect(()=>{
    if(searchValue||startDatte||endDatte){
      handleSearchh()
    }
    else{
      loadCustomer()
    }
  },[startDatte,endDatte,searchValue])
  const handleSearchh=async ()=>{
    try {
      const requestUrl=`/app/customerSearch?&query=${searchValue}&startDatte=${startDatte}&endDatte=${endDatte}`;
      const response=await privateApiCall(requestUrl,"GET")
      console.log(response,"response")
      if(response.data.status){
        setValues((prevValues)=>({
          ...prevValues,
          data:response.data.data
        }))
      }
      else{
        setValues((prevState) => ({
                 ...prevState,
                  data: [],
                }));
      }
    } catch (error) {

      
    }
  }
  
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setCustomer({
      ...customer,
      namee: "",
      error:false,
      email: "",
      mobile: "",
    });
    setShowModal(false);
  };
  
 const loadCustomer=async()=>{
    const requestUrl=`${CUSTOMER_PATH}`
    const response=await privateApiCall(requestUrl,"GET")
    console.log(response,"response daata load customer")
    const k = 1;
    for (let i = 0; i < response.data.length; i++) {
      response.data[i].sno = k + i;
    }
    setValues({ ...values, data: response.data });
  }
  // const handleSearch = async (e) => {
  //   const searchValue = e.target.value;

  //   try {
  //     const requestUrl = `${SEARCH_CUSTOMER}?&query=${searchValue}`;
  //     const response = await privateApiCall(requestUrl, "GET");

  //     if (response.data && response.data.length) {
  //       setValues((prevState) => ({
  //         ...prevState,
  //         data: response.data,
  //       }));
  //       setPage(0);
  //     } else {
  //       setValues((prevState) => ({
  //         ...prevState,
  //         data: [],
  //       }));
  //     }
  //   } catch (error) {
  //     console.log(error, "errors");
  //     Swal.fire({
  //       title: `${ERR_ALERT_TITLE}`,
  //       icon: `${ALERT_ICON_ERR}`,
  //       text: `${ERR_GETTING}`,
  //       allowOutsideClick: false,
  //       showCancelButton: false,
  //       confirmButtonText: `${ALERT_BUTTON_OK}`,
  //     });
  //   }
  // };
  const loadData1 = async (type, value) => {
    const requestUrl = `${CUSTOMER_PATH}?token=${cookies.get(
      "agritech_token"
    )}&startDate=${type === "start" ? value : startDate}&endDate=${
      type === "end" ? value : endDate
    }`;
    try {
      const response = await privateApiCall(requestUrl, "GET");
      const data = response.data.map((item, index) => ({
        ...item,
        sno: index + 1,
      }));

      if (type === "start") {
        setValues({ ...values, data, startDate: value });
      } else {
        setValues({ ...values, data, endDate: value });
      }
    } catch (err) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  const handleShowClose1 = () => {
    // setSellPId(null)
    setSellModal(false);
    setLocations({ lat: null, lng: null });
    setValues((prevData) => ({
      ...prevData,
      location: "",
    }));
  };
  const onFileChange1 = (e) => {
    setValues({
      ...values,
      error: false,
      img1: URL.createObjectURL(e.target.files[0]),
    });
    setImage1(e.target.files[0]);
  };
  const onFileChange2 = (e) => {
    setValues({
      ...values,
      error: false,
      img2: URL.createObjectURL(e.target.files[0]),
    });
    setImage2(e.target.files[0]);
  };
  const onFileChange3 = (e) => {
    setValues({
      ...values,
      error: false,
      img3: URL.createObjectURL(e.target.files[0]),
    });
    setImage3(e.target.files[0]);
  };
  const onFileChange4 = (e) => {
    setValues({
      ...values,
      error: false,
      img4: URL.createObjectURL(e.target.files[0]),
    });
    setImage4(e.target.files[0]);
  };
  const requestFileChange = (file) => {
    setRequest({
      ...request,
      // rimg1: URL.createObjectURL(e.target.files[0]),
      rimg1: file,
    });
    // setrImage(e.target.files[0]);
    setrImage(URL.createObjectURL(file));
  };
  const bChange = (name) => (e) => {
    if (name === "b1") {
      setProfile({
        ...profile,
        bimg1: URL.createObjectURL(e.target.files[0]),
      });
      setbImage1(e.target.files[0]);
    } else if (name === "b2") {
      setProfile({
        ...profile,
        bimg2: URL.createObjectURL(e.target.files[0]),
      });
      setbImage2(e.target.files[0]);
    } else if (name === "b3") {
      setProfile({
        ...profile,
        bimg3: URL.createObjectURL(e.target.files[0]),
      });
      setbImage3(e.target.files[0]);
    } else {
      setProfile({
        ...profile,
        bimg4: URL.createObjectURL(e.target.files[0]),
      });
      setbImage4(e.target.files[0]);
    }
  };
  // const handleChangeStart = (date) => {
  //   loadData1("start", date ? dayjs(date).format("MM/DD/YYYY") : "");
  // };
  // const handleChangeEnd = (date) => {
  //   loadData1("end", date ? dayjs(date).format("MM/DD/YYYY") : "");
  // };
  const handleSellStartDateChange = (date) => {
    setValues((prevData) => ({
      ...prevData,
      availableDate: date ? dayjs(date).format("MM/DD/YYYY") : "",
    }));
  };
  const handleSellEndDateChange = (date) => {
    setValues((prevData) => ({
      ...prevData,
      expdate: date
        ? dayjs(date).format("MM/DD/YYYY")
        : dayjs().add(1, "day").format("MM/DD/YYYY"),
    }));
  };
  // const handleRequestChange = (key) => (e) => {
  //   console.log(key,"key data")
  //   if(key==="rpincode"){
  //     if(e.target.value.length === 6 && /^\d{6}$/.test(e.target.value)){
  //       setPincodeBError("")
  //     }
  //     else{
  //       setPincodeBError(`${STRING_CONSTANTS.PIN_ALERT}`)
  //       return;
  //     }
  //   }
  //   setRequest((prevState) => ({
  //     ...prevState,
  //     [key]: e.target.value,
  //   }));
  // };
  const handleRequestChange = (key) => (e) => {
    const value = e.target.value; // Store value for cleaner access
 
  
    if (key === "rpincode") {
      if (value.length === 6 && /^\d{6}$/.test(value)) {
        setPincodeBError(""); // Clear error if valid
      } else {
        setPincodeBError(`${STRING_CONSTANTS.PIN_ALERT}`); // Set error if invalid
         
      }
    }
    if (key === "rqty") {
      const numericValue = Number(value);
      if (numericValue < 0) {
          setRequest((prevState) => ({
              ...prevState,
              qerror: true,  
              rqty: value,  
          }));
          return;  
      } else {
          setRequest((prevState) => ({
              ...prevState,
              qerror: false,  
              rqty: value,  
          }));
      }
  }
    
  
    
    setRequest((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  
  const requestPost = (e) => {
    e.preventDefault();
    if(!rpincode||rpincode.length!==6|| !/^\d{6}$/.test(rpincode))
    {
       
      setPincodeBError(`${STRING_CONSTANTS.PIN_ALERT}`)
      return
    }
    setPincodeBError("")
    if (rqty <= 0) {
      setRequest((prevState) => ({
        ...prevState,
        qerror: true,  
      }));
      return;
    }
    setRequest((prevState) => ({
      ...prevState,
      qerror: false,  
    }));
    const { lat: latitude, lng: longitude } = locations;
    const formData = new FormData();
    formData.append("image1", rimg1);
    formData.append("mobile_no", mobile_no);
    formData.append("title", rtitle);
    formData.append("description", rdescription);
    formData.append("qty", rqty);
    formData.append("location", rlocation);
    formData.append("unit", runit);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("startDate", rstartDate);
    formData.append("endDate", rendDate);
    formData.append("pincode",rpincode);
    formData.append("city",rcity)
    formData.append("state",rstate)
    const requestUrl = "/api/organisation/addBuyproduct";
    privateApiCall(requestUrl, "POST", formData).then((res) => {
      if (!res.data.status) {
        Swal.fire({
          title: res.data.msg,
          text: "",
          icon: `${ALERT_ICON_ERR}`,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      } else {
        setBuyModal(false);
        setRequest({
          rstartDate: dayjs().format("MM/DD/YYYY"),
          rendDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
        });
        Swal.fire({
          title: res.data.msg,
          text: "",
          icon: `${ALERT_ICON_SUCC}`,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setRequest({
              ...request,
              rimg1: "",
              rkeywords: "",
              rtitle: "",
              rdescription: "",
              rpincode:"",
              rcity:"",
              rstate:"",
              rqty: "",
              rprice: "",
              runit: "",
              rlocation: "",
              rlatitude: "",
              rlongitude: "",
              rstartDate: dayjs().format("MM/DD/YYYY"),
              rendDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
            });
            setrImage("");
          }
        });
      }
    });
  };
  const handleChange = (nameParams) => (e) => {
    const { name, value } = e.target;
    if(name==="pincode"){
      if (value.length === 6 && /^\d{6}$/.test(value)) {
        setPincodeError("");  
      } else {
        setPincodeError(`${STRING_CONSTANTS.PIN_ALERT}`); // Set error if invalid
      }
    }
    if (name === "qty") {
      const numericValue = Number(value);
      if (value === "" || numericValue > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setValues({ ...values, error: false, [nameParams]: e.target.value });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `${STRING_CONSTANTS.MIN_QTY}`,  
        }));
      }
      return;
    }
    if(name==="price"){
      const numericValue = Number(value);
      if (value === "" || numericValue > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setValues({ ...values, error: false, [nameParams]: e.target.value });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Price must be greater than 0",  
        }));
      }
      return;
    }
    if (name === "location" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Location is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    setValues({ ...values, error: false, [nameParams]: e.target.value });
  };
  // const handleCheckBoxChange = (name, type) => (e) => {
  //   setValues({ ...values, [name]: type });
  // };
  const handleCheckBoxChange = (name, value) => (e) => {
    const isChecked = e.target.checked ? value : "0";
    setShowAlert(false);

    switch (name) {
      case "organic_status":
        setValues({
          ...values,
          organic_status: isChecked,
          safe_status: isChecked,
        });
        break;
      case "safe_status":
        setValues({
          ...values,
          organic_status: isChecked ? "0" : "1",
          safe_status: isChecked,
        });

        break;
      case "auction_status":
        setValues({
          ...values,
          auction_status: isChecked,
          ecommerce_status: isChecked ? "0" : values.ecommerce_status,
        });
        break;
      case "ecommerce_status":
        setValues({
          ...values,
          ecommerce_status: isChecked,
          auction_status: isChecked ? "0" : values.auction_status,
        });
        break;
      default:
        setValues({ ...values, [name]: isChecked });
    }
  };
  const [keywordss, setKeywordss] = useState([]);

  const fetchLanguage = async () => {
    try {
      const requestUrl = `${LANGUAGE}/getactivelan`;

      const response = await privateApiCall(requestUrl, "GET");
      const responseData = response.data;
      setActiveLanguages(responseData);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: ` ${ALERT_BUTTON_OK}`,
      });
    }
  };

  const fetchKeywords = async () => {
    try {
      const requestUrl = `${KEYWORDS}/getKeywords`;
      const response = await privateApiCall(requestUrl, "GET");

      const fetchKeywords = [...response.data.keywords, { keywords: "Other" }];
      setKeywordss(fetchKeywords);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  useEffect(() => {
    fetchLanguage();
    fetchKeywords();
  }, []);
  const handleModalSubmit = async () => {
    try {
      const requestUrl = `${KEYWORDS}/addKeyword`;

      const response = await privateApiCall(requestUrl, "POST", {
        keyword: keyword,
        languageCode: selectedLanguage,
        status: "active",
      });
      if (response.status === 200) {
        setKeywordss((prevKeywords) => [
          ...prevKeywords,
          { keywords: keyword, language: selectedLanguage },
        ]);
        setShowModal1(false);
        setKeyword("");
        setSelectedLanguage("");
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          text: response.data.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          text: error.response?.data?.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    }
  };

  const createPost = (e) => {
    e.preventDefault();
    if (auction_status !== "1" && ecommerce_status !== "1") {
      setShowAlert(true);
      return;
    }
    if (selectedKeyword.length === 0) {
      // setShowKeywordAlert(true)
      setShowKeywordAlert(`${STRING_CONSTANTS.KEYWORD_ALERT}`)
      return;
    }
    if (!pincode || pincode.length !== 6 || !/^\d{6}$/.test(pincode)) {
      setPincodeError(`${STRING_CONSTANTS.PIN_ALERT}`);
      return;  
    }
  
    
    setPincodeError("");
    setShowKeywordAlert("")

    const { lat: latitude, lng: longitude } = locations;
    const formData = new FormData();
    formData.append("image1", image1);
    formData.append("image2", image2);
    formData.append("image3", image3);
    formData.append("image4", image4);
    formData.append("mobile_no", s_mobile);
    formData.append("title", title);
    formData.append("description", description);
    formData.append(
      "selectedKeyword",
      selectedKeyword.map((keyword) => keyword.keywords)
    );
    formData.append("qty", qty);
    formData.append("price", price);
    formData.append("location", location);
    formData.append("unit", unit);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("expDate", expdate);
    formData.append("availableDate", availableDate);
    formData.append("auction_status", auction_status);
    formData.append("organic_status", organic_status);
    formData.append("safe_status", safe_status);
    formData.append("transpot_status", transpot_status);
    formData.append("chargesAfter", chargesAfter);
    formData.append("freeDelivery", freeDelivery);
    formData.append("pincode",pincode);
    formData.append("city",city);
    formData.append("state",state)
    const requestUrl = "/api/organisation/addSellproduct";
    privateApiCall(requestUrl, "POST", formData).then((res) => {
      if (!res.data.status) {
        setSellModal(false);
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        setSellModal(false);
        Swal.fire({
          title: res.data.msg,
          text: "",
          icon: "success",
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        }).then((result) => {
          if (result.isConfirmed) {
            setValues({
              ...values,
              title: "",
              description: "",
              s_mobile: "",
              qty: "",
              price: "",
              unit: "",
              location: "",
              latitude: "",
              longitude: "",
              expdate: dayjs().add(1, "day").format("MM/DD/YYYY"),
              availableDate: dayjs().format("MM/DD/YYYY"),
              auction_status: "0",
              ecommerce_status: "0",
              organic_status: "0",
              safe_status: "0",
              transpot_status: "0",
              chargesAfter: "",
              freeDelivery: "",
              pincode:"",
              city:"",
              state:""
            });
            setSelectedKeyword([]);
          }
        });
      }
    });
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

 
  const handleSubmitOrgSwitch = (orgID) => {
   
  const userID = details._id
    // Example: You can make an API call to switch to the selected organization
    const requestUrl = `/app/switchOrg`;  // Your API endpoint for switching org
    privateApiCall(requestUrl, "POST",{orgID,userID}).then((res) => {
      if (res.data.status) {
        // Handle successful switch (e.g., close the dialog, refresh data)
        handleClose2();
        loadCustomer();  // Refresh data if needed
      } else {
        console.error("Failed to switch organization:", res.data.message);
      }
    }).catch((error) => {
      console.error("Error during org switch:", error);
    });
  };

  const create = (row) => {
    const handlePOP = (e) => {
      console.log(row,"row dara details...")
      
      setDetails(row);
      setOpen1(true);
    };
    const handleSwithOrgPOP = (e) => {
    
      const requestUrl= '/app/getOrgList'
      privateApiCall(requestUrl, "GET").then(
        (res) => {
          if (res.data.status) {
        
           
            const filteredOrgList = res.data.list.filter(
              (org) => org._id !== row.organisation_id
            );
            
            setOrgList(filteredOrgList);
          }
        
        }
      );
      setDetails(row);
      setOpen2(true);
    };
    const handleBChange = (name) => (e) => {
      const value = e.target.value;
      if(name==="contactNumber"){
        const isValidContactNumber = /^[1-9]\d{0,9}$/.test(value);
        if (isValidContactNumber || value === "") {
          setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
            berror: false,
          }));
        } else {
          setProfile((prevProfile) => ({
            ...prevProfile,
            berror: true, // Set error flag if validation fails
          }));
          return; // Stop if the contact number is invalid
        }
      
      }
      else if (name === "serviceCharge") {
        const numericValue = parseFloat(value);
        
        
        if (numericValue < 0) {
          
          setProfile((prevProfile) => ({
            ...prevProfile,
            berror: true,
            [name]: numericValue
          }));
        } else {
          // Reset error state and set the numeric value
          setProfile((prevProfile) => ({
            ...prevProfile,
            berror: false,
            [name]: numericValue
          }));
        }
      } else {
         
        setProfile((prevProfile) => ({
          ...prevProfile,
          [name]: value
        }));
      }
    };
 
    const handleBCheckBoxChange = (name, type) => (e) => {
      setProfile({ ...profile, [name]: type });
    };
    const createBusiness = (e) => {
      console.log(serviceCharge,"service charge")
      e.preventDefault();
      // e.persist();
      const isValidContactNumber = /^[1-9]\d{9}$/.test(contactNumber); // Must be 10 digits and start with 1-9

      if (!isValidContactNumber) {
        Swal.fire({
          title: "Please enter a valid 10-digit contact number that starts with 1-9.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return; 
      }
      if (profile.berror || parseFloat(serviceCharge) <= 0) {
        Swal.fire({
          title: "Please enter a valid Service Charge",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;  
      }
      const formData = new FormData();
      formData.append("image1", bimage1);
      formData.append("image2", bimage2);
      formData.append("image3", bimage3);
      formData.append("image4", bimage4);
      formData.append("mobile_no", row.mobile_no);
      formData.append("btype", selectedBtype);
      formData.append("company_name", cName);
      formData.append("description", bDescription);
      formData.append("contact_person", contactPerson);
      formData.append("contact_number", contactNumber);
      formData.append("address", address);
      formData.append("latitude", row.latitude);
      formData.append("longitude", row.longitude);
      formData.append("expDate", expdate);
      formData.append("charges", serviceCharge);
      formData.append("deliveryAvailable", bDeliveryAvailable);
      formData.append("chargesAfter", bChargesAfter);
      formData.append("freeDelivery", bFreeDelivery);
      formData.append("status", 1);
      const requestUrl = "/api/user/add_bussinessProfile";
      privateApiCall(requestUrl, "POST", formData).then((res) => {
        console.log(res,"response data")
        if (!res.data.status) {
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: `${ALERT_ICON_ERR}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          // close the modal
          handleCloseBusiness()
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: `${ALERT_ICON_SUCC}`,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          }).then((result) => {
            if (result.isConfirmed) {
              setProfile({
                ...profile,
                bimg1: "",
                bimg2: "",
                bimg3: "",
                bimg4: "",
                selectedBtype: "",
                cName: "",
                bDescription: "",
                contactPerson: "",
                contactNumber: "",
                address: "",
                serviceCharge: "",
                bLatitude: "",
                bLongitude: "",
                bDeliveryAvailable: "",
                bChargesAfter: "",
                bFreeDelivery: "",
              });
              setbImage1("");
              setbImage2("");
              setbImage3("");
              setbImage4("");
              
            }
            
          });
        }
      });
    };

    const handleOpenBuyModal = (row) => {
      setRequest({
        mobile_no: row.mobile_no,
        rstartDate: dayjs().format("MM/DD/YYYY"),
        rendDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
      });

      setBuyModal(true);
    };
    const handleOpenSellModal = (row) => {
      setValues((prevValues) => ({
        ...prevValues,
        s_mobile: row.mobile_no,
      }));
      setSellModal(true);
    };
    return (
      <span>
        <div style={{ display: "flex", marginTop: "8px" }}>
          <div style={{ marginLeft: "-8px" }}>
            {permissions.some(
              (item) =>
                item.keyword === "profilesCustomers" &&
                item.isPostProduct === true
            ) && (
              <>
                <img
                  className="mr-2"
                  data-toggle="modal"
                  // data-target={"#post" + row._id}
                  data-target={row ? `#post${row._id}` : ""}
                  onClick={() => handleOpenSellModal(row)}
                  data-tooltip-id="1"
                  data-tooltip-content={`${POST_PDT}`}
                  src="/assets/icons/post.png"
                  width="20"
                  height="20"
                  alt=""
                ></img>
                <Tooltip id="1" />
              </>
            )}
          </div>
          {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" &&
              item.isAddBusiness === true
          ) && (
            <div>
              <img
                className="mr-2"
                data-toggle="modal"
                data-target={"#business" + row._id}
                src="/assets/icons/business.png"
                data-tooltip-id="2"
                onClick={openModal}
                data-tooltip-content={`${ADD_BUS}`}
                width="17"
                height="17"
                alt=""
              ></img>
              <Tooltip id="2" />
            </div>
          )}
          {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" &&
              item.isRequestProduct === true
          ) && (
            <div>
              <img
                className="mr-2"
                data-toggle="modal"
                data-target={"#request" + row._id}
                onClick={() => handleOpenBuyModal(row)}
                src="/assets/icons/request.png"
                data-tooltip-id="3"
                data-tooltip-content={`${REQ_PDT}`}
                width="22"
                height="22"
                alt=""
              ></img>
              <Tooltip id="3" />
            </div>
            
          )}
           <i
              className="mr-2"
              data-tooltip-id="1"
              data-tooltip-content={`${VIEW_DET}`}
              style={{ fontStyle: "normal" }}
            >
              {" "}
              <FiEye onClick={handlePOP} color="#9368f3" size="15" />
              <Tooltip id="1" />
            </i>
            {row.activeOrgStatus==="2"&& 
            
            <i
              className="mr-2"
              data-tooltip-id="9"
              data-tooltip-content={`Switch Organistion`}
              style={{ fontStyle: "normal" }}
            >
              {" "}
              <FaCity onClick={handleSwithOrgPOP} color="#9368f3" size="15" />
              <Tooltip id="9" />
            </i>}
        </div>
       {isModalOpen && (
        <div
          id={"business" + row._id}
          className={`modal fade bs-example-modal-xl ${isModalOpen ? 'show' : ''}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden={!isModalOpen}
        >
          <div className="modal-dialog modal-xl">
            <form onSubmit={createBusiness}>
              <a href="#" title="Approve" data-toggle="tooltip">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h5 className="modal-title text-dark" id="myModalLabel">
                      {STRING_CONSTANTS.ADD_BUSSINESS_PROFILE_FOR}
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="row ">
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_1}
                        </div>

                        {bimg1 !== "" ? (
                          <div>
                            <img
                              src={bimg1}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={bChange("b1")}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_2}
                        </div>

                        {bimg2 !== "" ? (
                          <div>
                            <img
                              src={bimg2}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={bChange("b2")}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_3}
                        </div>

                        {bimg3 !== "" ? (
                          <div>
                            <img
                              src={bimg3}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={bChange("b3")}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div
                          className="text-dark text-left font-size-16 mb-2"
                          style={{ letterSpacing: "0.04rem" }}
                        >
                          {STRING_CONSTANTS.IMAGE_4}
                        </div>

                        {bimg4 !== "" ? (
                          <div>
                            <img
                              src={bimg4}
                              alt=""
                              height="150px"
                              width="100%"
                            />
                          </div>
                        ) : (
                          <img
                            src="/assets/icons/try.jpg"
                            alt=""
                            height="150px"
                            width="100%"
                            className=""
                          />
                        )}

                        <div className="mt-3">
                          <input
                            type="file"
                            id="image"
                            className="mt-3 margin-top-10"
                            onChange={bChange("b4")}
                            accept="image/png,  image/jpeg"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.COMPANY_NAME}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={cName}
                            placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                            onChange={handleBChange("cName")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.CONTACT_PERSON}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={contactPerson}
                            placeholder={
                              STRING_CONSTANTS.CONTACT_PERSON_PLACEHOLDER
                            }
                            onChange={handleBChange("contactPerson")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.CONTACT_NUMBER}
                          </div>

                          <input
                            type="tel"
                            className="form-control"
                            value={contactNumber}
                            placeholder={
                              STRING_CONSTANTS.CONTACT_NUMBER_PLACEHOLDER
                            }
                            onChange={handleBChange("contactNumber")}
                            required
                          />
                          
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.BUSSINESS_TYPE}
                          </div>

                          <select
                            id="single"
                            className="form-control"
                            value={selectedBtype}
                            onChange={handleBChange("selectedBtype")}
                          >
                            <option value="">
                              {STRING_CONSTANTS.SELECT_BUSINESS_TYPE}
                            </option>

                            {bType.map((person, i) => (
                              <option key={i} value={person._id}>
                                {" "}
                                {person.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.SERVICE_CHARGES}
                          </div>

                          <input
                            type="number"
                            className="form-control"
                            value={serviceCharge}
                            placeholder={STRING_CONSTANTS.CHARGES_PLACEHOLDER}
                            onChange={handleBChange("serviceCharge")}
                            required
                          />
                          {berror && <span style={{ color: 'red' }}>Service charge cannot be negative.</span>}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.ADDRESS}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={address}
                            placeholder={STRING_CONSTANTS.ADDRESS_PLACEHOLDER}
                            onChange={handleBChange("address")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div
                            className="text-dark text-left font-size-16 mb-2 "
                            style={{ letterSpacing: "0.04rem" }}
                          >
                            {STRING_CONSTANTS.DESCRIPTION}
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            value={bDescription}
                            placeholder={
                              STRING_CONSTANTS.DESCRIPTION_PLACEHOLDER
                            }
                            onChange={handleBChange("bDescription")}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4 ml-3 mt-0 d-flex justify-content-start ">
                        <div className="form-group">
                          {bDeliveryAvailable === 1 ? (
                            <i>
                              <FaRegCheckSquare
                                color="#769A0F"
                                onClick={handleBCheckBoxChange(
                                  "bDeliveryAvailable",
                                  0
                                )}
                                size="25"
                              />
                            </i>
                          ) : (
                            <i>
                              <FaRegSquare
                                color="#98a6ad"
                                onClick={handleBCheckBoxChange(
                                  "bDeliveryAvailable",
                                  1
                                )}
                                size="25"
                              />
                            </i>
                          )}

                          <label className="ml-2 mt-4 text-dark">
                            {STRING_CONSTANTS.TRANSPORT_AVAILABLE}
                          </label>
                        </div>
                      </div>
                      {bDeliveryAvailable === 1 && (
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div
                                className="text-dark text-left font-size-16 mb-2 "
                                style={{ letterSpacing: "0.04rem" }}
                              >
                                {STRING_CONSTANTS.FREE_DELEVERY_UPTO}
                              </div>

                              <input
                                type="number"
                                className="form-control"
                                value={bFreeDelivery}
                                placeholder={STRING_CONSTANTS.KM_PLACEHOLDER}
                                onChange={handleBChange("bFreeDelivery")}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div
                                className="text-dark text-left font-size-16 mb-2 "
                                style={{ letterSpacing: "0.04rem" }}
                              >
                                {STRING_CONSTANTS.CHARGES_AFTER_KM}
                              </div>

                              <input
                                type="number"
                                className="form-control"
                                value={bChargesAfter}
                                placeholder={
                                  STRING_CONSTANTS.CHARGES_PLACEHOLDER
                                }
                                onChange={handleBChange("bChargesAfter")}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      data-dismiss="modal"
                      onClick={handleCloseBusiness}
                    >
                      {STRING_CONSTANTS.CLOSE}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      {STRING_CONSTANTS.SUBMIT}
                    </button>
                  </div>
                </div>
              </a>
            </form>
          </div>
        </div>
       )}
        
             
      </span>
    );
  };
  const deleteCus = (row) => {
    const handleBlock = (event, url) => {
      event.preventDefault();
      Swal.fire({
        title: `${CONFIRM_TITLE}`,
        text: `${CONFIRM_BLOCK_CUS}`,
        icon: `${ALERT_ICON_WARNING}`,
        showCancelButton: true,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        cancelButtonText: `${ALERT_BUTTON_NO}`,
      }).then((result) => {
        if (result.isConfirmed) {
          privateApiCall(url, "POST", {})
            .then((response) => {
              console.log(response,"response after blocking")

              if (response.status === 200) {
                setValues((prevState) => {
                  const updatedData = prevState.data.map((user) => {
                    if (user._id === row._id) {
                      return { ...user, delete_status: "inactive" };  
                    }
                    return user;  
                  });
                  return { ...prevState, data: updatedData };  
                })
                Swal.fire({
                  title: `${STRING_CONSTANTS.BLOCKED_MESSAGE}`,
                  icon: "success",
                  confirmButtonText: "OK"
                }) 
              } else {
                Swal.fire(`${STRING_CONSTANTS.ERR_BLOCK_MESSAGE}`);
              }
            })
            .catch((error) => {
              Swal.fire(`${STRING_CONSTANTS.ERR_BLOCK_MESSAGE}`);
            });
        }
      });
    };

    const handleUnblock = (event, url) => {
      event.preventDefault();
      Swal.fire({
        title: `${CONFIRM_TITLE}`,
        text: `${CONFIRM_UNBLOCK_CUS}`,
        icon: `${ALERT_ICON_ERR}`,
        showCancelButton: true,
        confirmButtonText: ` ${ALERT_BUTTON_YES}`,
        cancelButtonText: `${ALERT_BUTTON_NO}`,
      }).then((result) => {
        if (result.isConfirmed) {
          privateApiCall(url, "POST", {})
            .then((response) => {
            console.log(response,"response after unblocking")
              if (response.status === 200) {
                setValues((prevState) => {
                  const updatedData = prevState.data.map((user) => {
                    if (user._id === row._id) {
                      return { ...user, delete_status: "active" };  
                    }
                    return user;  
                  });
                  return { ...prevState, data: updatedData };  
                });
                Swal.fire({
                  title: `${STRING_CONSTANTS.UNBLOCKED_MESSAGE}`,
                  icon: "success",
                  confirmButtonText: "OK"
                }).then(()=>{
                  loadCustomer();
                })
              } else {
                Swal.fire(`${STRING_CONSTANTS.ERR_UNBLOCK_MESSAGE}`);
              }
            })
            .catch((error) => {
              Swal.fire(`${STRING_CONSTANTS.ERR_UNBLOCK_MESSAGE}`);
            });
        }
      });
    };

    if (row && row.delete_status === "active") {
      return (
        <span>
          {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" && item.isBlock === true
          ) && (
            <>
              <button
                type="button"
                className="btn btn-icon waves-effect  waves-light btn-danger"
                alt="Block"
                data-tooltip-id="8"
                data-tooltip-content={`Block`}
                data-toggle="modal"
                data-target={"#myModal" + row._id}
                onClick={(event) =>
                  handleBlock(
                    event,
                    `${BLOCK_USER_PATH}/${row._id}/?token=${cookies.get(
                      "agritech_token"
                    )}`
                  )
                }
                style={{
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "8px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  marginTop: "10px",
                }}
              >
                {" "}
                <i
                  className="fas fa-thumbs-down"
                  style={{ fontSize: "8px", marginBottom: "0px" }}
                ></i>{" "}
              </button>
              <Tooltip id="8" />
            </>
          )}
        </span>
      );
    } else if (row && row.delete_status === "inactive") {
      return (
        <span>
          {permissions.some(
            (item) =>
              item.keyword === "profilesCustomers" && item.isUnblock === true
          ) && (
            <>
              <button
                type="button"
                className="btn btn-icon waves-effect   waves-light btn-success"
                data-toggle="modal"
                alt="Unblock"
                data-tooltip-id="7"
                data-tooltip-content={`Unblock`}
                data-target={"#myModal" + row._id}
                onClick={(event) =>
                  handleUnblock(
                    event,
                    `${UNBLOCK_USER_PATH}/${row._id}/?token=${cookies.get(
                      "agritech_token"
                    )}`
                  )
                }
                style={{
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "9px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  marginTop: "10px",
                }}
              >
                {" "}
                <i
                  className="fas fa-thumbs-up"
                  style={{ fontSize: "8px", marginBottom: "0px" }}
                ></i>{" "}
              </button>
              <Tooltip id="7" />
            </>
          )}
        </span>
      );
    } 
    
  
    else {
      return null;
    }
  
  };





  const handleOrgRequest = (id,status)=>{
    if(status === "0") {
      let swalTitle;
      let showButtons = false;
      swalTitle = "Choose an action for this customer's request to join the organisation.";
      showButtons = true;
    
      Swal.fire({
        title: swalTitle,
        icon: `${ALERT_ICON_WARNING}`,
        showCancelButton: true,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        cancelButtonText: `${ALERT_BUTTON_NO}`,
        showDenyButton: showButtons, // Show deny button only if status is 0
        denyButtonText: 'Accept',    // Deny button for "accept" action
        confirmButtonText: 'Reject', // Confirm button for "reject" action
        showConfirmButton: showButtons, // Show confirm button for "reject" action
      }).then((result) => {
        if (result.isDenied) {
          // Handle "accept" action
          privateApiCall(`${HANDLE_ORG_REQ}`, "POST", { id, status: "2" }) // "2" for accept
            .then((response) => {
              loadCustomer();
              Swal.fire(`${STRING_CONSTANTS.SUCC_UPDATE_STATUS}`);
            })
            .catch((error) => {
              Swal.fire(`${STRING_CONSTANTS.ERR_BLOCK_MESSAGE}`);
            });
        } else if (result.isConfirmed) {
          // Handle "reject" action
          privateApiCall(`${HANDLE_ORG_REQ}`, "POST", { id, status: "1" }) // "1" for reject
            .then((response) => {
              loadCustomer();
              Swal.fire(`${STRING_CONSTANTS.SUCC_UPDATE_STATUS}`);
            })
            .catch((error) => {
              Swal.fire(`${STRING_CONSTANTS.ERR_UPDATE_STATUS}`);
            });
        }
      });
    } else{
      const swalTitle = status ==="1" ? "You want to reject  this customer's request to join the organisation?" : status === "2" ? "You want to accept  this customer's request to join the organisation?" :"You want to   this customer's request to join the organisation?"
      Swal.fire({
        title: `${swalTitle}`,
      //  text: `${CONFIRM_ACCEPT_CUS}`,
        icon: `${ALERT_ICON_WARNING}`,
        showCancelButton: true,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
    
        cancelButtonText: `${ALERT_BUTTON_NO}`,
      }).then((result) => {
        if (result.isConfirmed) {
          const reqeustUrl = `${HANDLE_ORG_REQ}`
          privateApiCall(reqeustUrl, "POST", {id,status})
            .then(async(response) => {
               loadCustomer()
    
     Swal.fire(`${STRING_CONSTANTS.SUCC_UPDATE_STATUS}`);
            })
            .catch((error) => {
              Swal.fire(`${STRING_CONSTANTS.ERR_UPDATE_STATUS}`);
            });
        }
      });
    }
           
    
      
    }
  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="profiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.CUSTOMERS}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.CUSTOMERS}</h4>
                </div>
              </div>
            </div>
            <div className="card-box">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-2 mt-3i">
                    {permissions.some(
                      (item) =>
                        item.keyword === "profilesCustomers" &&
                        item.isCreate === true
                    ) && (
                      <button
                        className="btn btn-icon waves-effect waves-light btn-primary mb-3"
                        onClick={handleShowModal}
                      >
                        <i className="fa fa-user"></i>{" "}
                        {STRING_CONSTANTS.ADD_CUSTOMER}
                      </button>
                    )}
                    <Modal
                      open={showModal}
                      onClose={handleCloseModal}
                      closeAfterTransition
                      BackdropProps={{
                        style: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        },
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disableBackdropClick
                    >
                      <Fade in={showModal}>
                        <Box
                          sx={{
                            bgcolor: "background.paper",
                            boxShadow: 1,
                            p: 4,
                            borderRadius: 2,
                            width: "60%",
                            marginBottom: "50px",
                          }}
                        >
                          <h2>{STRING_CONSTANTS.ADD_CUSTOMER}</h2>
                          <form onSubmit={onSubmit}>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Name"
                                  variant="outlined"
                                  name="namee"
                                  value={namee}
                                  onChange={handleCustomerChange("namee")}
                                  placeholder={
                                    STRING_CONSTANTS.NAME_PLACEHOLDER
                                  }
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="email"
                                  variant="outlined"
                                  name="email"
                                  value={email}
                                  onChange={handleCustomerChange("email")}
                                  placeholder={
                                    STRING_CONSTANTS.EMAIL_PLACEHOLDER
                                  }
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="mobileno"
                                  variant="outlined"
                                  name="mobileno"
                                  value={mobile}
                                  onChange={handleCustomerChange("mobile")}
                                  placeholder={
                                    STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER
                                  }
                                  required
                                  error={!!error}
                                  helperText={error}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justifyContent="flex-end"
                              style={{
                                marginTop: "40px",
                                marginBottom: "20px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModal}
                                style={{ marginRight: "10px" }}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "#769A0F" }}
                              >
                                {STRING_CONSTANTS.SUBMIT}
                              </Button>
                            </Grid>
                          </form>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                  <div
                    className="row container-fluid"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {permissions.some(
                      (item) =>
                        item.keyword === "profilesCustomers" &&
                        item.isDateSearch === true
                    ) && (
                      <>
                        <div className="col-md-3 ">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                label=" Start Date"
                                format="DD/MM/YYYY"
                                // defaultValue={  dayjs(formData.endDate)}
                                defaultValue={dayjs(startDatte)}
                                name="startDate"
                                onChange={handleChangeStart}
                                required
                                // minDate={dayjs()}
                                slotProps={{
                                  textField: {
                                    error:
                                      !!startDatte &&
                                      !dayjs(startDatte).isValid(),
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                        <div className="col-md-3 ">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                label="End Date"
                                format="DD/MM/YYYY"
                                defaultValue={dayjs(endDatte)}
                                name="endDate"
                                onChange={handleChangeEnd}
                                required
                                // You can control the error state with this condition
                                slotProps={{
                                  textField: {
                                    error:
                                      !!endDatte && !dayjs(endDatte).isValid(),
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </>
                    )}

                    <div style={{ position: "relative", marginLeft: "auto" }}>
                      {permissions.some(
                        (item) =>
                          item.keyword === "profilesCustomers" &&
                          item.isSearch === true
                      ) && (
                        <>
                          <input
                            type="text"
                            placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                            className="form-control"
                            style={{
                              width: "200px",
                              paddingLeft: "35px",
                              boxSizing: "border-box",
                              marginLeft: "auto",
                            }}
                            onChange={(e) => {
                              setSearchValue(e.target.value)
                              // handleSearch(e);
                              // if (e.target.value.trim() === "") {
                              //   e.target.nextElementSibling.style.display =
                              //     "inline-block";
                              // } else {
                              //   e.target.nextElementSibling.style.display =
                              //     "none";
                              // }
                            }}
                          />
                          <i
                            className="fas fa-search"
                            style={{
                              position: "absolute",
                              left: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              color: "#aaa",
                            }}
                          ></i>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "98%" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {STRING_CONSTANTS.SN_NO}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.NAME}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.EMAIL}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.MOBILE}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.SUBSCRIPTION}
                        </TableCell>
                        <TableCell align="left">
                        {STRING_CONSTANTS.ORG}
                        </TableCell>
                        {/* <TableCell align="left">
                          {STRING_CONSTANTS.VALIDITY}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.COINS}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.LANGUAGE}
                        </TableCell> */}
                        <TableCell align="left">
                          {STRING_CONSTANTS.STATUS}
                        </TableCell>
                        <TableCell align="left">
                          {STRING_CONSTANTS.ACTIONS}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow key={row._id}>
                             <TableCell align="left">{page * rowsPerPage + index + 1}</TableCell>
                            <TableCell
                              align="left"
                              style={{ minWidth: "150px" }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">{row.mobile_no}</TableCell>
                            <TableCell align="left">{row.sub}</TableCell>
                            <TableCell align="left">{row.organisation_name}</TableCell>
                            {/* <TableCell align="left">{row.val}</TableCell>
                            <TableCell align="left">{row.coins}</TableCell>
                            <TableCell align="left">{row.language}</TableCell> */}

                                    <TableCell>
                                      {row.activeOrgStatus === "0" ? (
                                        <span className="btn-sm btn-warning" onClick={()=>handleOrgRequest(row._id,"0")} style={{ cursor: 'pointer' }}>
                                          {STRING_CONSTANTS.PENDING}
                                        </span>
                                      ) : row.activeOrgStatus === "2" ? (
                                        <span className="btn-sm btn-primary" onClick={()=>handleOrgRequest(row._id,"1")}  style={{ cursor: 'pointer' }}>
                                          {STRING_CONSTANTS.ACCEPTED}
                                        </span>
                                      ) : row.activeOrgStatus === "1" ? (
                                        <span className="btn-sm btn-danger" onClick={()=>handleOrgRequest(row._id,"2")} style={{ cursor: 'pointer' }}>
                                          {STRING_CONSTANTS.REJECTED}
                                        </span>
                                      ) : row.delete_status === "active" ? (
                                        <span className="btn-sm btn-success">
                                          {STRING_CONSTANTS.ACTIVE}
                                        </span>
                                      ) : row.delete_status === "inactive" ? (
                                        <span className="btn-sm btn-danger">
                                          {STRING_CONSTANTS.INACTIVE}
                                        </span>
                                      ): (
                                        <span className="btn-sm btn-secondary">
                                          {STRING_CONSTANTS.UNKNOWN_STATUS}
                                        </span>
                                      )} 
                                    </TableCell>

                            <TableCell align="center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {create(row)}
                                {deleteCus(row)}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                        <Dialog
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="details-modal-title"
                            aria-describedby="details-modal-description"
                            style={{
                              maxHeight: "80vh",
                              overflowY: "auto",
                              marginTop: "100px",
                              
                            }}
                            fullWidth
                            disableEnforceFocus
                            maxWidth="lg"
                          >
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="modal-content"
                            >
                              <Grid item xs={12} className="modal-header">
                                <h5 id="details-modal-title">
                                  {STRING_CONSTANTS.DETAILS}
                                </h5>
                                <Button onClick={handleClose1} color="inherit">
                                  <span
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  >
                                    &#128473;
                                  </span>
                                </Button>
                              </Grid>
                              <Grid item xs={12} className="modal-body">
                                <Table className="col-12">
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CUSTOMER_NAME}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                     {details.name}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.EMAIL}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.email}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.mobile_no}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.SUB_NAME}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.sub}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.VALIDITY}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                       {details.val}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.COINS}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                       {details.coins}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.SELECT_LANGUAGE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                       {details.language}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.ORG}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                       {details.organisation_name}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.REG_DATE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                       {formatDate(details.registered_date)}
                                    </TableCell>
                                  </TableRow>
                                  
                                </Table>
                              </Grid>
                              <Grid item xs={12} className="modal-footer">
                                <Button
                                  onClick={handleClose1}
                                  variant="outlined"
                                  color="error"
                                >
                                  {STRING_CONSTANTS.CLOSE}
                                </Button>
                              </Grid>
                            </Grid>
                          </Dialog>


                          <Dialog
                          open={open2}
                          onClose={handleClose2}
                          aria-labelledby="details-modal-title"
                          aria-describedby="details-modal-description"
                          style={{
                            maxHeight: "80vh",
                            overflowY: "auto",
                            marginTop: "100px",
                          }}
                          fullWidth
                          disableEnforceFocus
                          maxWidth="md"
                        >
                          <Grid container justifyContent="center" alignItems="center" className="modal-content">
                            <Grid item xs={12} className="modal-header">
                              <h5 id="details-modal-title">
                                {STRING_CONSTANTS.SWITCH}
                              </h5>
                              <Button onClick={handleClose2} color="inherit">
                                <span style={{ fontSize: "20px" }} aria-hidden="true">
                                  &#128473;
                                </span>
                              </Button>
                            </Grid>

                            <Grid item xs={12} className="modal-body">
                              <select
                                 
                                 onChange={handleOrgChange}
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <option value=""> {STRING_CONSTANTS.SELECT_ORG}</option>
                                {/* Dynamically map over the orgList to create options */}
                                {orgList && orgList.map((org) => (
                                  <option key={org._id}  value={org._id} >
                                    {org.name}
                                    <input type="hidden" value={org._id} />
                                  </option>
                                ))}
                              </select>
                            </Grid>

                            <Grid item xs={12} className="modal-footer">
                              <Button 
                          onClick={() => handleSubmitOrgSwitch(selectedOrg)}   variant="contained">
                                {STRING_CONSTANTS.SWITCH_TO}
                              </Button>
                              <Button onClick={handleClose2} variant="outlined" color="error">
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                            </Grid>
                          </Grid>
                        </Dialog>


                    </TableBody>
                    <div>
                      <style>
                        {`
          .pac-container {
            z-index: 2000 !important;
          }
        `}
                      </style>
                      <Modal
                        open={buyModal}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "100px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "900px",
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "8px",
                            boxShadow: 24,
                            outline: "none",
                            maxHeight: "80vh", // Limit the height to 80% of the viewport height
                            overflowY: "auto", 
                          }}
                        >
                          <h3>{STRING_CONSTANTS.ADD_BUY_PROD}</h3>
                          <form onSubmit={requestPost}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={4}>
                                {rimg1 ? (
                                  <img
                                    src={rimage}
                                    alt=""
                                    height="150px"
                                    width="100%"
                                  />
                                ) : (
                                  <img
                                    src="/assets/icons/try.jpg"
                                    alt=""
                                    height="150px"
                                    width="100%"
                                  />
                                )}
                                <input
                                  type="file"
                                  id="image"
                                  name="image1"
                                  className="mt-3 mb-3"
                                  onChange={(e) =>
                                    requestFileChange(e.target.files[0])
                                  }
                                  accept="image/png, image/jpeg"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  label="Title"
                                  name="title"
                                  value={rtitle}
                                  onChange={handleRequestChange("rtitle")}
                                  placeholder={
                                    STRING_CONSTANTS.TITLE_PLACEHOLDER
                                  }
                                  required
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  required
                                >
                                  <InputLabel>
                                    {STRING_CONSTANTS.UNIT}
                                  </InputLabel>
                                  <Select
                                    label="Unit"
                                    value={runit}
                                    onChange={handleRequestChange("runit")}
                                    name="unit"
                                  >
                                    <MenuItem value="">
                                      {STRING_CONSTANTS.SELECT_UNIT}
                                    </MenuItem>
                                    {STRING_CONSTANTS.UNIT_ARRAY.map(
                                      (unit, index) => (
                                        <MenuItem key={index} value={unit}>
                                          {unit}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  type="number"
                                  label="Quantity"
                                  name="quantity"
                                  value={rqty}
                                  placeholder={
                                    STRING_CONSTANTS.QUANTITY_PLACEHOLDER
                                  }
                                  onChange={handleRequestChange("rqty")}
                                  required
                                  error={qerror}  
                                  helperText={qerror ? "Quantity cannot be negative." : ""}  
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      label="Start Date"
                                      format="DD/MM/YYYY"
                                      defaultValue={dayjs(rstartDate)}
                                      onChange={handleStartDateChange}
                                      required
                                      minDate={dayjs()}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      label="End Date"
                                      format="DD/MM/YYYY"
                                      value={dayjs(rendDate)}
                                      name="endDate"
                                      required
                                      onChange={handleEndDateChange}
                                      minDate={dayjs(rstartDate).add(1, "day")}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                {isLoaded && (
                                  <StandaloneSearchBox
                                    onLoad={(ref) => (inputRef.current = ref)}
                                    onPlacesChanged={() =>
                                      handleOnPlacesChanged("buy")
                                    }
                                  >
                                    <TextField
                                      fullWidth
                                      label="Location"
                                      name="location"
                                      value={rlocation}
                                      onChange={handleRequestChange(
                                        "rlocation"
                                      )}
                                      placeholder="Search for a location"
                                      required
                                    />
                                  </StandaloneSearchBox>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  // label="Pincode"
                                  name="rpincode"
                                  value={rpincode}
                                  onChange={handleRequestChange("rpincode")}
                                  placeholder="Enter Pincode"
                                  required
                                />
                                {pincodeBError && (
    <span style={{ color: "red", fontSize: "12px" }}>{pincodeBError}</span>)}
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  // label="City"
                                  name="city"
                                  value={rcity}
                                  onChange={handleRequestChange("rcity")}
                                  placeholder="Enter City"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  // label="State"
                                  name="state"
                                  value={rstate}
                                  onChange={handleRequestChange("rstate")}
                                  placeholder="Enter State"
                                  required
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  label="Description"
                                  name="description"
                                  value={rdescription}
                                  onChange={handleRequestChange("rdescription")}
                                  placeholder={
                                    STRING_CONSTANTS.DESCRIPTION_PLACEHOLDER
                                  }
                                  required
                                />
                              </Grid>
                            </Grid>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                sx={{ mt: 2, mr: 1 }}
                                onClick={() => setBuyModal(false)}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2 }}
                                style={{ backgroundColor: "#769A0F" }}
                              >
                                {STRING_CONSTANTS.SUBMIT}
                              </Button>
                            </div>
                          </form>
                        </Box>
                      </Modal>
                    </div>
                    <div>
                      <style>
                        {`
                                  .pac-container {
                                    z-index: 2000 !important;
                                  }
                                `}
                      </style>
                      <Modal
                        open={sellModal}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "100px",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            width: "90vw",
                            maxHeight: "80vh",

                            backgroundColor: "white",
                            padding: "30px",
                            borderRadius: "8px",

                            boxShadow: 24,
                            outline: "none",
                            overflowY: "auto",
                          }}
                        >
                          <h3 style={{ marginBottom: 40 }}>
                            {STRING_CONSTANTS.ADD_SELL_PROD}
                          </h3>
                          <form onSubmit={createPost}>
                            <Grid container spacing={3}>
                              <div className="col-md-3 mb-3">
                                <div
                                  className="text-dark text-left font-size-16 mb-2"
                                  style={{ letterSpacing: "0.04rem" }}
                                >
                                  {STRING_CONSTANTS.IMAGE_1}
                                </div>

                                {img1 !== "" ? (
                                  <div>
                                    <img
                                      src={img1}
                                      alt=""
                                      height="150px"
                                      width="100%"
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src="/assets/icons/try.jpg"
                                    alt=""
                                    height="150px"
                                    width="100%"
                                    className=""
                                  />
                                )}

                                <div className="mt-3">
                                  <input
                                    type="file"
                                    id="image"
                                    onChange={onFileChange1}
                                    accept="image/png,  image/jpeg"
                                    className="mt-3 margin-top-10"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 mb-3">
                                <div
                                  className="text-dark text-left font-size-16 mb-2"
                                  style={{ letterSpacing: "0.04rem" }}
                                >
                                  {STRING_CONSTANTS.IMAGE_2}
                                </div>

                                {img2 !== "" ? (
                                  <div>
                                    <img
                                      src={img2}
                                      alt=""
                                      height="150px"
                                      width="100%"
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src="/assets/icons/try.jpg"
                                    alt=""
                                    height="150px"
                                    width="100%"
                                    className=""
                                  />
                                )}

                                <div className="mt-3">
                                  <input
                                    type="file"
                                    id="image"
                                    className="mt-3 margin-top-10"
                                    onChange={onFileChange2}
                                    accept="image/png,  image/jpeg"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 mb-3">
                                <div
                                  className="text-dark text-left font-size-16 mb-2"
                                  style={{ letterSpacing: "0.04rem" }}
                                >
                                  {STRING_CONSTANTS.IMAGE_3}
                                </div>

                                {img3 !== "" ? (
                                  <div>
                                    <img
                                      src={img3}
                                      alt=""
                                      height="150px"
                                      width="100%"
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src="/assets/icons/try.jpg"
                                    alt=""
                                    height="150px"
                                    width="100%"
                                    className=""
                                  />
                                )}

                                <div className="mt-3">
                                  <input
                                    type="file"
                                    id="image"
                                    className="mt-3 margin-top-10"
                                    onChange={onFileChange3}
                                    accept="image/png,  image/jpeg"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 mb-3">
                                <div
                                  className="text-dark text-left font-size-16 mb-2"
                                  style={{ letterSpacing: "0.04rem" }}
                                >
                                  {STRING_CONSTANTS.IMAGE_4}
                                </div>

                                {img4 !== "" ? (
                                  <div>
                                    <img
                                      src={img4}
                                      alt=""
                                      height="150px"
                                      width="100%"
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src="/assets/icons/try.jpg"
                                    alt=""
                                    height="150px"
                                    width="100%"
                                    className=""
                                  />
                                )}

                                <div className="mt-3">
                                  <input
                                    type="file"
                                    id="image"
                                    className="mt-3 margin-top-10"
                                    onChange={onFileChange4}
                                    accept="image/png,  image/jpeg"
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  label="Title"
                                  name="title"
                                  value={title}
                                  onChange={handleChange("title")}
                                  placeholder="Enter Title"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  multiple
                                  id="tags-outlined"
                                  options={keywordss}
                                  value={selectedKeyword}
                                  getOptionLabel={(option) => option.keywords}
                                  defaultValue={[]}
                                  filterSelectedOptions
                                  onChange={(event, value) => {
                                    if (
                                      value.some(
                                        (option) => option.keywords === "Other"
                                      )
                                    ) {
                                      handleModalOpen();
                                    } else {
                                      setSelectedKeyword(value);
                                      setShowKeywordAlert("");
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Keywords"
                                      placeholder="Select Your keyword"
                                    />
                                  )}
                                />
                              {showKeywordAlert&&(<span style={{ color: "red", fontSize: "12px" }}>{showKeywordAlert}</span>)}

                                <Modal
                                  open={showModal1}
                                  onClose={handleModalClose}
                                  aria-labelledby="simple-modal-title"
                                  aria-describedby="simple-modal-description"
                                >
                                  <div
                                    style={{
                                      padding: "20px",
                                      background: "white",
                                      width: "60%",
                                      margin: "100px auto",
                                      position: "absolute",
                                      top: "40%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                    }}
                                  >
                                    <h2>{STRING_CONSTANTS.ADD_KEYWORDS}</h2>
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                      <TextField
                                        label="Keyword"
                                        type="text"
                                        value={keyword}
                                        onChange={(e) =>
                                          setKeyword(e.target.value)
                                        }
                                        required
                                        style={{ marginTop: "20px" }}
                                      />
                                    </FormControl>
                                    <FormControl
                                      fullWidth
                                      sx={{ mb: 3 }}
                                      variant="outlined"
                                      required
                                    >
                                      <InputLabel id="language">
                                        {STRING_CONSTANTS.SELECT_LANGUAGE}
                                      </InputLabel>
                                      <Select
                                        label="language"
                                        value={selectedLanguage}
                                        onChange={(e) =>
                                          setSelectedLanguage(e.target.value)
                                        }
                                        required
                                      >
                                        <MenuItem value="">
                                          <em>
                                            {STRING_CONSTANTS.SELECT_LANGUAGE}
                                          </em>
                                        </MenuItem>
                                        {activeLanguages.map((lang) => (
                                          <MenuItem
                                            key={lang._id}
                                            value={lang.language}
                                          >
                                            {lang.language}-{lang.code}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <Grid
                                      container
                                      justifyContent="flex-end"
                                      sx={{ mt: 3 }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={handleModalClose}
                                        sx={{ mt: 2, mr: 1 }}
                                      >
                                        {STRING_CONSTANTS.CLOSE}
                                      </Button>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                        sx={{ mt: 2 }}
                                        style={{
                                          backgroundColor: "#0BAAE5",
                                        }}
                                        onClick={handleModalSubmit}
                                      >
                                        {STRING_CONSTANTS.ADD}
                                      </Button>
                                    </Grid>
                                  </div>
                                </Modal>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  required
                                >
                                  <InputLabel>
                                    {STRING_CONSTANTS.UNIT}
                                  </InputLabel>
                                  <Select
                                    label="Unit"
                                    value={unit}
                                    onChange={handleChange("unit")}
                                    name="unit"
                                  >
                                    <MenuItem value="">
                                      {STRING_CONSTANTS.SELECT_UNIT}
                                    </MenuItem>
                                    {STRING_CONSTANTS.UNIT_ARRAY.map(
                                      (unit, index) => (
                                        <MenuItem key={index} value={unit}>
                                          {unit}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  type="number"
                                  label="Quantity"
                                  name="qty"
                                  value={qty}
                                  placeholder={
                                    STRING_CONSTANTS.QUANTITY_PLACEHOLDER
                                  }
                                  onChange={handleChange("qty")}
                                  error={!!errors.qty} // Set error prop if there is an error
                                  helperText={errors.qty}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  type="number"
                                  name="price"
                                  label="Price"
                                  value={price}
                                  onChange={handleChange("price")}
                                  error={!!errors.price}
                                  helperText={errors.price}
                                  required
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                {isLoaded && (
                                  <StandaloneSearchBox
                                    onLoad={(ref) => (inputRef.current = ref)}
                                    onPlacesChanged={() =>
                                      handleOnPlacesChanged("sell")
                                    }
                                  >
                                    <TextField
                                      fullWidth
                                      label="Location"
                                      name="location"
                                      value={location}
                                      onChange={handleChange("location")}
                                      required
                                      placeholder="Search for a location"
                                    />
                                  </StandaloneSearchBox>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  label="Pincode"
                                  name="pincode"
                                  value={pincode}
                                  onChange={handleChange("pincode")}
                                  placeholder="Enter Pincode"
                                 
                                />
                                 {pincodeError && (
    <span style={{ color: "red", fontSize: "12px" }}>{pincodeError}</span>
  )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  label="City"
                                  name="city"
                                  value={city}
                                  onChange={handleChange("city")}
                                  placeholder="Enter City"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  label="State"
                                  name="state"
                                  value={state}
                                  onChange={handleChange("state")}
                                  placeholder="Enter State"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  fullWidth
                                  label="Description"
                                  name="description"
                                  value={description}
                                  placeholder="Enter Description"
                                  onChange={handleChange("description")}
                                  required
                                />
                              </Grid>
                              <Grid
                                style={{ marginTop: "-10px" }}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      label=" Available Date "
                                      format="DD/MM/YYYY"
                                      defaultValue={dayjs(availableDate)}
                                      name="availableDate"
                                      onChange={handleSellStartDateChange}
                                      required
                                      minDate={dayjs()}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              <Grid
                                style={{ marginTop: "-10px" }}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      label=" Expiry Date"
                                      format="DD/MM/YYYY"
                                      defaultValue={dayjs(expdate)}
                                      name="expdate"
                                      onChange={handleSellEndDateChange}
                                      required
                                      minDate={dayjs(availableDate).add(
                                        1,
                                        "day"
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={auction_status === "1"}
                                        onChange={handleCheckBoxChange(
                                          "auction_status",
                                          "1"
                                        )}
                                        color="primary"
                                        disabled={ecommerce_status === "1"}
                                      />
                                    }
                                    label="For Auction"
                                    sx={{ marginLeft: 3 }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={ecommerce_status === "1"}
                                        onChange={handleCheckBoxChange(
                                          "ecommerce_status",
                                          "1"
                                        )}
                                        color="primary"
                                        disabled={auction_status === "1"}
                                      />
                                    }
                                    label="For Ecommerce"
                                    sx={{ marginLeft: 3 }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={organic_status === "1"}
                                        onChange={handleCheckBoxChange(
                                          "organic_status",
                                          "1"
                                        )}
                                        color="primary"
                                      />
                                    }
                                    label="Organic"
                                    sx={{ marginLeft: 3 }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={safe_status === "1"}
                                        onChange={handleCheckBoxChange(
                                          "safe_status",
                                          "1"
                                        )}
                                        color="primary"
                                        disabled={organic_status === "1"}
                                      />
                                    }
                                    label="Safe To Eat"
                                    sx={{ marginLeft: 3 }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={transpot_status === "1"}
                                        onChange={handleCheckBoxChange(
                                          "transpot_status",
                                          "1"
                                        )}
                                        color="primary"
                                      />
                                    }
                                    label="Transport Available"
                                    sx={{ marginLeft: 3 }}
                                  />
                                </Grid>
                                {transpot_status === "1" && (
                                  <>
                                    <Grid item xs={12} md={4}>
                                      <TextField
                                        fullWidth
                                        label="Free Delivery Upto KM"
                                        value={freeDelivery}
                                        placeholder="Enter KM"
                                        onChange={(e) =>
                                          setValues({
                                            ...values,
                                            freeDelivery: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <TextField
                                        fullWidth
                                        label="Charges after KM"
                                        value={chargesAfter}
                                        placeholder="Enter Price"
                                        onChange={(e) =>
                                          setValues({
                                            ...values,
                                            chargesAfter: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={handleShowClose1}
                                sx={{ mt: 2, mr: 1 }}
                              >
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2, mr: 1 }}
                                style={{
                                  backgroundColor: "#769A0F",
                                  marginRight: "10px",
                                }}
                              >
                                {STRING_CONSTANTS.SUBMIT}
                              </Button>
                            </div>
                          </form>
                        </Box>
                      </Modal>
                    </div>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  page={page}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  component="div"
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowPageChange}
                ></TablePagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
