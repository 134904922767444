import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import AddIcon from "@mui/icons-material/Add";
import { IoSearch } from "react-icons/io5";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Paper,
  Modal,
  Fade,
  Box,
} from "@mui/material";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ADD_EMPLOYEE, BLOCK, EDIT, UNBLOCK } from "../../constants/url";
import { FiEdit2 } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { privateApiCall } from "../../api/privateApi";
import {
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  ALERT_ICON_SUCC,
  BLOCK_EMPLOYEE,
  BLOCKED_EMPLOYEE,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  SUCC_ALERT_TITLE,
  SUCC_EMP_ADDED,
  UNBLOCK_EMPLOYEE,
  UNBLOCKED_EMPLOYEE,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();

const Employees = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [employee, setEmployee] = useState(null);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    department: "",
  });

  const [employees, setEmployees] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const org_token = cookies.get("org_token");

  const getEmployees = async () => {
    try {
      const requestUrl = `${ADD_EMPLOYEE}/getEmployees`;

      const response = await privateApiCall(requestUrl, "GET");

      const employeesWithSno = response.data.map((employee, index) => {
        return {
          ...employee,
          sno: index + 1,
        };
      });

      setEmployees(employeesWithSno);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  const handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${ADD_EMPLOYEE}/searchEmployee?query=${searchValue}`;
      const response = await privateApiCall(requestUrl, "GET");

      if (response.data && Array.isArray(response.data)) {
        const employeesWithSno = response.data.map((employee, index) => {
          return {
            ...employee,
            sno: index + 1,
          };
        });

        setEmployees(employeesWithSno);
      } else {
        setEmployees([]); // Clear employees if the response is invalid
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);
  const handleClose = () => {
    setValues({
      fname: "",
      lname: "",
      mobile: "",
      department: "",
      email: "",
    });
    setShow(false);
    setEmployee(null);
  };
  const Actions = ({ row }) => {
    const handleEditShow = () => {
      setShow(true);
      setValues({
        fname: row.fname,
        lname: row.lname,
        mobile: row.mobile,
        department: row.department,
        email: row.email,
        _id: row._id,
      });
      setEmployee(row._id);
    };

    const handleBlock = () => {
      Swal.fire({
        title: `${BLOCK_EMPLOYEE} ${row.fname} ${row.lname} ?`,
        icon: "question",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const requestUrl = `${ADD_EMPLOYEE}/blockEmployee?employeeId=${row._id}`;

          privateApiCall(requestUrl, "POST", `${row._id}`).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: `${BLOCKED_EMPLOYEE}`,
                text: response.data.message,
                icon: "success",
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
              getEmployees();
            }
          });
        }
      });
    };

    const handleUnblock = () => {
      Swal.fire({
        title: `${UNBLOCK_EMPLOYEE}${row.fname} ${row.lname}?`,
        icon: "question",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const requestUrl = `${ADD_EMPLOYEE}/unblockEmployee?employeeId=${row._id}`;
          privateApiCall(requestUrl, "POST", `${row._id}`).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: `${UNBLOCKED_EMPLOYEE}`,
                text: response.data.message,
                icon: "success",
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
              getEmployees();
            }
          });
        }
      });
    };

    return (
      <span>
        {row.block ? (
          <span>
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgProfileEmployee" && item.isEdit
              )) && (
              <>
                <i
                  className="mr-2"
                  onClick={handleEditShow}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={`${EDIT}`}
                >
                  <FiEdit2 size="18px" color="#00C5B2" />
                </i>
              </>
            )}

            {(permissions === "All" ||
              permissions.some(
                (item) =>
                  item.keyword === "orgProfileEmployee" && item.isUnblock
              )) && (
              <>
                <i
                  className="ml-2"
                  onClick={handleUnblock}
                  data-tooltip-id="tooltip"
                  data-tooltip-content={`${UNBLOCK}`}
                >
                  <Tooltip id="tooltip" style={{ fontStyle: "normal" }} />
                  <FaRegThumbsUp size="18px" color="green" />
                </i>
              </>
            )}
          </span>
        ) : (
          <span>
            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgProfileEmployee" && item.isEdit
              )) && (
              <>
                <i
                  className="mr-2"
                  onClick={handleEditShow}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={`${EDIT}`}
                >
                  <Tooltip id="my-tooltip" style={{ fontStyle: "normal" }} />
                  <FiEdit2 size="18px" color="#00C5B2" />
                </i>
              </>
            )}

            {(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgProfileEmployee" && item.isBlock
           
                
              )) && (
              <>
                <i
                  className="ml-2"
                  onClick={handleBlock}
                  data-tooltip-id="tool"
                  data-tooltip-content={`${BLOCK}`}
                >
                  <Tooltip id="tool" style={{ fontStyle: "normal" }} />
                  <FaRegThumbsDown size="18px" color="red" />
                </i>
              </>
            )}
          </span>
        )}
      </span>
    );
  };

  const departments = STRING_CONSTANTS.DEPARTMENTA_ARRY;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestUrl = `${ADD_EMPLOYEE}/addEmployee?token=${org_token}`;

      const response = await privateApiCall(requestUrl, "POST", values);

      if (response.status === 200) {
        handleClose(false);
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          text: `${SUCC_EMP_ADDED}`,
          icon: `${ALERT_ICON_SUCC}`,
        });

        getEmployees();
        setValues({
          fname: "",
          lname: "",
          mobile: "",
          department: "",
          email: "",
          _id: "",
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: error.response.data.message,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
        setValues({
          fname: "",
          lname: "",
          mobile: "",
          department: "",
          email: "",
          _id: "",
        });
      }
      handleClose(false);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = { ...values, _id: values._id };

      const requestUrl = `${ADD_EMPLOYEE}/updateEmployee?token=${org_token}`;

      const response = await privateApiCall(requestUrl, "POST", formData);

      if (response.status === 200) {
        handleClose1(false);
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.message,
          icon: `${ALERT_ICON_SUCC}`,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
        getEmployees();
        setValues({
          fname: "",
          lname: "",
          mobile: "",
          department: "",
          email: "",
          _id: "",
        });
        handleClose(false);
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
      });
    }
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar type="profiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.EMPLOY_LIST}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.EMPLOY_LIST}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div
                    className="row container-fluid"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >

{(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgProfileEmployee" && item.isCreate
              )) && (
              <>
               <button
                      className="btn  ml-2 mb-2"
                      style={{
                        float: "left",
                        backgroundColor: "#0baae5",
                        color: "#fff",
                      }}
                      onClick={handleShow}
                    >
                      <AddIcon style={{ marginRight: "5px" }} />
                      {STRING_CONSTANTS.ADD_EMPLOYEE}
                    </button>
              </>
            )}


{(permissions === "All" ||
              permissions.some(
                (item) => item.keyword === "orgProfileEmployee" && item.isSearch
              )) && (
              <>
               <div style={{ position: "relative", marginLeft: "auto" }}>
                      <input
                        type="text"
                        placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                        className="form-control"
                        style={{
                          width: "200px",
                          paddingLeft: "35px",
                          boxSizing: "border-box",
                          marginLeft: "auto",
                        }}
                        onChange={(e) => {
                          handleSearch(e);
                          if (e.target.value.trim() === "") {
                            e.target.nextElementSibling.style.display =
                              "inline-block";
                          } else {
                            e.target.nextElementSibling.style.display = "none";
                          }
                        }}
                      />
                      <IoSearch
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
              </>
            )}
                   
                 

                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.FIRST_NAME}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.LAST_NAME}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.MOBLE_NUM}
                            </TableCell>

                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.EMAIL}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.DEPARTMENT}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.STATUS}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.ACTIONS}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {employees.map((row) => (
                            <TableRow key={row._id}>
                              <TableCell align="center">{row.sno}</TableCell>
                              <TableCell align="center">{row.fname}</TableCell>
                              <TableCell align="center">{row.lname}</TableCell>
                              <TableCell align="center">{row.mobile}</TableCell>
                              <TableCell align="center">{row.email}</TableCell>
                              <TableCell align="center">
                                {row.department}
                              </TableCell>
                              <TableCell align="center">
                                {" "}
                                {row.block === true ? (
                                  <span className="btn-sm btn-danger ">
                                    {STRING_CONSTANTS.INACTIVE}
                                  </span>
                                ) : (
                                  <span className="btn-sm btn-success">
                                    {STRING_CONSTANTS.ACTIVE}
                                  </span>
                                )}{" "}
                              </TableCell>
                              <TableCell align="center">
                                <Actions row={row} />
                              </TableCell>
                            </TableRow>
                          ))}

                          <Modal
                            open={show}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropProps={{
                              style: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              },
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            disableBackdropClick
                          >
                            <Fade in={show}>
                              <Box
                                sx={{
                                  bgcolor: "background.paper",
                                  boxShadow: 1,
                                  p: 4,
                                  borderRadius: 2,
                                  width: "60%",
                                  marginBottom: "50px",
                                }}
                              >
                                <h4>
                                  {employee ? "Edit Employee" : "Add Employee"}
                                </h4>
                                <form
                                  onSubmit={
                                    employee ? handleEditSubmit : handleSubmit
                                  }
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="First Name"
                                        variant="outlined"
                                        name="fname"
                                        value={values.fname}
                                        onChange={handleChange}
                                        placeholder={
                                          STRING_CONSTANTS.NAME_PLACEHOLDER
                                        }
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Last Name"
                                        variant="outlined"
                                        name="lname"
                                        value={values.lname}
                                        onChange={handleChange}
                                        placeholder={
                                          STRING_CONSTANTS.DISPLAY_NAME_PLACEHOLDER
                                        }
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormControl
                                        fullWidth
                                        variant="outlined"
                                        required
                                      >
                                        <InputLabel>
                                          {STRING_CONSTANTS.DEPARTMENT}
                                        </InputLabel>
                                        <Select
                                          label="department"
                                          name="department"
                                          value={values.department}
                                          onChange={handleChange}
                                        >
                                          <MenuItem value="">
                                            <em>
                                              {
                                                STRING_CONSTANTS.SELECT_DEPARTMENT
                                              }
                                            </em>
                                          </MenuItem>
                                          {departments.map(
                                            (department, index) => (
                                              <MenuItem
                                                key={index}
                                                value={department}
                                              >
                                                {department}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Mobile Number"
                                        variant="outlined"
                                        name="mobile"
                                        value={values.mobile}
                                        onChange={handleChange}
                                        placeholder={
                                          STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER
                                        }
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Email"
                                        variant="outlined"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder={
                                          STRING_CONSTANTS.EMAIL_PLACEHOLDER
                                        }
                                        required
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="flex-end"
                                    style={{
                                      marginTop: "40px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={handleClose}
                                      style={{ marginRight: "10px" }}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        backgroundColor: "#0baae5",
                                        color: "#fff",
                                      }}
                                    >
                                      {employee ? "Update" : "Submit"}
                                    </Button>
                                  </Grid>
                                </form>
                              </Box>
                            </Fade>
                          </Modal>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employees;
