import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { ORG_CUSTOMER } from "../../constants/url";
import { FiEye } from "react-icons/fi";
import { FaRegCopy } from "react-icons/fa";
import { toast, Bounce } from "react-toastify";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { privateApiCall } from "../../api/privateApi";
import { formatDate } from "../../utils/dateUtils";
import ShareIcon from "@mui/icons-material/Share";
import {
  Table,
  MenuItem,
  Select,
  Typography,
  Badge,
  FormControlLabel,
  Checkbox,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Modal,
  Grid,
  TextField,
  Fade,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import {
  ALERT_BUTTON_NO,
  COPIED_TO_CLIPBOARD,
  DELETE_YES_BUTTON,
  DO_YOU_WANT_TO_DELETE,
  DO_YOU_WANT_TO_DELETE_BILLILNG_ADDRESS,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const cookies = new Cookies();
const Subscription = () => {
  const [value, setValue] = useState("1");
  const [subscription, setSubscription] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [allSubscription, setAllSubscription] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [isPrimaryEmail, setIsPrimaryEmail] = useState(false);
  const [showPrimaryConfirmation, setShowPrimaryConfirmation] = useState(false);
  const [showAddPrimaryConfirmation, setShowAddPrimaryConfirmation] =
    useState(false);
  const [voucher, setVoucher] = useState("");
  const [data, setData] = useState({
    recipientEmail: "",
    recipientName: "",
    designation: "",
  });
  const [newaddress, setNewaddress] = useState({
    Address: "",
    area: "",
    locality: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [opens, setOpens] = useState(false);
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [customers, setCustomers] = useState([]);
  const handleOpen = (code) => {
    setSelectedCode(code);
    setOpens(true);
  };
  const handleCloses = () => {
    setOpens(false);
    setSelectedCode("");
    setSelectedUser("");
  };

  const handleAssign = async () => {
    const selectedCustomer = customers.find(customer => customer.userId === selectedUser);
   
    const currentSubscriptionName = selectedCustomer ? selectedCustomer.subscription_name : "unknown";
 
    setOpens(false);
    const result = await Swal.fire({
      title: "Confirm Subscription Change",
      text: `This user currently has the "${currentSubscriptionName}" subscription. Do you want to assign the "${selectedVoucherData.subscription_name}" subscription instead?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, assign",
      cancelButtonText: "Cancel",
    });
    
    if (!result.isConfirmed) {
      return;  
    }
  
    const requestUrl = "/api/organisation/assignVoucherCode";
     
    const payload = {
      userId: selectedUser,
      subscription_id: selectedVoucherData.subscription_id,
      voucher_id: selectedVoucher,
      voucher_code: selectedCode,
    };
     
    const response = await privateApiCall(requestUrl, "POST", payload);
     
    getUnassignedUsers(payload.voucher_id);
    fetchVoucher();
    toast.success(response.data.msg, {
      position: "top-right",
      transition: Bounce,
    });

    handleCloses();
  };

  // const customerData = async () => {
  //   const requestUrl = `${ORG_CUSTOMER}?_id=${cookies.get(
  //     "org_id"
  //   )}&org_name=${cookies.get("org_name")}`;
  //   const response = await privateApiCall(requestUrl, "GET");
  //   console.log(response,"customer response data")
  //   setCustomers(response.data);
  // };

  const [recipientId, setRecipientId] = useState(null);
  const [orgEmail, setOrgEmail] = useState(null);
  const [orgAddress, setOrgAddress] = useState({
    Address: "",
    locality: "",
    area: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    landmark: "",
  });
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [address, setAddress] = useState([]);
  const [isPrimaryAddress, setIsPrimaryAddress] = useState(false);
  const [primaryAddress, setPrimaryAddress] = useState("");
  const [addressId, setAddressId] = useState(null);
  const handleShowModal = () => setShowModal(true);
  const handleShowModalAdd = () => setShowModalAdd(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setData({
      recipientEmail: "",
      recipientName: "",
      designation: "",
    });
    setRecipientId(null);
  };
  const handleCloseModalAdd = () => {
    setShowModalAdd(false);
    setNewaddress({
      Address: "",
      area: "",
      locality: "",
      landmark: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    });
    setAddressId(null);
  };

  const handleTabChange = (e, newValue) => {
    setValue(newValue);
    setShowPromotionalCredits(false);
    setShowBalancedCredits(false);
    setShowUsedCredits(false);
    setSelectedVoucher("");
    setUsedVoucher("");
  };

  const fetchSubscription = async () => {
    try {
      const requestUrl = `/api/organisation/getSubscription`;
      const response = await privateApiCall(requestUrl, "GET");
      const combinedSubscriptions = [
        response.data.data,
        ...response.data.allSubscriptions,
      ];
      setSubscriptions(combinedSubscriptions);
      // setSubscription(response.data.data);
      // setAllSubscription(response.data.allSubscriptions);
    } catch (error) {
      console.error(error);
    }
  };

  // const [voucherCount, setVocherCount] = useState("");
  const [showPromotionalCredits, setShowPromotionalCredits] = useState(false);
  const [showBalancedCredits, setShowBalancedCredits] = useState(false);
  const [showUsedCredits, setShowUsedCredits] = useState(false);
  const [showExpCredits, setShowExpCredits] = useState(false);
  const [expiredCredits, setExpiredCredits] = useState("");
  const [userName, setUserName] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const fetchVoucher = async () => {
    const requestUrl = "/api/organisation/getMyVocher";
    try {
      const response = await privateApiCall(requestUrl, "GET");

      if (response.data.vouchers && response.data.vouchers.length > 0) {
        setVoucher(response.data.vouchers);
        // setVocherCount(response.data.vouchers.length);
        const userNamesAndCodes = response.data.vouchers
          .flatMap((voucher) =>
            voucher.assignedUserId.map((user) => ({
              userName: user.userName,
              voucherCode: user.userCode,
            }))
          )
          .filter((user) => user.userName);
        const userNames = userNamesAndCodes
          .map((user) => user.userName)
          .join(", ");
        const userCodes = userNamesAndCodes
          .map((user) => user.voucherCode)
          .join(", ");

        setUserName(userNames);
        setVoucherCode(userCodes);
      } else {
        setVoucher([]);
        // setVocherCount(0);
      }
    } catch (error) {
      console.error("Error fetching vouchers:", error);
    }
  };

  const [selectedVoucher, setSelectedVoucher] = useState("");

  const handleVoucherChange = async (event) => {
    console.log(event.target.value, "voucher id..");
    const voucherId = event.target.value;
    setSelectedVoucher(voucherId);

    await getUnassignedUsers(voucherId);
  };
  const [usedVoucher, setUsedVoucher] = useState("");
  const handleUsedVoucher = (e) => {
    setUsedVoucher(e.target.value);
  };

  const selectedVoucherData =
    voucher && voucher.length
      ? voucher.find((v) => v.voucher_id === selectedVoucher)
      : null;
  const useVoucherData =
    voucher && voucher.length
      ? voucher.find((v) => v.voucher_id === usedVoucher)
      : null;

  const handlePromotionalCredits = async () => {
    setShowBalancedCredits(false);
    setShowUsedCredits(false);
    setShowPromotionalCredits(true);
    setShowExpCredits(false);
    setSelectedVoucher("");
    setUsedVoucher("");
  };
  const getUnassignedUsers = async (selectedVoucher) => {
    try {
      const requestUrl = `/api/organisation/unassignedUser?voucherId=${selectedVoucher}&orgId=${cookies.get(
        "org_id"
      )}`;
      const response = await privateApiCall(requestUrl, "GET");
      console.log(response, "unassigned users response");

      if (response.data.status) {
        setCustomers(response.data.users);
      } else {
        console.log("No unassigned users found.");
      }
    } catch (error) {
      console.error("Error fetching unassigned users:", error);
    }
  };
  console.log(customers,"customersss")

  const handleBalancedCredits = () => {
    setShowPromotionalCredits(false);
    setShowUsedCredits(false);
    setShowExpCredits(false);
    setShowBalancedCredits(true);
    setUsedVoucher("");
  };
  const handleUsedCredits = () => {
    setShowPromotionalCredits(false);
    setShowBalancedCredits(false);
    setShowUsedCredits(true);
    setShowExpCredits(false);
  };
  const handleExpiredCredits = () => {
    setShowExpCredits(true);
    setShowPromotionalCredits(false);
    setShowBalancedCredits(false);
    setShowUsedCredits(false);
  };
  const [copyModal, setCopyModal] = useState(false);
  const [copyCode, setCopyCode] = useState("");
  const handleCopyCode = (code) => {
    setCopyCode(code);

    setCopyModal(true);
  };
  const handleClosed = () => {
    setCopyModal(false);
  };
  const handleCopyClick = (code) => {
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopyModal(false);
        toast.info(`${COPIED_TO_CLIPBOARD}`, {
          position: "top-right",
          transition: Bounce,
        });
      })
      .catch((error) => {
        toast.error("Failed to copy code.", {
          position: "top-right",
          transition: Bounce,
        });
      });
  };
  

  const isVoucherActive = (fromDate, endDate) => {
    console.log(fromDate, endDate, "ooooo");
    const currentDate = new Date().toLocaleDateString("en-GB");
    if (currentDate > endDate) {
      return false;
    }
    return true;
  };

  const get_Profile = async () => {
    try {
      const requestUrl = `/api/organisation/get_profile/${cookies.get(
        "org_id"
      )}?token=${cookies.get("org_token")}`;
      const response = await privateApiCall(requestUrl, "GET");
      return response.data.admin[0];
    } catch (error) {}
  };

  useEffect(() => {
    fetchSubscription();
    fetchData();
    fetchVoucher();
  }, []);
  const fetchData = async () => {
    const orgAdminData = await get_Profile();
    setOrgEmail(orgAdminData.email);
    const currentAddress = {
      Address: orgAdminData.Address,
      locality: orgAdminData.locality,
      area: orgAdminData.area,
      city: orgAdminData.city,
      state: orgAdminData.state,
      country: orgAdminData.country,
      pincode: orgAdminData.pincode,
      landmark: orgAdminData.landmark,
    };

    setOrgAddress(currentAddress);
    const recipiente = orgAdminData.recipients;
    const addres = orgAdminData.address;

    const hasPrimaryRecipient = recipiente.some(
      (recipient) => recipient.isPrimary
    );
    setRecipients([
      {
        recipientEmail: orgAdminData.email,
        recipientName: "",
        designation: "",
        isPrimary: hasPrimaryRecipient ? false : true,
      },
      ...recipiente,
    ]);
    const hasPrimaryAddress = addres.some(
      (recipient) => recipient.isPrimaryAddress
    );
    setAddress([
      {
        Address: orgAdminData.Address,
        area: orgAdminData.area,
        locality: orgAdminData.locality,
        landmark: orgAdminData.landmark,
        city: orgAdminData.city,
        state: orgAdminData.state,
        country: orgAdminData.country,
        pincode: orgAdminData.pincode,
        isPrimaryAddress: hasPrimaryAddress ? false : true,
      },
      ...addres,
    ]);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const requestUrl = "/api/organisation/addRecipient";
      const payload = {
        ...data,
        isPrimaryEmail: isPrimaryEmail,
      };
      const response = await privateApiCall(requestUrl, "POST", payload);
      handleCloseModal();
      fetchData();

      if (isPrimaryEmail) {
        const updatedRecipients = recipients.map((recipient) => {
          if (recipient.recipientEmail === data.recipientEmail) {
            return { ...recipient, isPrimary: true };
          }
          return { ...recipient };
        });
        setPrimaryEmail(data.recipientEmail);
        setRecipients(updatedRecipients);
        toast.info(response.data.message, {
          position: "top-right",
          transition: Bounce,
        });
      } else {
        toast.info(response.data.message, {
          position: "top-right",
          transition: Bounce,
        });
        fetchData();
      }
    } catch (error) {
      console.error("Error adding recipient:", error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      const requestUrl = `/api/organisation/updateRecipient/${recipientId}`;
      const payload = {
        recipientEmail: data.recipientEmail,
        recipientName: data.recipientName,
        designation: data.designation,
        isPrimary: isPrimaryEmail,
      };
      const response = await privateApiCall(requestUrl, "PUT", payload);

      handleCloseModal();
      fetchData();
      toast.info(response.data.message, {
        position: "top-right",
        transition: Bounce,
      });

      if (isPrimaryEmail) {
        const updatedRecipients = recipients.map((recipient) =>
          recipient._id === recipientId
            ? { ...recipient, isPrimary: true }
            : recipient
        );
        setRecipients(updatedRecipients);
      }
    } catch (error) {
      console.error("Error updating recipient:", error);
    }
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsPrimaryEmail(isChecked);
    if (isChecked) {
      setShowPrimaryConfirmation(true);
    }
  };

  const handlePrimaryConfirmationClose = (confirm) => {
    setShowPrimaryConfirmation(false);
    if (confirm) {
      setPrimaryEmail(data.recipientEmail);

      const updatedRecipients = recipients.map((recipient) => {
        if (recipient.recipientEmail === data.recipientEmail) {
          return { ...recipient, isPrimary: false };
        }

        return { ...recipient, isPrimary: false };
      });

      setRecipients(updatedRecipients);
    } else {
      setIsPrimaryEmail(false);
    }
  };

  const Action = ({ row }) => {
    const isPrimaryOrLoginEmail =
      row.isPrimary || (orgEmail && row.recipientEmail === orgEmail);
    const handleEditShow = (e) => {
      setRecipientId(row._id);
      setShowModal(true);
      setData({
        recipientEmail: row.recipientEmail,
        recipientName: row.recipientName,
        designation: row.designation,
      });
      setIsPrimaryEmail(row.isPrimary);
    };

    const handleDelete = async () => {
      try {
        const result = await Swal.fire({
          title: `${DO_YOU_WANT_TO_DELETE} ${row.recipientEmail}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: " #3085d6",
          confirmButtonText: `${DELETE_YES_BUTTON}`,
          cancelButtonText: `${ALERT_BUTTON_NO}`,
        });
        if (result.isConfirmed) {
          const requestUrl = `/api/organisation/del/${row._id}`;
          const response = await privateApiCall(requestUrl, "DELETE", {});
          fetchData();
          toast.error(response.data.message, {
            position: "top-right",
            transition: Bounce,
          });
        }
      } catch (error) {
        console.error("Error deleting recipient:", error);
      }
    };

    return (
      <span>
        {!isPrimaryOrLoginEmail && (
          <>
            <i
              data-tooltip-id="1"
              data-tooltip-content="Edit"
              onClick={handleEditShow}
            >
              <FiEdit2 size="18px" color="#00C5B2" />
            </i>
            <Tooltip id="1" style={{ fontStyle: "normal" }} />
            <i onClick={handleDelete}>
              <FiTrash2
                size="18px"
                color="red"
                data-tooltip-id="3"
                data-tooltip-content="Delete"
                style={{ marginLeft: "10px", cursor: "pointer" }}
              />
              <Tooltip id="3" style={{ fontStyle: "normal" }} />
            </i>
          </>
        )}
        {isPrimaryOrLoginEmail && (
          <Typography color="gray" variant="body2">
            <FiEdit2
              size="18px"
              color="#00C5B2"
              data-tooltip-id="2"
              data-tooltip-content="Disabled"
              style={{ cursor: "not-allowed" }}
            />
            <FiTrash2
              size="18px"
              color="red"
              data-tooltip-id="2"
              data-tooltip-content="Disabled"
              style={{ marginLeft: "10px", cursor: "not-allowed" }}
            />
            <Tooltip id="2" style={{ fontStyle: "normal" }} />
          </Typography>
        )}
      </span>
    );
  };

  const invoice = {
    invoiceNo: "#VL25000351",
    date: "27 Mar, 2021 9:58 PM",
    paymentStatus: "Paid",
    totalAmount: "$755.96",
    billingAddress: {
      name: "David Nichols",
      address: "305 S San Gabriel Blvd",
      phone: "(123) 456-7890",
    },
    shippingAddress: {
      name: "David Nichols",
      address: "305 S San Gabriel Blvd",
      phone: "(123) 456-7890",
    },
    legalRegistrationNo: "987654",
    email: "velzon@themesbrand.com",
    website: "www.themesbrand.com",
    contactNo: "0123456789",
  };

  const handleSubmitAddress = async (event) => {
    event.preventDefault();
    try {
      const requestUrl = "/api/organisation/addNewAddress";
      const payload = {
        ...newaddress,
        isPrimaryAddress: isPrimaryAddress,
      };

      const response = await privateApiCall(requestUrl, "POST", payload);
      handleCloseModalAdd();
      fetchData();
      if (isPrimaryAddress) {
        const updatedAddresses = address.map((add) =>
          add.isPrimaryAddress ? { ...add, isPrimaryAddress: false } : add
        );

        setPrimaryAddress(newaddress);
        setAddress(updatedAddresses);
      } else {
        fetchData();
      }
    } catch (error) {}
  };
  const handleUpdateAddress = async (event) => {
    event.preventDefault();
    try {
      const requestUrl = `/api/organisation/updateAddress/${addressId}`;
      const payload = {
        ...newaddress,
        isPrimaryAddress: isPrimaryAddress,
      };

      const response = await privateApiCall(requestUrl, "PUT", payload);
      handleCloseModalAdd();
      fetchData();
      if (isPrimaryAddress) {
        const updatedAddress = address.map((addr) =>
          addr._id === addressId
            ? { ...address, isPrimaryAddress: true }
            : address
        );
        setAddress(updatedAddress);
      }
    } catch (error) {}
  };

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setNewaddress((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsPrimaryAddress(isChecked);
    if (isChecked) {
      setShowAddPrimaryConfirmation(true);
    }
  };

  const handleAddPrimaryConfirmation = (confirm) => {
    setShowAddPrimaryConfirmation(false);
    if (confirm) {
      setPrimaryAddress(newaddress);
    }
  };

  const sortedAddresses = address.sort(
    (a, b) => b.isPrimaryAddress - a.isPrimaryAddress
  );
  const sortedRecipients = recipients.sort((a, b) => (b.isPrimary ? 1 : -1));
  const AddressAction = ({ row }) => {
    const fieldsMatch = (address1, address2) => {
      return (
        address1.Address === address2.Address &&
        address1.area === address2.area &&
        address1.locality === address2.locality &&
        address1.landmark === address2.landmark &&
        address1.city === address2.city &&
        address1.state === address2.state &&
        address1.country === address2.country &&
        address1.pincode === address2.pincode
      );
    };
    const disableAddress =
      row.isPrimaryAddress || (orgAddress && fieldsMatch(row, orgAddress));
    const handleEditAdd = (e) => {
      try {
        setAddressId(row._id);
        setShowModalAdd(true);
        setNewaddress({
          Address: row.Address,
          area: row.area,
          locality: row.locality,
          landmark: row.landmark,
          city: row.city,
          state: row.state,
          country: row.country,
          pincode: row.pincode,
        });
        setIsPrimaryAddress(row.isPrimaryAddress);
      } catch (error) {
        console.error(error);
      }
    };
    const handleDeleteAdd = async () => {
      try {
        const result = await Swal.fire({
          title: `${DO_YOU_WANT_TO_DELETE_BILLILNG_ADDRESS}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: " #3085d6",
          confirmButtonText: `${DELETE_YES_BUTTON}`,
          cancelButtonText: `${ALERT_BUTTON_NO}`,
        });
        if (result.isConfirmed) {
          const requestUrl = `/api/organisation/delAddress/${row._id}`;
          const response = await privateApiCall(requestUrl, "DELETE", {});
          fetchData();
        }
      } catch (error) {
        console.error(error);
      }
    };
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {!disableAddress && (
          <>
            <i
              data-tooltip-id="1"
              data-tooltip-content="Edit"
              onClick={handleEditAdd}
              style={{ marginRight: "10px", cursor: "pointer" }}
            >
              <FiEdit2 size="18px" color="#00C5B2" />
            </i>
            <Tooltip id="1" style={{ fontStyle: "normal" }} />
            <i
              data-tooltip-id="3"
              data-tooltip-content="Delete"
              onClick={handleDeleteAdd}
              style={{ cursor: "pointer" }}
            >
              <FiTrash2 size="18px" color="#FF0000" />
              <Tooltip id="3" style={{ fontStyle: "normal" }} />
            </i>
          </>
        )}
        {disableAddress && (
          <Typography
            color="gray"
            variant="body2"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FiEdit2
              size="18px"
              color="#00C5B2"
              data-tooltip-id="2"
              data-tooltip-content="Disabled"
              style={{ cursor: "not-allowed", marginRight: "10px" }}
            />
            <FiTrash2
              size="18px"
              color="red"
              data-tooltip-id="2"
              data-tooltip-content="Disabled"
              style={{ cursor: "not-allowed" }}
            />
            <Tooltip id="2" style={{ fontStyle: "normal" }} />
          </Typography>
        )}
      </div>
    );
  };

  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const transactions = [
    { id: 1, details: "Transaction 1", date: "01-06-2024", value: "₹100" },
    { id: 2, details: "Transaction 2", date: "01-06-2024", value: "₹200" },
  ];

  const handleClose = () => {
    setOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.SUBSCRIPTION}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.SUBSCRIPTION}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <form>
              <div className="row">
                <div className="col-12">
                  <div className="card-box">
                    <div className="row">
                      <div className="col-12">
                        <div className="p-20 container-fluid">
                          <Box sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={value}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <TabList
                                  onChange={handleTabChange}
                                  aria-label="lab API tabs example"
                                >
                                  <Tab label="Subscription" value="1" />
                                  <Tab label="Credit Info" value="2" />
                                  <Tab
                                    label="Address & Other Info"
                                    value="3"
                                  />

                                  <Tab label="Invoices" value="4" />
                                  <Tab label="Email Recipients" value="5" />
                                  <Tab label="Payment Method" value="6" />
                                </TabList>
                              </Box>
                              <TabPanel value="1">
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    gap: "16px",
                                  }}
                                >
                                  {subscriptions.length > 0 ? (
                                    subscriptions.map((subscription, index) => (
                                      <Paper
                                        key={subscription._id}
                                        style={{
                                          padding: "16px",
                                          marginBottom: "16px",
                                          width: "100%",
                                          maxWidth: "300px",
                                          border: "1px solid #ddd",
                                          borderRadius: "8px",
                                          position: "relative",
                                          textAlign: "center",
                                          boxShadow:
                                            index === 0
                                              ? "0px 0px 10px rgba(0, 0, 0, 0.1)"
                                              : "0px 0px 10px rgba(135, 206, 235, 0.5)",
                                          backgroundColor:
                                            index === 0 ? "#e3f2fd" : "#fff",
                                        }}
                                      >
                                        {index === 0 && (
                                          <Badge
                                            badgeContent="Current"
                                            color="primary"
                                            style={{
                                              position: "absolute",
                                              top: "10px",
                                              left: "10px",
                                              backgroundColor: "#f50057",
                                              color: "#fff",
                                            }}
                                          />
                                        )}
                                        {index !== 0 && (
                                          <LockIcon
                                            style={{
                                              position: "absolute",
                                              top: "10px",
                                              right: "10px",
                                              color: "#45a6e0",
                                              opacity: 0.5,
                                            }}
                                          />
                                        )}
                                        <Typography
                                          variant="h6"
                                          style={{ marginBottom: "8px" }}
                                        >
                                          {subscription.name}
                                        </Typography>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          {subscription.offerprice > 0 && (
                                            <>
                                              <span
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                  marginBottom: "4px",
                                                }}
                                              >
                                                {STRING_CONSTANTS.RUPEE}
                                                {subscription.price}
                                              </span>
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "30px",
                                                }}
                                              >
                                                {STRING_CONSTANTS.RUPEE}
                                                {subscription.offerprice}
                                              </span>
                                            </>
                                          )}
                                          {subscription.offerprice <= 0 && (
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "30px",
                                              }}
                                            >
                                              {STRING_CONSTANTS.RUPEE}
                                              {subscription.price}
                                            </span>
                                          )}
                                        </div>

                                        <Typography
                                          variant="body1"
                                          style={{ marginBottom: "8px" }}
                                        >
                                          {STRING_CONSTANTS.VALIDITY}:{" "}
                                          {subscription.validity}{" "}
                                          {STRING_CONSTANTS.DAYS}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          style={{ marginBottom: "8px" }}
                                        >
                                          {STRING_CONSTANTS.COINS}:{" "}
                                          {subscription.coins}
                                        </Typography>
                                        {index !== 0 && (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            style={{ marginTop: "10px" }}
                                          >
                                            {STRING_CONSTANTS.UPGRADE}
                                          </Button>
                                        )}
                                      </Paper>
                                    ))
                                  ) : (
                                    <Typography variant="body1" align="center">
                                      {STRING_CONSTANTS.NO_SUBSCRIPTION_FOUND}
                                    </Typography>
                                  )}
                                </div>
                              </TabPanel>

                              <TabPanel value="2">
                                <Grid
                                  container
                                  spacing={2}
                                  wrap="wrap"
                                  justifyContent="space-between"
                                >
                                  <Grid item>
                                    <Paper
                                      sx={{
                                        padding: 2,
                                        width: 180,
                                        border: "1px solid #ccc",
                                        boxShadow: 3,
                                        "&:hover": {
                                          transform: "scale(1.05)",
                                          transition: "transform 0.2s",
                                          zIndex: 1,
                                        },
                                        cursor: "pointer",
                                        backgroundColor: showPromotionalCredits
                                          ? "#0baae4"
                                          : "inherit",
                                      }}
                                      onClick={handlePromotionalCredits}
                                    >
                                      <Box textAlign="center">
                                        <Typography variant="subtitle1">
                                          {STRING_CONSTANTS.PROMO_CRED}
                                        </Typography>
                                        <Typography variant="h6">
                                          &nbsp;
                                        </Typography>
                                      </Box>
                                    </Paper>
                                  </Grid>
                                  <Grid item>
                                    <Paper
                                      sx={{
                                        padding: 2,
                                        width: 180,
                                        border: "1px solid #ccc",
                                        boxShadow: 3,
                                        "&:hover": {
                                          transform: "scale(1.05)",
                                          transition: "transform 0.2s",
                                          zIndex: 1,
                                        },
                                        cursor: "pointer",
                                        backgroundColor: showUsedCredits
                                          ? "#0baae4"
                                          : "inherit",
                                      }}
                                      onClick={handleUsedCredits}
                                    >
                                      <Box textAlign="center">
                                        <Typography variant="subtitle1">
                                          {STRING_CONSTANTS.USED_CRED}
                                        </Typography>
                                        <Typography variant="h6">
                                          &nbsp;
                                        </Typography>
                                      </Box>
                                    </Paper>
                                  </Grid>
                                  <Grid item>
                                    <Paper
                                      sx={{
                                        padding: 2,
                                        width: 180,
                                        border: "1px solid #ccc",
                                        boxShadow: 3,
                                        "&:hover": {
                                          transform: "scale(1.05)",
                                          transition: "transform 0.2s",
                                          zIndex: 1,
                                        },
                                        cursor: "pointer",
                                        backgroundColor: showBalancedCredits
                                          ? "#0baae4"
                                          : "inherit",
                                      }}
                                      onClick={handleBalancedCredits}
                                    >
                                      <Box textAlign="center">
                                        <Typography variant="subtitle1">
                                          {STRING_CONSTANTS.BAL_CRED}
                                        </Typography>
                                        <Typography variant="h6">
                                          &nbsp;
                                        </Typography>
                                      </Box>
                                    </Paper>
                                  </Grid>
                                  {/* <Grid item>
                                    <Paper
                                      sx={{
                                        padding: 2,
                                        width: 180,
                                        border: "1px solid #ccc",
                                        boxShadow: 3,
                                        "&:hover": {
                                          transform: "scale(1.05)",
                                          transition: "transform 0.2s",
                                          zIndex: 1,
                                        },
                                        cursor: "pointer",
                                        backgroundColor: showExpCredits
                                          ? "#0baae4"
                                          : "inherit",
                                      }}
                                      onClick={handleExpiredCredits}
                                    >
                                      <Box textAlign="center">
                                        <Typography variant="subtitle1">
                                          {STRING_CONSTANTS.EXP_CRED}
                                        </Typography>
                                        <Typography variant="h6">
                                          {" "}
                                          &nbsp;
                                        </Typography>
                                      </Box>
                                    </Paper>
                                  </Grid> */}
                                </Grid>
                                {showPromotionalCredits && (
                                  <TableContainer open={showPromotionalCredits}>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>
                                            {STRING_CONSTANTS.VOUCHER_NAME}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.SUB_NAME}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.FROM_DATE}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.END_DATE}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.LIMIT}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.STATUS}
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {voucher.map((voucher) => (
                                          <TableRow key={voucher.voucher_id}>
                                            <TableCell>
                                              {voucher.voucherName}
                                            </TableCell>
                                            <TableCell>
                                              {voucher.subscription_name}
                                            </TableCell>
                                            <TableCell>
                                              {formatDate(voucher.from)}
                                            </TableCell>
                                            <TableCell>
                                              {formatDate(voucher.end)}
                                            </TableCell>
                                            <TableCell>
                                              {voucher.limit}
                                            </TableCell>
                                            <TableCell>
                                              {isVoucherActive(
                                                formatDate(voucher.from),
                                                formatDate(voucher.end)
                                              )
                                                ? `${STRING_CONSTANTS.ACTIVE}`
                                                : `${STRING_CONSTANTS.INACTIVE}`}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}

                                {showBalancedCredits && (
                                  <Box sx={{ marginTop: 3 }}>
                                    <Select
                                      value={selectedVoucher}
                                      onChange={handleVoucherChange}
                                      sx={{ width: 250 }}
                                    >
                                      {voucher && voucher.length > 0 ? (
                                        voucher.map((voucher) => (
                                          <MenuItem
                                            key={voucher.voucher_id}
                                            value={voucher.voucher_id}
                                          >
                                            {voucher.voucherName}
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem value="" disabled>
                                          {
                                            STRING_CONSTANTS.NO_VOUCHER_AVAILABLE
                                          }
                                        </MenuItem>
                                      )}
                                    </Select>

                                    {selectedVoucherData && (
                                      <Box sx={{ marginTop: 2 }}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 2,
                                          }}
                                        >
                                          {selectedVoucherData.voucherCode &&
                                          selectedVoucherData.voucherCode
                                            .length > 0 ? (
                                            selectedVoucherData.voucherCode.map(
                                              (code, index) => (
                                                <Paper
                                                  key={index}
                                                  sx={{
                                                    padding: 2,
                                                    border: "1px solid #ccc",
                                                    boxShadow: 3,
                                                    width: 250,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "start",
                                                  }}
                                                >
                                                  <Box sx={{ marginBottom: 1 }}>
                                                    <Typography variant="body1">
                                                      {STRING_CONSTANTS.FROM}:{" "}
                                                      {formatDate(
                                                        selectedVoucherData.from
                                                      )}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                      {STRING_CONSTANTS.END}:{" "}
                                                      {formatDate(
                                                        selectedVoucherData.end
                                                      )}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                      {STRING_CONSTANTS.CODE}:{" "}
                                                      {code}
                                                    </Typography>
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={() =>
                                                        handleOpen(code)
                                                      }
                                                    >
                                                      {STRING_CONSTANTS.ASSIGN}
                                                    </Button>
                                                    <IconButton
                                                      aria-label="share"
                                                      onClick={() =>
                                                        handleCopyCode(code)
                                                      }
                                                    >
                                                      {/* <ShareIcon /> */}
                                                    </IconButton>
                                                  </Box>
                                                </Paper>
                                              )
                                            )
                                          ) : (
                                            <Typography
                                              variant="body1"
                                              color="textSecondary"
                                            >
                                              {
                                                STRING_CONSTANTS.NO_VOUCHER_AVAILABLE_FOR_SELECTED_VOUCHER
                                              }
                                            </Typography>
                                          )}
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                                <Modal open={opens} onClose={handleCloses}>
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      width: 400,
                                      bgcolor: "background.paper",
                                      border: "2px solid #000",
                                      boxShadow: 24,
                                      p: 4,
                                    }}
                                  >
                                    <Typography variant="h6" component="h2">
                                      {STRING_CONSTANTS.ASSING_CODE}
                                    </Typography>
                                    <Typography sx={{ mt: 2 }}>
                                      Code: {selectedCode}
                                    </Typography>
                                    <Box sx={{ mt: 2 }}>
                                      <InputLabel id="user-select-label">
                                        {STRING_CONSTANTS.SELECTED_USER}
                                      </InputLabel>
                                      <Select
                                        labelId="user-select-label"
                                        value={selectedUser}
                                        onChange={(e) =>
                                          setSelectedUser(e.target.value)
                                        }
                                        fullWidth
                                      >
                                        {customers.length > 0 ? (
                                          customers.map((customer) => (
                                            <MenuItem
                                              key={customer.userId}
                                              value={customer.userId}
                                            >
                                              {customer.username}
                                            </MenuItem>
                                          ))
                                        ) : (
                                          <MenuItem value="" disabled>
                                            No user found
                                          </MenuItem>
                                        )}
                                      </Select>
                                    </Box>
                                    <Box sx={{ mt: 2, textAlign: "right" }}>
                                      <Button
                                        onClick={handleCloses}
                                        sx={{ mr: 1 }}
                                      >
                                        {STRING_CONSTANTS.CANCEL}
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAssign}
                                      >
                                        {STRING_CONSTANTS.ASSIGN}
                                      </Button>
                                    </Box>
                                  </Box>
                                </Modal>

                                {showUsedCredits && (
                                  <Box sx={{ marginTop: 3 }}>
                                    <Select
                                      value={usedVoucher}
                                      onChange={handleUsedVoucher}
                                      sx={{ width: 250 }}
                                    >
                                      {voucher && voucher.length > 0 ? (
                                        voucher.map((voucher) => (
                                          <MenuItem
                                            key={voucher.voucher_id}
                                            value={voucher.voucher_id}
                                          >
                                            {voucher.voucherName}
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem value="" disabled>
                                          {
                                            STRING_CONSTANTS.NO_VOUCHER_AVAILABLE
                                          }
                                        </MenuItem>
                                      )}
                                    </Select>

                                    {useVoucherData && (
                                      <TableContainer>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>
                                                {STRING_CONSTANTS.SUB_NAME}
                                              </TableCell>
                                              <TableCell>
                                                {STRING_CONSTANTS.USER_NAME}
                                              </TableCell>
                                              <TableCell>
                                                {STRING_CONSTANTS.VOUCHER_CODE}
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {useVoucherData.assignedUserId
                                              .length > 0 ? (
                                              useVoucherData.assignedUserId.map(
                                                (user, index) => (
                                                  <TableRow key={index}>
                                                    {user.userId && (
                                                      <>
                                                        <TableCell>
                                                          {
                                                            useVoucherData.subscription_name
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          {user.userName ||
                                                            "N/A"}
                                                        </TableCell>
                                                        <TableCell>
                                                          {user.userCode ||
                                                            "N/A"}
                                                        </TableCell>
                                                      </>
                                                    )}
                                                  </TableRow>
                                                )
                                              )
                                            ) : (
                                              <TableRow>
                                                <TableCell colSpan={3}>
                                                  {
                                                    STRING_CONSTANTS.NO_VOUCHER_AVAILABLE
                                                  }
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    )}
                                  </Box>
                                )}

                                {/* {showExpCredits && (
                                  <TableContainer open={showExpCredits}>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>
                                            {STRING_CONSTANTS.VOUCHER_NAME}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.SUB_NAME}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.FROM_DATE}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.END_DATE}
                                          </TableCell>
                                          <TableCell>
                                            {STRING_CONSTANTS.LIMIT}
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {expiredCredits.map((voucher) => (
                                          <TableRow key={voucher.voucher_id}>
                                            <TableCell>
                                              {voucher.voucherName}
                                            </TableCell>
                                            <TableCell>
                                              {voucher.subscription_name}
                                            </TableCell>
                                            <TableCell>
                                              {formatDate(voucher.from)}
                                            </TableCell>
                                            <TableCell>
                                              {formatDate(voucher.end)}
                                            </TableCell>
                                            <TableCell>
                                              {voucher.limit}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )} */}

                                <Modal
                                  open={copyModal}
                                  onClose={handleClosed}
                                  aria-labelledby="modal-title"
                                  aria-describedby="modal-description"
                                >
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      width: 300,
                                      bgcolor: "background.paper",
                                      boxShadow: 24,
                                      p: 4,
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      id="modal-title"
                                      variant="h6"
                                      component="h2"
                                    >
                                      {STRING_CONSTANTS.SELECTED_VOUCHER_CODE}
                                    </Typography>
                                    <Typography
                                      id="modal-description"
                                      sx={{ mt: 2 }}
                                    >
                                      {copyCode}&nbsp;{" "}
                                      <IconButton onClick={() => handleCopyClick(copyCode)}>
                                        <FaRegCopy />
                                      </IconButton>
                                    </Typography>

                                    <Button
                                      onClick={handleClosed}
                                      color="primary"
                                      variant="contained"
                                      sx={{ mt: 2 }}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Box>
                                </Modal>
                              </TabPanel>

                              <TabPanel value="3">
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#0baae5",
                                    color: "#fff",
                                    marginBottom: "20px",
                                    textTransform: "none",
                                    "&:hover": {
                                      backgroundColor: "#0baae5",
                                    },
                                  }}
                                  onClick={handleShowModalAdd}
                                >
                                  + {STRING_CONSTANTS.ADD_NEW_ADDRESS}
                                </Button>
                                <Modal
                                  open={showModalAdd}
                                  onClose={handleCloseModalAdd}
                                  closeAfterTransition
                                  BackdropProps={{
                                    style: {
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    },
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  disableBackdropClick
                                >
                                  <Fade in={showModalAdd}>
                                    <Box
                                      sx={{
                                        bgcolor: "background.paper",
                                        boxShadow: 1,
                                        p: 4,
                                        borderRadius: 2,
                                        width: "60%",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      <h2>
                                        {addressId
                                          ? "Edit Address"
                                          : "Add Address"}
                                      </h2>
                                      <form
                                        onSubmit={
                                          addressId
                                            ? handleUpdateAddress
                                            : handleSubmitAddress
                                        }
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={6}>
                                            <TextField
                                              label="Address (Building No/floor)"
                                              fullWidth
                                              name="Address"
                                              value={newaddress.Address}
                                              onChange={handleChangeAddress}
                                              variant="outlined"
                                              margin="normal"
                                            />
                                            <TextField
                                              label="Locality"
                                              fullWidth
                                              name="locality"
                                              value={newaddress.locality}
                                              onChange={handleChangeAddress}
                                              variant="outlined"
                                              margin="normal"
                                            />
                                            <TextField
                                              label="City"
                                              fullWidth
                                              name="city"
                                              value={newaddress.city}
                                              onChange={handleChangeAddress}
                                              variant="outlined"
                                              margin="normal"
                                            />
                                            <TextField
                                              label="Country"
                                              fullWidth
                                              name="country"
                                              value={newaddress.country}
                                              onChange={handleChangeAddress}
                                              variant="outlined"
                                              margin="normal"
                                            />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <TextField
                                              label="Address (Area/Street)"
                                              fullWidth
                                              name="area"
                                              value={newaddress.area}
                                              onChange={handleChangeAddress}
                                              variant="outlined"
                                              margin="normal"
                                            />
                                            <TextField
                                              label="Landmark"
                                              fullWidth
                                              name="landmark"
                                              value={newaddress.landmark}
                                              onChange={handleChangeAddress}
                                              variant="outlined"
                                              margin="normal"
                                            />
                                            <TextField
                                              label="State"
                                              fullWidth
                                              name="state"
                                              value={newaddress.state}
                                              onChange={handleChangeAddress}
                                              variant="outlined"
                                              margin="normal"
                                            />
                                            <TextField
                                              label="PIN Code"
                                              fullWidth
                                              name="pincode"
                                              value={newaddress.pincode}
                                              onChange={handleChangeAddress}
                                              variant="outlined"
                                              margin="normal"
                                            />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={isPrimaryAddress}
                                                  onChange={
                                                    handleAddCheckboxChange
                                                  }
                                                  name="isPrimaryAddress"
                                                  color="primary"
                                                />
                                              }
                                              label="Is Primary Address"
                                            />
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          container
                                          justifyContent="flex-end"
                                          style={{
                                            marginTop: "40px",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          <Button
                                            variant="outlined"
                                            color="error"
                                            style={{ marginRight: "10px" }}
                                            onClick={handleCloseModalAdd}
                                          >
                                            {STRING_CONSTANTS.CLOSE}
                                          </Button>
                                          <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{
                                              backgroundColor: "#0baae5",
                                            }}
                                          >
                                            {addressId ? "Update" : "Submit"}
                                          </Button>
                                        </Grid>
                                      </form>
                                    </Box>
                                  </Fade>
                                </Modal>

                                <Dialog open={showAddPrimaryConfirmation}>
                                  <DialogTitle>
                                    {STRING_CONSTANTS.CONFIRM_PRIMARY_ADDRESS}
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>
                                      {
                                        STRING_CONSTANTS.DO_YOU_WANT_TO_SET_THIS_ADDRESS_PRIMARY
                                      }
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={() =>
                                        handleAddPrimaryConfirmation(false)
                                      }
                                      color="primary"
                                    >
                                      {STRING_CONSTANTS.NO}
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleAddPrimaryConfirmation(true)
                                      }
                                      color="primary"
                                      autoFocus
                                    >
                                      {STRING_CONSTANTS.YES}
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                {sortedAddresses.map((address, index) => (
                                  <Paper
                                    key={index}
                                    sx={{
                                      padding: 2,
                                      marginBottom: 2,
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 8,
                                        right: 8,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {address.isPrimaryAddress && (
                                        <Badge
                                          badgeContent="Primary"
                                          color="primary"
                                          sx={{
                                            fontSize: "0.75rem",
                                            marginRight: "50px",
                                          }}
                                        />
                                      )}
                                      <AddressAction row={address} />
                                    </div>
                                    <Typography variant="body1" align="left">
                                      <div>{address.Address}</div>
                                      <div>{address.area}</div>
                                      <div>{address.locality}</div>
                                      <div>
                                        {address.city}, {address.country},{" "}
                                        {address.landmark}, {address.state},{" "}
                                        {address.pincode}
                                      </div>
                                    </Typography>
                                  </Paper>
                                ))}
                              </TabPanel>

                              <TabPanel value="4">
                                <TableContainer component={Paper}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell width="100px" align="center">
                                          {STRING_CONSTANTS.SN_NO}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {STRING_CONSTANTS.TRANS_TITLE}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {STRING_CONSTANTS.DATE}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {STRING_CONSTANTS.INVOICE_VALUE}
                                        </TableCell>
                                        <TableCell width="100px" align="center">
                                          {STRING_CONSTANTS.ACTIONS}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {transactions.map((row, index) => (
                                        <TableRow key={row.id}>
                                          <TableCell
                                            width="100px"
                                            align="center"
                                          >
                                            {index + 1}
                                          </TableCell>
                                          <TableCell width="180px" align="left">
                                            {row.details}
                                          </TableCell>
                                          <TableCell width="180px" align="left">
                                            {row.date}
                                          </TableCell>
                                          <TableCell width="180px" align="left">
                                            {row.value}
                                          </TableCell>
                                          <TableCell
                                            width="100px"
                                            align="center"
                                          >
                                            <IconButton
                                              component={Link}
                                              to={"/organisation/invoice"}
                                              target="_blank"
                                            >
                                              <FiEye
                                                color="#9368f3"
                                                size="20"
                                              />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </TabPanel>
                              <Dialog
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                  style: {
                                    width: "80%",
                                    maxHeight: "80%",
                                  },
                                }}
                              >
                                <DialogTitle>
                                  {STRING_CONSTANTS.INVOICE_DETAILS}
                                </DialogTitle>
                                <DialogContent>
                                  <TableContainer>
                                    <Table>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell colSpan={2}>
                                            <Typography
                                              variant="h6"
                                              gutterBottom
                                            >
                                              VELZON
                                            </Typography>
                                            <Typography variant="body2">
                                              California, United States
                                              <br />
                                              90201
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            <strong>
                                              {STRING_CONSTANTS.INVOICE_NO}:
                                            </strong>
                                          </TableCell>
                                          <TableCell>
                                            {invoice.invoiceNo}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            <strong>
                                              {STRING_CONSTANTS.DATE}:
                                            </strong>
                                          </TableCell>
                                          <TableCell>{invoice.date}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            <strong>
                                              {STRING_CONSTANTS.PAYMENT_STATUS}:
                                            </strong>
                                          </TableCell>
                                          <TableCell>
                                            {invoice.paymentStatus}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            <strong>
                                              {STRING_CONSTANTS.TOTAL_AMOUNT}:
                                            </strong>
                                          </TableCell>
                                          <TableCell>
                                            {invoice.totalAmount}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            <strong>
                                              {STRING_CONSTANTS.BILLING_ADD}:
                                            </strong>
                                          </TableCell>
                                          <TableCell>
                                            {invoice.billingAddress.name}
                                            <br />
                                            {invoice.billingAddress.address}
                                            <br />
                                            {STRING_CONSTANTS.PHONE}:{" "}
                                            {invoice.billingAddress.phone}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            <strong>
                                              {
                                                STRING_CONSTANTS.SHIPPING_ADDRESS
                                              }
                                              :
                                            </strong>
                                          </TableCell>
                                          <TableCell>
                                            {invoice.shippingAddress.name}
                                            <br />
                                            {invoice.shippingAddress.address}
                                            <br />
                                            {STRING_CONSTANTS.PHONE}:{" "}
                                            {invoice.shippingAddress.phone}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={2}>
                                            <Typography variant="body2">
                                              {STRING_CONSTANTS.LEGAL_REG_NO}:{" "}
                                              {invoice.legalRegistrationNo}
                                              <br />
                                              {STRING_CONSTANTS.EMAIL}:{" "}
                                              {invoice.email}
                                              <br />
                                              {STRING_CONSTANTS.WEBSITE}:{" "}
                                              {invoice.website}
                                              <br />
                                              {
                                                STRING_CONSTANTS.CONTACT_NO
                                              }: {invoice.contactNo}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose} color="primary">
                                    {STRING_CONSTANTS.CLOSE}
                                  </Button>
                                </DialogActions>
                              </Dialog>

                              <TabPanel value="5">
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#0baae5",
                                    color: "#fff",
                                    marginBottom: "20px",
                                    textTransform: "none",
                                    "&:hover": {
                                      backgroundColor: "#0baae5",
                                    },
                                  }}
                                  onClick={handleShowModal}
                                >
                                  + {STRING_CONSTANTS.ADD_RECIPIENT}
                                </Button>
                                <Modal
                                  open={showModal}
                                  onClose={handleCloseModal}
                                  closeAfterTransition
                                  BackdropProps={{
                                    style: {
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    },
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  disableBackdropClick
                                >
                                  <Fade in={showModal}>
                                    <Box
                                      sx={{
                                        bgcolor: "background.paper",
                                        boxShadow: 1,
                                        p: 4,
                                        borderRadius: 2,
                                        width: "60%",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      <h2>
                                        {recipientId
                                          ? `${STRING_CONSTANTS.EDIT_RECIPIENT}`
                                          : `${STRING_CONSTANTS.ADD_RECIPIENT}`}
                                      </h2>
                                      <form
                                        onSubmit={
                                          recipientId
                                            ? handleUpdate
                                            : handleSubmit
                                        }
                                      >
                                        <Grid
                                          container
                                          spacing={2}
                                          style={{ marginTop: "0px" }}
                                        >
                                          <Grid item xs={12}>
                                            <TextField
                                              fullWidth
                                              label="Email"
                                              variant="outlined"
                                              placeholder={
                                                STRING_CONSTANTS.EMAIL_PLACEHOLDER
                                              }
                                              name="recipientEmail"
                                              value={data.recipientEmail}
                                              onChange={handleChange}
                                              required
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <TextField
                                              fullWidth
                                              label="Recipient Name"
                                              variant="outlined"
                                              placeholder={
                                                STRING_CONSTANTS.RECIPIENT_NAME_PLACEHOLDER
                                              }
                                              name="recipientName"
                                              value={data.recipientName}
                                              onChange={handleChange}
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <TextField
                                              fullWidth
                                              label="Designation"
                                              variant="outlined"
                                              placeholder={
                                                STRING_CONSTANTS.DESIGNATION_PLACEHOLDER
                                              }
                                              name="designation"
                                              value={data.designation}
                                              onChange={handleChange}
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={isPrimaryEmail}
                                                  onChange={
                                                    handleCheckboxChange
                                                  }
                                                  name="isPrimaryEmail"
                                                  color="primary"
                                                />
                                              }
                                              label="Is Primary Email"
                                            />
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          container
                                          justifyContent="flex-end"
                                          style={{
                                            marginTop: "40px",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          <Button
                                            variant="outlined"
                                            color="error"
                                            style={{ marginRight: "10px" }}
                                            onClick={handleCloseModal}
                                          >
                                            {STRING_CONSTANTS.CLOSE}
                                          </Button>
                                          <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{
                                              backgroundColor: "#0baae5",
                                            }}
                                          >
                                            {recipientId
                                              ? `${STRING_CONSTANTS.UPDATE}`
                                              : `${STRING_CONSTANTS.SUBMIT}`}
                                          </Button>
                                        </Grid>
                                      </form>
                                    </Box>
                                  </Fade>
                                </Modal>

                                <Dialog open={showPrimaryConfirmation}>
                                  <DialogTitle>
                                    {STRING_CONSTANTS.CONFIRM_PRIMARY_EMAIL}
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>
                                      {
                                        STRING_CONSTANTS.DO_YOU_WANT_TO_SET_THIS_ADDRESS_PRIMARY
                                      }
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={() =>
                                        handlePrimaryConfirmationClose(false)
                                      }
                                      color="primary"
                                    >
                                      {STRING_CONSTANTS.NO}
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handlePrimaryConfirmationClose(true)
                                      }
                                      color="primary"
                                      autoFocus
                                    >
                                      {STRING_CONSTANTS.YES}
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                <TableContainer component={Paper}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell width="80px" align="center">
                                          {STRING_CONSTANTS.SN_NO}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {STRING_CONSTANTS.EMAIL}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {STRING_CONSTANTS.RECIPIENT_NAME}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {STRING_CONSTANTS.DESIGNATION}
                                        </TableCell>
                                        <TableCell width="180px" align="left">
                                          {STRING_CONSTANTS.ACTIONS}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {sortedRecipients.map(
                                        (recipient, index) => (
                                          <TableRow key={index}>
                                            <TableCell align="center">
                                              <Typography>
                                                {index + 1}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              {recipient.recipientEmail}
                                              {recipient.isPrimary && (
                                                <Badge
                                                  badgeContent="Primary"
                                                  color="primary"
                                                  sx={{
                                                    marginLeft: 4,
                                                    fontSize: "0.75rem",
                                                  }}
                                                />
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {recipient.recipientName}
                                            </TableCell>
                                            <TableCell>
                                              {recipient.designation}
                                            </TableCell>
                                            <TableCell>
                                              <Action row={recipient} />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </TabPanel>

                              <TabPanel value="6">
                                <Paper sx={{ padding: 2 }}>
                                  <Typography
                                    variant="h6"
                                    align="left"
                                    sx={{
                                      borderBottom: 1,
                                      borderColor: "divider",
                                      paddingBottom: "8px",
                                      marginBottom: "16px",
                                    }}
                                  >
                                    {STRING_CONSTANTS.PAYMENT_METHODS}
                                  </Typography>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      marginTop: "16px",
                                      marginBottom: "32px",
                                    }}
                                  >
                                    <img
                                      src="/assets/images/payment/mastercard.png"
                                      alt="MasterCard"
                                      style={{ width: "50px" }}
                                    />
                                    <img
                                      src="/assets/images/payment/visa.png"
                                      alt="Visa"
                                      style={{ width: "50px" }}
                                    />

                                    <img
                                      src="/assets/images/payment/paypal.png"
                                      alt="PayPal"
                                      style={{ width: "50px" }}
                                    />
                                  </Box>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "#009cde",
                                      color: "#fff",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {STRING_CONSTANTS.PAYPAL}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      width: "100%",
                                      backgroundColor: "#333",
                                      color: "#fff",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <i
                                      className="fa fa-credit-card"
                                      style={{ marginRight: "8px" }}
                                    ></i>
                                    {STRING_CONSTANTS.DEBIT_OR_CRED}
                                  </Button>
                                  <Typography
                                    sx={{
                                      marginTop: "10px",
                                      fontSize: "12px",
                                      color: "#aaa",
                                      textAlign: "center",
                                    }}
                                  >
                                    {STRING_CONSTANTS.POWERED_BY_PAYPAL}
                                  </Typography>
                                </Paper>
                              </TabPanel>
                            </TabContext>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
