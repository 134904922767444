import { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import {
  getMemberWithOrg,
  editOrganisationMember,
  addOrganisationMember,
  deleteOrganisationMember,
  ApproveOrganisationMember,
} from "../../actions/adminAction";
import { BLOCK, EDIT, SEARCH_ORG_MEMBER, UNBLOCK } from "../../constants/url";
import { IoSearch } from "react-icons/io5";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import Cookies from "universal-cookie";
import AddIcon from "@mui/icons-material/Add";
import { FiEdit2 } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  Table,
  Paper,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Modal,
  Fade,
  Box,
} from "@mui/material";

import { Tooltip } from "react-tooltip";
import { privateApiCall } from "../../api/privateApi";
import {
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  BLOCK_VOLUNTEER,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  SUCC_ALERT_TITLE,
  UN_BLOCK_VOLUNTEER,
} from "../../constants/alertMessage";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const cookies = new Cookies();

const Members = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [volunteer, setVolunteer] = useState(null);
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    error: "",
    org: [],
    header: "",
    type: "",
    _id: "",
    org_id: cookies.get("org_id"),
    members: [],
    success: false,
    removed: false,
  });

  useEffect(() => {
    loadData();
  }, []);

  const { _id, org_id, members, fname, lname, mobile, email, type } = values;
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getMemberWithOrg(org_id).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setValues({
          ...values,
          fname: "",
          lname: "",
          mobile: "",
          email: "",
          members: data.mem,
          header: "Add Organisation Volunteer",
          _id: "",
          type: "add",
        });
      }
    });
  };
  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const handleSearchMember = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${SEARCH_ORG_MEMBER}?org_id=${org_id}&query=${searchValue}`;
      const response = await privateApiCall(requestUrl, "GET");

      if (response.error) {
        return;
      }

      if (response.status) {
        const membersinfo = response.data.mem.map((member, index) => ({
          ...member,
          sno: index + 1,
        }));
        setValues((prevState) => ({ ...prevState, members: membersinfo }));
      } else {
        setValues((prevState) => ({ ...prevState, members: [] }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const handleClose = () => {
    setValues({
      ...values,
      fname: "",
      lname: "",
      mobile: "",
      email: "",
      _id: "",
    });
    setShow(false);
    setVolunteer(null);
  };
  const Actions = ({ row }) => {
    const handleEditShow = (e) => {
      setShow(true);

      setValues({
        ...values,
        error: false,
        fname: row.fname,
        lname: row.lname,
        mobile: row.mobile,
        email: row.email,

        header: "Edit Organisation Volunteer",
        type: "edit",
        _id: row._id,
      });

      setVolunteer(row._id);
    };
    const handleClick = (e) => {
      Swal.fire({
        title: `${BLOCK_VOLUNTEER} ${row.fname} ${row.lname} ?`,
        text: "",
        icon: "question",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteOrganisationMember(row._id).then((data) => {
            if (data.error) {
              setValues({ ...values, error: data.error, loading: false });
            } else {
              loadData();
            }
          });
        }
      });
    };
    const handleApproveClick = (e) => {
      Swal.fire({
        title: `${UN_BLOCK_VOLUNTEER} ${row.fname} ${row.lname} ?`,
        text: "",
        icon: "question",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          ApproveOrganisationMember(row._id).then((data) => {
            if (data.error) {
              setValues({ ...values, error: data.error, loading: false });
            } else {
              loadData();
            }
          });
        }
      });
    };

    if (row.status) {
      return (
        <span>
          {(permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProfileVolunteers" && item.isEdit
            )) && (
            <>
              <i
                className="mr-2"
                onClick={handleEditShow}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={`${EDIT}`}
              >
                <Tooltip id="my-tooltip" style={{ fontStyle: "normal" }} />
                <FiEdit2 size="18px" color="#00C5B2" />
              </i>
            </>
          )}

          {(permissions === "All" ||
            permissions.some(
              (item) =>
                item.keyword === "orgProfileVolunteers" && item.isUnblock
            )) && (
            <>
              <i
                className="ml-2"
                onClick={handleApproveClick}
                data-tooltip-id="tooltip"
                data-tooltip-content={`${UNBLOCK}`}
              >
                <Tooltip id="tooltip" style={{ fontStyle: "normal" }} />
                <FaRegThumbsUp size="18px" color="green" />
              </i>
            </>
          )}
        </span>
      );
    } else {
      return (
        <span>
          {(permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProfileVolunteers" && item.isEdit
            )) && (
            <>
              <i
                className="mr-2"
                onClick={handleEditShow}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={`${EDIT}`}
              >
                <Tooltip id="my-tooltip" style={{ fontStyle: "normal" }} />
                <FiEdit2 size="18px" color="#00C5B2" />
              </i>
            </>
          )}

          {(permissions === "All" ||
            permissions.some(
              (item) => item.keyword === "orgProfileVolunteers" && item.isBlock
            )) && (
            <>
              <i
                className="ml-2"
                onClick={handleClick}
                data-tooltip-id="tool"
                data-tooltip-content={`${BLOCK}`}
              >
                <Tooltip id="tool" style={{ fontStyle: "normal" }} />
                <FaRegThumbsDown size="18px" color="red" />
              </i>
            </>
          )}
        </span>
      );
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const chapter = { fname, lname, mobile, email, org_id };
    if (type === "add") {
      addOrganisationMember(chapter)
        .then((data) => {
          if (data && data.error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: data.message,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            }).then(() => {
              handleClose(false);
              loadData();
            });
          } else {
            handleClose(false);
            Swal.fire({
              icon: "success",
              title: `${SUCC_ALERT_TITLE}`,
              text: data.message,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            }).then(() => {
              loadData();
            });
          }
        })
        .catch((error) => {
          console.error("Error occurred while adding member:");
        });
    } else if (type === "edit") {
      editOrganisationMember(chapter, _id)
        .then((data) => {
          if (data.error) {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ALERT_ICON_ERR}`,
              text: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            }).then(() => {
              loadData();
            });
          } else {
            handleClose(false);
            Swal.fire({
              title: `${SUCC_ALERT_TITLE}`,
              text: data.message,
              icon: "success",
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            }).then(() => {
              loadData();
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: "error",
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    }
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar type="profiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.VOLUNTEER_LIST}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.VOLUNTEER_LIST}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div
                      className="row container-fluid"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {(permissions === "All" ||
                        permissions.some(
                          (item) =>
                            item.keyword === "orgProfileVolunteers" &&
                            item.isCreate
                        )) && (
                        <>
                          <button
                            className="btn  ml-2 mb-2"
                            style={{
                              float: "left",
                              backgroundColor: "#0baae5",
                              color: "#fff",
                            }}
                            onClick={handleShow}
                          >
                            <AddIcon style={{ marginRight: "5px" }} />
                            {STRING_CONSTANTS.ADD_VOLUNTEER}
                          </button>
                        </>
                      )}

                      {(permissions === "All" ||
                        permissions.some(
                          (item) =>
                            item.keyword === "orgProfileVolunteers" &&
                            item.isSearch
                        )) && (
                        <>
                          <div
                            style={{ position: "relative", marginLeft: "auto" }}
                          >
                            <input
                              type="text"
                              placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                              className="form-control"
                              style={{
                                width: "200px",
                                paddingLeft: "35px",
                                boxSizing: "border-box",
                                marginLeft: "auto",
                              }}
                              onChange={(e) => {
                                handleSearchMember(e);
                                if (e.target.value.trim() === "") {
                                  e.target.nextElementSibling.style.display =
                                    "inline-block";
                                } else {
                                  e.target.nextElementSibling.style.display =
                                    "none";
                                }
                              }}
                            />
                            <IoSearch
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.FIRST_NAME}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.LAST_NAME}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.MOBILE}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.EMAIL}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.STATUS}
                            </TableCell>
                            <TableCell width="80px" align="center">
                              {STRING_CONSTANTS.ACTIONS}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {members.map((row, index) => (
                            <TableRow key={row._id}>
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="center">{row.fname}</TableCell>
                              <TableCell align="center">{row.lname}</TableCell>
                              <TableCell align="center">{row.mobile}</TableCell>
                              <TableCell align="center">{row.email}</TableCell>
                              <TableCell align="center">
                                {" "}
                                {row.status === true ? (
                                  <span className="btn-sm btn-danger ">
                                    {STRING_CONSTANTS.INACTIVE}
                                  </span>
                                ) : (
                                  <span className="btn-sm btn-success">
                                    {STRING_CONSTANTS.ACTIVE}
                                  </span>
                                )}{" "}
                              </TableCell>
                              <TableCell align="center">
                                <Actions row={row} />
                              </TableCell>
                            </TableRow>
                          ))}
                          <Modal
                            open={show}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropProps={{
                              style: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              },
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            disableBackdropClick
                          >
                            <Fade in={show}>
                              <Box
                                sx={{
                                  bgcolor: "background.paper",
                                  boxShadow: 1,
                                  p: 4,
                                  borderRadius: 2,
                                  width: "60%",
                                  marginBottom: "50px",
                                }}
                              >
                                <h4>
                                  {volunteer
                                    ? `${STRING_CONSTANTS.EDIT_VOLUNTEER}`
                                    : `${STRING_CONSTANTS.ADD_VOLUNTEER}`}
                                </h4>
                                <form onSubmit={handleSubmit}>
                                  <Grid
                                    container
                                    spacing={2}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="First Name"
                                        variant="outlined"
                                        name="name"
                                        value={fname}
                                        onChange={handleChange("fname")}
                                        placeholder={
                                          STRING_CONSTANTS.FIRST_NAME_PLACEHOLDER
                                        }
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Last Name"
                                        variant="outlined"
                                        name="lname"
                                        value={lname}
                                        onChange={handleChange("lname")}
                                        placeholder={
                                          STRING_CONSTANTS.DISPLAY_NAME_PLACEHOLDER
                                        }
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Mobile Number"
                                        variant="outlined"
                                        name="mobile"
                                        value={mobile}
                                        onChange={handleChange("mobile")}
                                        placeholder={
                                          STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER
                                        }
                                        inputProps={{ maxLength: 10 }}
                                        required
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Email ID"
                                        variant="outlined"
                                        name="email"
                                        value={email}
                                        onChange={handleChange("email")}
                                        placeholder={
                                          STRING_CONSTANTS.EMAIL_PLACEHOLDER
                                        }
                                        required
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="flex-end"
                                    style={{
                                      marginTop: "40px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={handleClose}
                                      style={{ marginRight: "10px" }}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        backgroundColor: "#0baae5",
                                        color: "#fff",
                                      }}
                                    >
                                      {volunteer ? "Update" : "Submit"}
                                    </Button>
                                  </Grid>
                                </form>
                              </Box>
                            </Fade>
                          </Modal>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Members;
