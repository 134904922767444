import React, { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import {
  getOrganisation,
  getOrganisationMember,
  editOrganisationMember,
  addOrganisationMember,
  deleteOrganisationMember,
  ApproveOrganisationMember,
} from "../../actions/adminAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import Cookies from "universal-cookie";
import { FiEdit2 } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa'
import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
} from "@mui/material";
import { Tooltip } from "react-tooltip";
import { BLOCK, EDIT, UNBLOCK, SEARCH_ORG_VTR } from "../../constants/url";
import { ERR_GETTING, ERR_POSTING, ERROR, ERR, CONFIRM, SUCC_ALERT_TITLE, ALERT_ICON_ERR, ALERT_BUTTON_OK, ALERT_ICON_SUCC, ALERT_ICON_WARNING, ALERT_BUTTON_YES, ERR_ALERT_TITLE, } from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
const cookies = new Cookies();

const Members = () => {
  const localRole = JSON.parse(localStorage.getItem("role"))
  const permissions = localRole && localRole.permissions ? localRole.permissions : "All"
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    error: "",
    org: [],
    header: "",
    type: "",
    org_id: "",
    members: [],
    _id: "",
    success: false,
    removed: false,
  });
  const {
    org_id,
    members,
    org,
    fname,
    lname,
    mobile,
    email,

    type,
    _id,

  } = values;
  const agritech_token = cookies.get("agritech_token");
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    getOrganisation(agritech_token, "All", "All").then((data) => {
      if (data.error) {
        Swal.fire({
          title: `${ERR_GETTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        
      })
      } else {
        getOrganisationMember(agritech_token).then((mem) => {

          if (mem.error) {
          } else {
            setValues({
              ...values,
              fname: "",
              lname: "",
              mobile: "",
              email: "",
              members: mem.mem,
              org: data.org,
              header: `${STRING_CONSTANTS.ADD_ORG_AGENT}`,
              _id: "",
              org_id: "",
              type: "add",
            });
          }
        });
      }
    })
      .catch(() => {
        Swal.fire({
          title: `${ERR_GETTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        
      })
      })
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${SEARCH_ORG_VTR}?query=${searchValue}`
      const response = await privateApiCall(requestUrl, "GET")

      if (response.data && response.data.mem) {
        setValues((prevState) => ({
          ...prevState,
          members: response.data.mem,
        }));
      } else {
        setValues((prevState) => ({
          ...prevState,
          members: [],
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ ALERT_ICON_ERR }`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ ALERT_BUTTON_OK }`,
      });
    }
  };


  const Actions = ({ row }) => {
    const handleEditClick = (e) => {
      setShow1(true);
      setValues({
        ...values,
        error: false,
        fname: row.fname,
        lname: row.lname,
        mobile: row.mobile,
        email: row.email,
        header: `${STRING_CONSTANTS.EDIT_ORG_AGENT}`,
        type: "edit",
        _id: row._id,
        org_id: row.org_id,
      });
    };
    const handleClick = (e) => {
      Swal.fire({
        title: `${STRING_CONSTANTS.BLOCK_VOLUNTEER} ${row.fname} ${row.lname} ?`,
        icon: `${ALERT_ICON_WARNING}`,
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteOrganisationMember(row._id).then((data) => {
            if (data.error) {
              setValues({ ...values, error: data.error, loading: false });
            } else {
              loadData();
            }
          });
        }
      })
        .catch(() => {
          Swal.fire({
            title: `${ERR_POSTING}`,
            icon: `${ALERT_ICON_ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`
          });
        })
    };
    const handleApproveClick = (e) => {
      Swal.fire({
        title: `${STRING_CONSTANTS.UNBLOCK_VOLUNTEER} ${row.fname} ${row.lname} ?`,
        text: "",
        icon: `${ALERT_ICON_SUCC}`,
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          ApproveOrganisationMember(row._id).then((data) => {
            if (data.error) {
              setValues({ ...values, error: data.error, loading: false });
            } else {
              loadData();
            }
          });
        }
      })
        .catch(() => {
          Swal.fire({
            title: `${ERR_GETTING}`,
            icon: `${ALERT_ICON_ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        })
    };
    if (row.status) {
      return (
        <span>
          <i
            className="mr-2"
            onClick={handleEditClick}
            data-tooltip-id="1"
            data-tooltip-content={`${EDIT}`}
            style={{ fontStyle: "normal" }}
          >
            <FiEdit2 size="18px" color="#00C5B2" />
          </i>
          {permissions.some(item => item.keyword === "profilesVolunteers" && item.isUnblock === true) &&
            <i
              className="ml-2"
              onClick={handleApproveClick}
              data-tooltip-id="3"
              data-tooltip-content={`${UNBLOCK}`}
              style={{ fontStyle: "normal" }}
            >
              <FaRegThumbsUp size="18px" color="green" />
              <Tooltip id="3" />
            </i>
          }
        </span>
      );
    } else {
      return (
        <span>
          {permissions.some(item => item.keyword === "profilesVolunteers" && item.isEdit === true) &&
            <i
              className="mr-2"
              onClick={handleEditClick}
              data-tooltip-id="1"
              data-tooltip-content={`${EDIT}`}
              style={{ fontStyle: "normal" }}
            >
              <FiEdit2 size="18px" color="#00C5B2" />
              <Tooltip id="1" />
            </i>
          }
          {permissions.some(item => item.keyword === "profilesVolunteers" && item.isBlock === true) &&
            <i
              className="ml-2"
              onClick={handleClick}
              data-tooltip-id="2"
              data-tooltip-content={`${BLOCK}`}
              style={{ fontStyle: "normal" }}
            >
              <FaRegThumbsDown size="18px" color="red" />
              <Tooltip id="2" />
            </i>
          }
        </span>
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const chapter = { fname, lname, mobile, email, org_id };

    if (type === "add") {
      addOrganisationMember(chapter, agritech_token).then((data) => {
        if (data.error) {
          Swal.fire({
            title: data.error,
            icon: {ALERT_ICON_ERR},
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else {
          handleClose(false);
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            icon: {ALERT_ICON_SUCC},
            confirmButtonText: "OK",
          }).then(() => {
            loadData();
          });
        }
      })
        .catch(() => {
          Swal.fire({
            title: `${ERR_POSTING}`,
            icon: {ALERT_ICON_ERR},
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText:"OK",
          });
        })
    } else if (type === "edit") {
      editOrganisationMember(chapter, _id).then((data) => {
        if (data.error) {
          Swal.fire({
            title: data.error,
            icon: {ALERT_ICON_ERR},
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else {
          handleClose1(false);
          Swal.fire({
            title: `${SUCC_ALERT_TITLE}`,
            icon: {ALERT_ICON_SUCC},
            confirmButtonText:"OK",
          }).then(() => {
            loadData();
          });
        }
      })
        .catch(() => {
          Swal.fire({
            title: `${ERR_POSTING}`,
            icon: {ALERT_ICON_ERR},
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText:{ALERT_BUTTON_OK},
          });
        })
    }
  };
  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };
  const handleClearData = () => {
    setValues({
      ...values,
      fname: "",
      lname: "",
      mobile: "",
      email: "",
      _id: "",
      org_id: "",
    });
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="profiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div class="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.VOLUNTEERS}</li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.VOLUNTEERS}</h4>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    width: "98%",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">

                    {permissions.some(item => item.keyword === "profilesVolunteers" && item.isCreate === true) &&
                      <Button onClick={handleShow}>
                        <span
                          className="btn btn-icon waves-effect waves-light btn-primary "
                          onClick={(e) => handleClearData()}
                          style={{ textTransform: 'none' }}
                        >
                          {" "}
                          <i className="fa fa-user"></i> {STRING_CONSTANTS.ADD_ORG_VOLUNTEER}
                        </span>
                      </Button>
                    }
                    <div style={{ position: 'relative', marginLeft: 'auto', }}>

                    {permissions.some(item => item.keyword === "profilesVolunteers" && item.isSearch === true) &&
         <>
          <input
                          type="text"
                          placeholder="Search..."
                          className="form-control"
                          style={{ width: '200px', paddingLeft: '35px', boxSizing: 'border-box', marginLeft: "auto" }}
                          onChange={(e) => {
                            handleSearch(e);
                            if (e.target.value.trim() === '') {
                              e.target.nextElementSibling.style.display = 'inline-block';
                            } else {
                              e.target.nextElementSibling.style.display = 'none';
                            }
                          }}
                       />
                        <i
                          className="fas fa-search"
                          style={{
                            position: 'absolute',
                            left: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#aaa',
                          }}
                        ></i>
         
         </>
          }

                       
                      </div>

                  </div>

                  <Dialog
                    open={show}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="md"
                  >
                    <DialogTitle>{STRING_CONSTANTS.ADD_ORG_VOLUNTEER}</DialogTitle>
                    <DialogContent>
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>{STRING_CONSTANTS.ORG}</InputLabel>
                              <Select
                                value={org_id}
                                onChange={handleChange("org_id")}
                                required
                              >
                                <MenuItem value="">
                                  <em>{STRING_CONSTANTS.SELECT_ORG}</em>
                                </MenuItem>
                                {org.map((person, i) => (
                                  <MenuItem key={i} value={person._id}>
                                    {person.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="First Name"
                              value={fname}
                              onChange={handleChange("fname")}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Last Name"
                              value={lname}
                              onChange={handleChange("lname")}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Mobile Number"
                              value={mobile}
                              onChange={handleChange("mobile")}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Email"
                              value={email}
                              onChange={handleChange("email")}
                              fullWidth
                              required
                            />
                          </Grid>
                        </Grid>
                      </form>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleClose}
                      >
                        {STRING_CONSTANTS.CLOSE}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{
                          backgroundColor: "#769A0F",
                          color: "#fff",
                        }}
                      >
                        {STRING_CONSTANTS.SUBMIT}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                          <TableCell style={{ minWidth: 150 }}>
                           {STRING_CONSTANTS.FIRST_NAME}
                          </TableCell>
                          <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.LAST_NAME}
                          </TableCell>
                          <TableCell style={{ minWidth: 150 }}>
                           {STRING_CONSTANTS.MOBLE_NUM}
                          </TableCell>
                          <TableCell style={{ minWidth: 150 }}>Email</TableCell>
                          <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.ORG}
                          </TableCell>
                          <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.STATUS}
                          </TableCell>
                          <TableCell style={{ minWidth: 150 }}>
                            {STRING_CONSTANTS.ACTIONS}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {members.map((member, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{member.fname}</TableCell>
                            <TableCell>{member.lname}</TableCell>
                            <TableCell>{member.mobile}</TableCell>
                            <TableCell>{member.email}</TableCell>
                            <TableCell>
                              {member.org}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {member.status === true ? (
                                <span className="btn-sm btn-danger ">
                                  {STRING_CONSTANTS.INACTIVE}
                                </span>
                              ) : (
                                <span className="btn-sm btn-success">
                                  {STRING_CONSTANTS.ACTIVE}
                                </span>
                              )}{" "}
                            </TableCell>
                            <TableCell>
                              <Actions row={member} />
                            </TableCell>
                          </TableRow>
                        ))}
                        <Dialog
                          open={show1}
                          onClose={handleClose1}
                          fullWidth
                          maxWidth="md"
                        >
                          <DialogTitle >{STRING_CONSTANTS.EDIT_ORG_VOLUNTEER}</DialogTitle>
                          <DialogContent>
                            <form onSubmit={handleSubmit}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>{STRING_CONSTANTS.ORG}</InputLabel>
                                    <Select
                                      value={org_id}
                                      onChange={handleChange("org_id")}
                                      required
                                    >
                                      <MenuItem value="">
                                        <em>{STRING_CONSTANTS.SELECT_ORG}</em>
                                      </MenuItem>
                                      {org.map((person, i) => (
                                        <MenuItem key={i} value={person._id}>
                                          {person.displayName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="First Name"
                                    style={{ paddingTop: "10px" }}

                                    value={fname}
                                    onChange={handleChange("fname")}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="Last Name"
                                    value={lname}
                                    onChange={handleChange("lname")}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    label="Mobile Number"
                                    value={mobile}
                                    onChange={handleChange("mobile")}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    label="Email"
                                    value={email}
                                    onChange={handleChange("email")}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </form>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={handleClose1}
                            >
                              {STRING_CONSTANTS.CLOSE}
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#769A0F",
                                color: "#fff",
                              }}
                              onClick={handleSubmit}
                            >
                              {STRING_CONSTANTS.UPDATE}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Members;
