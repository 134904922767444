import { useState, useEffect, useCallback } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import {
  MAP_PERMISSIONS,
  GET_SUBSCRIPTION,
  GET_MOBILE_PERMISSIONS,
  GET_SUBBYID,
  MAP_MOB_PERMISSIONS,
  EXPORT_MOBILE_PERMISSIONS,
  IMPORT_MOBILE_PERMISSIONS,
} from "../../constants/url";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper,
} from "@mui/material";
import {
  ERR_GETTING,
  ERR_POSTING,
  SUCCESS_SAVE_PERMISSIONS,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { toast, Bounce } from "react-toastify";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const MobliePermissions = () => {
  const [subs, setSubs] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [subId, setSubId] = useState("");
  const [roleDetails, setRoleDetails] = useState();

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchSubscription();
    fetchPermissions();
  }, []);

  useEffect(() => {
    
    if (subId || subId===0) {
      fetchSubById();
    }
  }, [subId]);

  const fetchSubscription = async () => {
    try {
      const requestUrl = `${GET_SUBSCRIPTION}`;
      const response = await privateApiCall(requestUrl, "GET");
      setSubs(response.data.subscription);
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const fetchSubById = async () => {


    try {
      const requestUrl = `${GET_SUBBYID}/${subId}`;
      const response = await privateApiCall(requestUrl, "GET");

           
      setPermissions(response.data.permissions);
      
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const fetchPermissions = async () => {
    try {
      const requestUrl = `${GET_MOBILE_PERMISSIONS}`;
      const response = await privateApiCall(requestUrl, "GET");
      setPermissions(response.data.permissions);
      
    } catch (error) {
      toast.error(`${ERR_GETTING}`, { transition: Bounce });
    }
  };

  const handleCheckboxChange = (keyword, property) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.keyword === keyword
          ? { ...permission, [property]: !permission[property] }
          : permission
      )
    );
  };

  const handleSubmit = useCallback(async (event) => {
    
    const roleData = { subscription_id : subId, permissions: permissions };
    event.preventDefault();
    const requestUrl = `${MAP_MOB_PERMISSIONS}`;
    try {
      const updatedPermission = await privateApiCall(requestUrl, "POST", roleData);
      localStorage.setItem("role", JSON.stringify(updatedPermission.data));
   
      console.log(updatedPermission,'SAMPLE');
      
      localStorage.setItem("mobilepermission", JSON.stringify(updatedPermission.data));
      let localRole = JSON.parse(localStorage.getItem("role"));
      toast.success(`${SUCCESS_SAVE_PERMISSIONS}`, { transition: Bounce });
    } catch (err) {
      toast.error(`${ERR_POSTING}`, { transition: Bounce });
    }
  });

  const handleExport = useCallback(async (event) => {
    event.preventDefault();
  
    const requestUrl = `${EXPORT_MOBILE_PERMISSIONS}`; // API endpoint for exporting subscription permissions
  
    try {
      // Make the API call using privateApiCall
      const response = await privateApiCall(requestUrl, 'GET', null, {
        responseType: 'blob', // To handle file downloads
      });
      
      
      const jsonString = JSON.stringify(response.data, null, 2); 
  
   
  
      // Create a new Blob for the file data
      const blob = new Blob([jsonString], { type: 'application/json' });
  
      // Use the File System Access API to prompt the user for a save location
      const options = {
        suggestedName: `subscriptionPermissions.json`, // Suggest the file name with .json extension
        types: [
          {
            description: 'JSON Files',
            accept: { 'application/json': ['.json'] }, // Specify file types
          },
        ],
      };
  
      // Show the save file dialog
      const fileHandle = await window.showSaveFilePicker(options);
  
      // Create a writable stream
      const writable = await fileHandle.createWritable();
  
      // Write the blob data to the file
      await writable.write(blob);
      
      // Close the writable stream to save the file
      await writable.close();
  
      // Show success notification
      toast.success('Subscription permissions exported successfully!', { transition: Bounce });
    } catch (err) {
      // Show error notification in case of failure
      toast.error('Error exporting subscription permissions!', { transition: Bounce });
    }
  }, []);
  




  



  const handleImport = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = async(e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
    
        const requestUrl = `${IMPORT_MOBILE_PERMISSIONS}`; // API endpoint for importing subscription permissions
        //         console.log("enteing here");
        
                // Make the API call using privateApiCall
                const response = await privateApiCall(requestUrl, 'POST', jsonData, {
                  responseType: 'json', // Adjust based on your API response
                });
             
                toast.success('Subscription permissions imported successfully!', { transition: Bounce });
                window.location.reload()
     // Pass JSON data to the import handler
      } catch (error) {
        console.error('Invalid JSON file:', error);
        toast.error('Error importing subscription permissions!', { transition: Bounce });
      }
    };
    if (file) {
      reader.readAsText(file);
    }
  };




  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="configurations" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.MOBILE_PERMISSON}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">
                    {STRING_CONSTANTS.MOBILE_PERMISSON}
                  </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel id="role-label">
                      {STRING_CONSTANTS.SELECT_SUB}
                    </InputLabel>
                    <Select
                      labelId="role-label"
                      value={subId}
                      onChange={(e) => {
                        
                        setSubId(e.target.value);
                      }}
                      required
                    >
                      <MenuItem value="">
                        <em>{STRING_CONSTANTS.SELECT_SUB}</em>
                      </MenuItem>
                      {subs
                        .sort((a, b) => a.subscription_id - b.subscription_id)
                        .map((sub) => (
                          <MenuItem
                            key={sub.subscription_id}
                            value={sub.subscription_id}
                          >
                            {sub.name}
                          </MenuItem>
                        ))}
                    </Select>

                    <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
                      <Link to="/dashboard">
                        {" "}
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ mt: 2, mr: 1 }}
                        >
                          {" "}
                          {STRING_CONSTANTS.CANCEL}{" "}
                        </Button>
                      </Link>
                      <>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="success"
                        sx={{ my:2,mr:1}}
                        style={{ backgroundColor: "#769A0F" }}
                      >
                   
                        {STRING_CONSTANTS.SAVE}
                      </Button>
                      </>
                     
                        <Button
                        onClick={handleExport}
                        variant="contained"
                        color="success"
                        sx={{ my:2,mr:1}}
                        style={{ backgroundColor: "#769A0F" }}
                      >
                        Export Permission
                      </Button>


                          <>
      <input
        accept=".json"
        style={{ display: 'none' }}
        id="import-file"
        type="file"
        onChange={handleImport}
      />
      <label htmlFor="import-file">
        <Button
          variant="contained"
          color="success"
          component="span"
          sx={{ mt: 2}}
          style={{ backgroundColor: "#769A0F" }}
        >
          Import Permission
        </Button>
      </label>
    </>
                    </Grid>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{STRING_CONSTANTS.PERMISSON}</TableCell>
                          <TableCell>{STRING_CONSTANTS.SELLERINFO}</TableCell>
                          <TableCell>{STRING_CONSTANTS.RELIST}</TableCell>
                          <TableCell>{STRING_CONSTANTS.CHAT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.BUYER_INFO}</TableCell>
                          <TableCell>{STRING_CONSTANTS.BUYER_DETAILS}</TableCell>
                          <TableCell>{STRING_CONSTANTS.HARVEST_PLANNER}</TableCell>
                          <TableCell>{STRING_CONSTANTS.ADD_BUSSINESS}</TableCell>
                          <TableCell>{STRING_CONSTANTS.WHEATHER}</TableCell>
                          <TableCell>{STRING_CONSTANTS.AGROCTOR}</TableCell>
                          <TableCell>{STRING_CONSTANTS.FIND_NEARBY}</TableCell>
                          <TableCell>{STRING_CONSTANTS.ALL_CHAT}</TableCell>
                          <TableCell>{STRING_CONSTANTS.SHOW_DISTANCE}</TableCell>
                          <TableCell>{STRING_CONSTANTS.FARM}</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permissions.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>
                              {item.hasOwnProperty("isSellerInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isSellerInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isSellerInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isRelist") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isRelist}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isRelist"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isChat") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isChat}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isChat"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isBuyInfo") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isBuyInfo}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isBuyInfo"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isBuyInfoDetails") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isBuyInfoDetails}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isBuyInfoDetails"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isHarvestPlanner") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isHarvestPlanner}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isHarvestPlanner"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isAddBusiness") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isAddBusiness}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isAddBusiness"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isWeather") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isWeather}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isWeather"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>
                      
                            <TableCell>
                              {item.hasOwnProperty("isAgroctor") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isAgroctor}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isAgroctor"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isFindNearby") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isFindNearby}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isFindNearby"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isAllChat") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isAllChat}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isAllChat"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isShowDistance") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isShowDistance}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isShowDistance"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {item.hasOwnProperty("isFarms") ? (
                                <input
                                  type="checkbox"
                                  checked={item.isFarms}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      item.keyword,
                                      "isFarms"
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={false}
                                />
                              )}
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Link to="/dashboard">
                      {" "}
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: 2, mr: 1 }}
                      >
                        {" "}
                        {STRING_CONSTANTS.CANCEL}{" "}
                      </Button>
                    </Link>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      color="success"
                      sx={{ mt: 2 }}
                      style={{ backgroundColor: "#769A0F" }}
                    >
                      {" "}
                      {STRING_CONSTANTS.SAVE}{" "}
                    </Button>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobliePermissions;
 